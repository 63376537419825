import {
  Component,
  ElementRef,
  Input,
  TemplateRef,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-dashboard-tab-item',
  templateUrl: './dashboard-tab-item.component.html',
  styleUrls: ['./dashboard-tab-item.component.scss'],
})
export class DashboardTabItemComponent {
  @Input() title: string = '';
  @ViewChild('content') content!: TemplateRef<ElementRef>;
}
