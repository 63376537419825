<div class="button-container">
  <button
    class="btn"
    [ngClass]="{ disabled: !enabled, right: right }"
    (click)="handleClick($event)"
  >
    <img
      *ngIf="icon !== ''"
      src="{{ icon }}"
      [ngClass]="{ disabled: !enabled, right: right }"
    />
    <span [ngClass]="{ disabled: !enabled, white: white }">{{ label }}</span>
  </button>
</div>
