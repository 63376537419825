import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { serializeQueryParams } from 'src/app/service-helper';
import { environment } from 'src/environments/environment';
import { DashboardFilterRequest } from '../dashboard/service/models/dashboard.service.model';
import {
  ResolutenessReportGetFilter,
  ResolutionDashboard,
} from './resoluteness-report.model';

@Injectable({
  providedIn: 'root',
})
export class ResolutenessReportService {
  constructor(private http: HttpClient) {}

  private baseURL: string = `${environment.apiUrl}/api/Resolution`;

  create(filters: ResolutenessReportGetFilter): Observable<any> {
    return this.http.post<any>(this.baseURL + '/Report', filters);
  }

  getAvaregePercentage(filter: DashboardFilterRequest): Observable<number> {
    const params = serializeQueryParams(filter);
    return this.http.get<number>(this.baseURL + '/AvaregePercentage', {
      params,
    });
  }

  getInsight(filter: DashboardFilterRequest) {
    const params = serializeQueryParams(filter);
    return this.http.get(this.baseURL + '/Insight', {
      params: params,
      responseType: 'text',
    });
  }

  getDashboardData(
    filter: DashboardFilterRequest
  ): Observable<ResolutionDashboard> {
    const params = serializeQueryParams(filter);
    return this.http.get<ResolutionDashboard>(this.baseURL + '/Dashboard', {
      params,
    });
  }
}
