<ul>
  <li *ngFor="let i of [0, 1, 2, 3, 4]">
    <div
      class="star-left"
      [ngClass]="{ rating: i + 0.5 <= (hover || rating) }"
      (click)="handleRating(i + 0.5)"
      (mouseenter)="handleHoverRating(i + 0.5)"
      (mouseleave)="handleHoverRating(0)"
    >
      <img src="../../../assets/icons/star.svg" alt="Star Icon" />
    </div>
    <div
      class="star-right"
      [ngClass]="{ rating: i + 1 <= (hover || rating) }"
      (click)="handleRating(i + 1)"
      (mouseenter)="handleHoverRating(i + 1)"
      (mouseleave)="handleHoverRating(0)"
    >
      <img src="../../../assets/icons/star.svg" alt="Star Icon" />
    </div>
  </li>
</ul>
