import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { BarChartComponent } from 'src/app/shared/bar-chart/bar-chart.component';
import {
  BarChartParams,
  DatasetBarChart,
} from 'src/app/shared/bar-chart/models/bar-chart.model';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { InputTypeEnum } from 'src/app/shared/input/enums/input-type.enum';
import { DashboardService } from '../service/dashboard.service';
import { DashboardFilterRequest } from '../service/models/dashboard.service.model';

@Component({
  selector: 'app-dashboard-mentioned-macro-contact-reasons-ranking',
  templateUrl:
    './dashboard-mentioned-macro-contact-reasons-ranking.component.html',
  styleUrls: [
    './dashboard-mentioned-macro-contact-reasons-ranking.component.scss',
  ],
})
export class DashboardMentionedMacroContactReasonsRankingComponent {
  constructor(
    public formBuilder: FormBuilder,
    public dashboardService: DashboardService
  ) {}

  @Input() filter!: DashboardFilterRequest;
  @Output() isLoadingEmmiter: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @ViewChild(BarChartComponent) barChartComponent!: BarChartComponent;

  error: boolean = false;
  isLoading: boolean = false;
  contactReasons: string[] = [];
  selectedContactReasons: string[] = [];

  params!: BarChartParams;

  form = this.formBuilder.group({
    initialDate: '',
    finalDate: '',
    contactReasons: [['']],
  });

  ButtonColorEnum = ButtonColorEnum;
  InputTypeEnum = InputTypeEnum;

  ngOnInit() {
    const currentDate = new Date();
    const firstDayOfYear = new Date(currentDate.getFullYear(), 0, 1);

    this.form.patchValue({
      initialDate: this.formatMonthYear(firstDayOfYear),
      finalDate: this.formatMonthYear(currentDate),
    });

    this.generate();
  }

  ngOnChanges() {
    if (this.filter) {
      this.generate();
    }
  }

  formatMonthYear(date: Date): string {
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${year}-${month}`;
  }

  clear() {
    this.form.reset();
    this.selectedContactReasons = [];
  }

  generate() {
    if (this.isLoading) return;

    this.error = false;
    this.isLoading = true;
    this.isLoadingEmmiter.emit(this.isLoading);

    this.filter.topQuantity = 3;

    this.dashboardService.getTopMentionedContacReasons(this.filter).subscribe({
      next: (data) => {
        var footerOptions = data.macroContactReasonsData.map(i => i.date);
        var contactReasons = data.macroContactReasonsData.flatMap(i => i.contactReasons.map(j => j.name));
        var datasets: DatasetBarChart[] = [];

        contactReasons.forEach((i) => {
          var values: string[] = [];

          data.macroContactReasonsData.forEach((j) => {
            var hasTranscriptionMonth = false;

            j.contactReasons.forEach((k) => {
              if (k.name == i) {
                values.push(k.quantity.toString());
                hasTranscriptionMonth = true;
              }
            });

            if (!hasTranscriptionMonth) values.push('0');
          });

          const dataset: DatasetBarChart = {
            title: i,
            values: values,
            backgroundColor: undefined,
            borderColor: undefined,
            borderWidth: 3.5,
            borderRadius: 5,
            type: undefined,
            hasBorderDash: false,
          };

          datasets.push(dataset);
        });

        this.params = {
          type: 'bar',
          footerOptions: footerOptions,
          datasets: datasets,
          displayLegends: true,
          isStacked: false,
          showDatalabel: false,
        };

        this.createChart();

        this.isLoading = false;
        this.isLoadingEmmiter.emit(this.isLoading);
      },
      error: () => {
        this.error = true;
        this.isLoading = false;
        this.isLoadingEmmiter.emit(this.isLoading);
      },
    });
  }

  createChart() {
    this.barChartComponent.createChart(
      this.params,
      'topMentionedMacroContactReasons'
    );
  }
}
