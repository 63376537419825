<div class="container">
  <article>
    <header>
      <strong>Editar Atendimento</strong>
    </header>

    <main>
      <div main class="main">
        <div class="grid-content">

          <app-select-multi
            label="Atendente"
            [placeholder]="attendantPlaceHolder"
            [options]="attendantOptions"
            property="Type"
            [disabled]="attendantDisabled"
            [multi]="false"
            [singleControl]="form.controls.attendantId"
            [errorMessage]="'campo obrigatório'"
            [selected]="selectedAttendant"
            (statusChanged)="handleAttendantStatusChanged($event)"
          ></app-select-multi>
        </div>
        <div class="grid-content">
          <app-select-multi
            label="Cliente"
            [placeholder]="clientPlaceHolder"
            [options]="clientOptions"
            property="Type"
            [disabled]="clientDisabled"
            [multi]="false"
            [errorMessage]="'campo obrigatório'"
            (statusChanged)="handleClientStatusChanged($event)"
            [singleControl]="form.controls.clientId"
            [selected]="selectedClient"
          ></app-select-multi>
          <app-select-multi
            label="Skill"
            [placeholder]="skillPlaceHolder"
            [selected]="selectedSkill"
            [options]="skillOptions"
            [singleControl]="form.controls.skillId"
            property="Type"
            [disabled]="skillDisabled"
            [multi]="false"
            [errorMessage]="skillErrorMessage"
            (statusChanged)="handleSkillStatusChanged($event)"
          ></app-select-multi>

        </div>
      </div>

      <hr />

      <div footer class="footer">
        <app-secondary-button
          footer
          label="Cancelar"
          [right]="false"
          [color]="ButtonColorEnum.primary"
          (clickFunc)="handleNavigateToList()"
        ></app-secondary-button>
        <app-primary-button
          [label]="'Salvar'"
          [color]="ButtonColorEnum.common"
          [loading]="isLoading"
          [enabled]="!(loadingAttendants && loadingClients)"
          (clickFunc)="handleUpdateClient()"
        ></app-primary-button>
      </div>
    </main>
  </article>
</div>

<app-modal-response
  [showModal]="showModalResponse"
  [title]="titleModalResponse"
  (closeModalFunc)="handleCloseModalResponse()"
  [icon]="iconButtonModalResponse"
>
  <strong main> {{ message }} </strong>

  <app-secondary-button
    footer
    label="Voltar"
    [right]="false"
    [color]="ButtonColorEnum.primary"
    (clickFunc)="handleCloseModalResponse()"
  ></app-secondary-button>

  <app-primary-button
    footer
    *ngIf="!error"
    class="btn-ok"
    label="Ok"
    [right]="false"
    [color]="ButtonColorEnum.common"
    (clickFunc)="handleCloseModalResponse()"
  ></app-primary-button>
</app-modal-response>
