import { Component, Input } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { ResolutenessReportService } from '../../resoluteness-report/resoluteness-report.service';
import { DashboardFilterRequest } from '../service/models/dashboard.service.model';

@Component({
  selector: 'app-dashboard-resolution-insights',
  templateUrl: './dashboard-resolution-insights.component.html',
  styleUrls: ['./dashboard-resolution-insights.component.scss'],
})
export class DashboardResolutionInsightsComponent {
  constructor(
    private resolutenessService: ResolutenessReportService,
    private toastrService: ToastrService
  ) {}

  @Input() filter!: DashboardFilterRequest;

  loadingPercentage: boolean = false;
  loadingInsight: boolean = false;
  resolutenessFullBarSize: number = 600;
  resolutenessBarSize: number | null = null;
  averageResolutenessPercentage: number = 0;
  showModal: boolean = false;
  insight: string = '';

  ButtonColorEnum = ButtonColorEnum;

  ngOnInit() {
    this.getAvaregePercentage();
  }

  ngOnChanges() {
    this.getAvaregePercentage();
  }

  getAvaregePercentage() {
    this.loadingPercentage = true;

    this.resolutenessService.getAvaregePercentage(this.filter).subscribe({
      next: (avaregePercentage) => {
        this.averageResolutenessPercentage = avaregePercentage;

        this.resolutenessBarSize =
          this.averageResolutenessPercentage *
          (this.resolutenessFullBarSize / 100);

        this.loadingPercentage = false;
      },
      error: () => {
        this.averageResolutenessPercentage = 0;

        this.resolutenessBarSize =
          this.averageResolutenessPercentage *
          (this.resolutenessFullBarSize / 100);

        this.loadingPercentage = false;
      },
    });
  }

  generate() {
    this.loadingInsight = true;

    this.resolutenessService.getInsight(this.filter).subscribe({
      next: (insight: string) => {
        this.insight = insight;
        this.loadingInsight = false;
        this.showModal = true;
      },
      error: (e) => {
        this.loadingInsight = false;
        this.toastrService.error(e.error);
      },
    });
  }

  closeModal() {
    this.showModal = false;
  }

  copied: boolean = false;
  copy() {
    navigator.clipboard.writeText(this.insight).then(() => {
      this.copied = true;

      setTimeout(() => {
        this.copied = false;
      }, 2000);
    });
  }
}
