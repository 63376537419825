import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cpfCnpj'
})
export class CpfCnpjPipe implements PipeTransform {
  transform(value: string) {
    if (!value) {
      return null;
    }
    else if (value.length === 11) {
      const cpf = value
        .replace(/[^0-9]/g, '')
        .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
      return cpf;
    }
    else if (value.length === 14) {
      const cnpj = value
        .replace(/[^0-9]/g, '')
        .replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');
      return cnpj;
    }
    else {
      return value;
    }
  }
}
