import { Component } from '@angular/core';

@Component({
  selector: 'app-incidents-import-list-header',
  templateUrl: './incidents-import-list-header.component.html',
  styleUrls: ['./incidents-import-list-header.component.scss']
})
export class IncidentsImportListHeaderComponent {

}
