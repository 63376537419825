import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { UserRoleEnum } from 'src/app/common/services/auth/enums/user-role.enum';
import { StorageService } from 'src/app/common/services/storage/storage.service';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { IUserListItemDTO, IUserRoleDTO } from '../models/user-list-item.model';

@Component({
  selector: 'app-user-list-item',
  templateUrl: './user-list-item.component.html',
  styleUrls: ['./user-list-item.component.scss'],
})
export class UserListItemComponent {
  constructor(private router: Router, private storageService: StorageService) {}

  hasSupervisorRole: boolean = false;
  showEditUserModal: boolean = false;
  userId: string = '';

  @Input() data: IUserListItemDTO = {
    id: '',
    name: '',
    email: '',
    functional: '',
    creationDate: '',
    active: false,
    role: {} as IUserRoleDTO,
    recorderId: '',
    supervisor: {} as IUserListItemDTO,
  };

  @Output() editUser: EventEmitter<string> = new EventEmitter<string>();

  get ButtonColorEnum() {
    return ButtonColorEnum;
  }

  ngOnInit(): void {
    const roles = this.storageService.getRole();
    this.hasSupervisorRole = roles === UserRoleEnum.supervisor;
  }

  handleDetails(id: string) {
    this.router.navigate(['/user/', id]);
  }

  getSupervisorName() {
    if (!this.data.supervisor)
      return "-";
    return this.data.supervisor.name;
  }
}
