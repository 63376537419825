import {
  Directive,
  ElementRef,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
} from '@angular/core';

@Directive({
  selector: '[appHighlightKeywords]',
})
export class HighlightKeywordsDirective implements OnChanges {
  @Input() text: string | null = '';
  @Input() wordsToHighlight: { key: string; value: string | null }[] = [];

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['text'] || changes['wordsToHighlight']) {
      if (this.text && this.wordsToHighlight.length) {
        this.highlightText();
      }
    }
  }

  private highlightText() {
    let highlightedText = this.text || '';

    for (const { key } of this.wordsToHighlight) {
      if (key) {
        const regex = new RegExp(`(?<!\\w)(${this.escapeRegExp(key)})(?!\\w)`, 'giu');
        highlightedText = highlightedText.replace(
          regex,
          `<span class="highlight">$1</span>`
        );
      }
    }

    this.renderer.setProperty(
      this.el.nativeElement,
      'innerHTML',
      highlightedText
    );

    this.applyColors();
  }

  private applyColors() {
    const spans = this.el.nativeElement.querySelectorAll('span.highlight');

    spans.forEach((span: HTMLSpanElement) => {
      const word = span.textContent?.toLowerCase() || '';
      const color = this.wordsToHighlight.find(
        ({ key }) => key.toLowerCase() === word
      )?.value;
      if (color) {
        this.renderer.setStyle(span, 'background-color', color);
      }
    });
  }

  private escapeRegExp(text: string): string {
    return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }
}
