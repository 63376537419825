import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
  ProtectedResourceScopes,
} from '@azure/msal-angular';
import {
  InteractionType,
  IPublicClientApplication,
  PublicClientApplication,
} from '@azure/msal-browser';
import { TagCloudComponent } from 'angular-tag-cloud-module';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { loginRequest, msalConfig } from './auth-config';
import { CpfCnpjPipe } from './common/pipes/cpf-cnpj.pipe';
import { HttpInterceptorService } from './common/services/interceptor/http-interceptor.service';
import { AccessDeniedComponent } from './pages/access-denied/access-denied.component';
import { AnalyticalReportComponent } from './pages/analytical-report/analytical-report.component';
import { CategoryAnalysisReportFilterComponent } from './pages/category-analysis-report/category-analysis-report-filter/category-analysis-report-filter.component';
import { CategoryAnalysisReportListHeaderComponent } from './pages/category-analysis-report/category-analysis-report-list/category-analysis-report-list-header/category-analysis-report-list-header.component';
import { CategoryAnalysisReportListItemComponent } from './pages/category-analysis-report/category-analysis-report-list/category-analysis-report-list-item/category-analysis-report-list-item.component';
import { CategoryAnalysisReportListComponent } from './pages/category-analysis-report/category-analysis-report-list/category-analysis-report-list.component';
import { CategoryAnalysisReportComponent } from './pages/category-analysis-report/category-analysis-report.component';
import { CategoryDetailsComponent } from './pages/category/category-details/category-details.component';
import { CategoryFilterComponent } from './pages/category/category-filter/category-filter.component';
import { CategoryListHeaderComponent } from './pages/category/category-list/category-list-header/category-list-header.component';
import { CategoryListItemComponent } from './pages/category/category-list/category-list-item/category-list-item.component';
import { CategoryListComponent } from './pages/category/category-list/category-list.component';
import { CategoryNewComponent } from './pages/category/category-new/category-new.component';
import { CategoryViewComponent } from './pages/category/category-view/category-view.component';
import { CategoryComponent } from './pages/category/category.component';
import { ClientDetailsComponent } from './pages/client/client-details/client-details.component';
import { ClientFilterComponent } from './pages/client/client-filter/client-filter.component';
import { ClientListHeaderComponent } from './pages/client/client-list/client-list-header/client-list-header.component';
import { ClientListItemComponent } from './pages/client/client-list/client-list-item/client-list-item.component';
import { ClientListComponent } from './pages/client/client-list/client-list.component';
import { ClientNewComponent } from './pages/client/client-new/client-new.component';
import { ClientComponent } from './pages/client/client.component';
import { ContactReasonDetailsComponent } from './pages/contact-reason/contact-reason-details/contact-reason-details.component';
import { ContactReasonFilterComponent } from './pages/contact-reason/contact-reason-filter/contact-reason-filter.component';
import { ContactReasonListHeaderComponent } from './pages/contact-reason/contact-reason-list/contact-reason-list-header/contact-reason-list-header.component';
import { ContactReasonListItemComponent } from './pages/contact-reason/contact-reason-list/contact-reason-list-item/contact-reason-list-item.component';
import { ContactReasonListComponent } from './pages/contact-reason/contact-reason-list/contact-reason-list.component';
import { ContactReasonNewComponent } from './pages/contact-reason/contact-reason-new/contact-reason-new.component';
import { ContactReasonComponent } from './pages/contact-reason/contact-reason.component';
import { DashboardBigNumberComponent } from './pages/dashboard/dashboard-big-number/dashboard-big-number.component';
import { DashboardDailyNonTalkComponent } from './pages/dashboard/dashboard-daily-non-talk/dashboard-daily-non-talk.component';
import { DashboardDailyTMAComponent } from './pages/dashboard/dashboard-daily-tma/dashboard-daily-tma.component';
import { DashboardFilterComponent } from './pages/dashboard/dashboard-filter/dashboard-filter.component';
import { DashboardMentionedCategoriesRankingComponent } from './pages/dashboard/dashboard-mentioned-categories-ranking/dashboard-mentioned-categories-ranking.component';
import { DashboardMentionedKeywordsRankingComponent } from './pages/dashboard/dashboard-mentioned-keywords-ranking/dashboard-mentioned-keywords-ranking.component';
import { DashboardResolutionChartComponent } from './pages/dashboard/dashboard-resolution-chart/dashboard-resolution-chart.component';
import { DashboardResolutionInsightsComponent } from './pages/dashboard/dashboard-resolution-insights/dashboard-resolution-insights.component';
import { DashboardShiftsComponent } from './pages/dashboard/dashboard-shifts/dashboard-shifts.component';
import { DashboardShortcallsTotalComponent } from './pages/dashboard/dashboard-shortcalls-total/dashboard-shortcalls-total.component';
import { DashboardTabItemComponent } from './pages/dashboard/dashboard-tabs/dashboard-tab-item/dashboard-tab-item.component';
import { DashboardTabsComponent } from './pages/dashboard/dashboard-tabs/dashboard-tabs.component';
import { DashboardTmaXNonTalkBySegmentComponent } from './pages/dashboard/dashboard-tma-x-non-talk-by-segment/dashboard-tma-x-non-talk-by-segment.component';
import { DashboardTranscriptionsClientComponent } from './pages/dashboard/dashboard-transcriptions-client/dashboard-transcriptions-client.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { IncidentsImportListHeaderComponent } from './pages/incidents-import/incidents-import-list/incidents-import-list-header/incidents-import-list-header.component';
import { IncidentsImportListItemComponent } from './pages/incidents-import/incidents-import-list/incidents-import-list-item/incidents-import-list-item.component';
import { IncidentsImportListComponent } from './pages/incidents-import/incidents-import-list/incidents-import-list.component';
import { IncidentsImportNewComponent } from './pages/incidents-import/incidents-import-new/incidents-import-new.component';
import { IncidentsImportComponent } from './pages/incidents-import/incidents-import.component';
import { KeywordsCloudFilterComponent } from './pages/keywords-cloud/keywords-cloud-filter/keywords-cloud-filter.component';
import { KeywordsCloudListComponent } from './pages/keywords-cloud/keywords-cloud-list/keywords-cloud-list.component';
import { KeywordsCloudComponent } from './pages/keywords-cloud/keywords-cloud.component';
import { LoginComponent } from './pages/login/login.component';
import { ResolutenessReportComponent } from './pages/resoluteness-report/resoluteness-report.component';
import { SaReportListHeaderComponent } from './pages/sa_report/sa_report-list/sa_report-list-header/sa_report-list-header.component';
import { SaReportListItemComponent } from './pages/sa_report/sa_report-list/sa_report-list-item/sa_report-list-item.component';
import { SaReportListComponent } from './pages/sa_report/sa_report-list/sa_report-list.component';
import { SaReportNewComponent } from './pages/sa_report/sa_report-new/sa_report-new.component';
import { SaReportComponent } from './pages/sa_report/sa_report.component';
import { TranscriptionService } from './pages/transcription/service/transcription.service';
import { TranscriptionDetailsComponent } from './pages/transcription/transcription-details/transcription-details.component';
import { TranscriptionListHeaderComponent } from './pages/transcription/transcription-list/transcription-list-header/transcription-list-header.component';
import { TranscriptionListItemComponent } from './pages/transcription/transcription-list/transcription-list-item/transcription-list-item.component';
import { TranscriptionListComponent } from './pages/transcription/transcription-list/transcription-list.component';
import { HighlightKeywordsDirective } from './pages/transcription/transcription-list/transcription-result/directives/highlight-keywords.directive';
import { PartialBackgroundColorDirective } from './pages/transcription/transcription-list/transcription-result/directives/partial-background-color.directive';
import { ResultTabComponent } from './pages/transcription/transcription-list/transcription-result/result-tabs/result-tab/result-tab.component';
import { ResultTabsComponent } from './pages/transcription/transcription-list/transcription-result/result-tabs/result-tabs.component';
import { TranscriptionResultComponent } from './pages/transcription/transcription-list/transcription-result/transcription-result.component';
import { TranscriptionNewComponent } from './pages/transcription/transcription-new/transcription-new.component';
import { TranscriptionComponent } from './pages/transcription/transcription.component';
import { TranscriptionsFilterComponent } from './pages/transcription/transcriptions-filter/transcriptions-filter.component';
import { UserComponent } from './pages/user/user.component';
import { UserDetailsComponent } from './pages/user/user/user-details/user-details.component';
import { UserFilterComponent } from './pages/user/user/user-filter/user-filter.component';
import { UserListHeaderComponent } from './pages/user/user/user-list/user-list-header/user-list-header.component';
import { UserListItemComponent } from './pages/user/user/user-list/user-list-item/user-list-item.component';
import { UserListComponent } from './pages/user/user/user-list/user-list.component';
import { UserNewComponent } from './pages/user/user/user-new/user-new.component';
import { BarChartComponent } from './shared/bar-chart/bar-chart.component';
import { IconButtonComponent } from './shared/buttons/icon-button/icon-button.component';
import { LoginButtonComponent } from './shared/buttons/login-button/login-button.component';
import { NavigateButtonComponent } from './shared/buttons/navigate-button/navigate-button.component';
import { PrimaryButtonComponent } from './shared/buttons/primary-button/primary-button.component';
import { SecondaryButtonComponent } from './shared/buttons/secondary-button/secondary-button.component';
import { CardComponent } from './shared/card/card.component';
import { CheckBoxComponent } from './shared/check-box/check-box.component';
import { FilterComponent } from './shared/filter/filter.component';
import { InfoboxComponent } from './shared/infobox/infobox.component';
import { InputComponent } from './shared/input/input.component';
import { PasswordValidationComponent } from './shared/input/password-validation/password-validation.component';
import { ModalResponseComponent } from './shared/modal-response/modal-response';
import { ModalComponent } from './shared/modal/modal.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { PaginatorComponent } from './shared/paginator/paginator.component';
import { PieChartComponent } from './shared/pie-chart/pie-chart.component';
import { CapitalizePipe } from './shared/pipes/capitalize.pipe';
import { RateComponent } from './shared/rate/rate.component';
import { SelectAltComponent } from './shared/select-alt/select-alt.component';
import { SelectMultiComponent } from './shared/select-multi/select-multi.component';
import { SelectComponent } from './shared/select/select.component';
import { TextAreaComponent } from './shared/text-area/text-area.component';
import { UploaderComponent } from './shared/uploader/uploader.component';
import { DashboardMentionedMacroContactReasonsRankingComponent } from './pages/dashboard/dashboard-mentioned-macro-contact-reasons-ranking/dashboard-mentioned-macro-contact-reasons-ranking.component';
import { DashboardMentionedMicroContactReasonsRankingComponent } from './pages/dashboard/dashboard-mentioned-micro-contact-reasons-ranking/dashboard-mentioned-micro-contact-reasons-ranking.component';

export function MSALInstanceFactory(): IPublicClientApplication {
  const msalInstance = new PublicClientApplication(msalConfig);
  return msalInstance;
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  const msalGuardConfig = {
    interactionType: InteractionType.Redirect,
    authRequest: loginRequest,
  };
  return {
    interactionType: InteractionType.Redirect,
    authRequest: loginRequest,
  };
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<
    string,
    Array<string | ProtectedResourceScopes> | null
  >();

  const msalInterceptorConfig = {
    interactionType: InteractionType.Popup,
    protectedResourceMap,
  };

  return {
    interactionType: InteractionType.Popup,
    protectedResourceMap,
  };
}

@NgModule({
  declarations: [
    AppComponent,
    PrimaryButtonComponent,
    NavbarComponent,
    IconButtonComponent,
    SecondaryButtonComponent,
    ModalComponent,
    ModalResponseComponent,
    InputComponent,
    UploaderComponent,
    RateComponent,
    CpfCnpjPipe,
    TextAreaComponent,
    PaginatorComponent,
    SelectComponent,
    LoginComponent,
    CardComponent,
    AccessDeniedComponent,
    UserComponent,
    NavigateButtonComponent,
    InfoboxComponent,
    PasswordValidationComponent,
    UserListComponent,
    UserListHeaderComponent,
    UserListItemComponent,
    UserNewComponent,
    UserDetailsComponent,
    CategoryComponent,
    CategoryNewComponent,
    CategoryDetailsComponent,
    CategoryListComponent,
    CategoryListHeaderComponent,
    CategoryListItemComponent,
    SaReportComponent,
    SaReportNewComponent,
    SaReportListComponent,
    SaReportListHeaderComponent,
    SaReportListItemComponent,
    SelectAltComponent,
    FilterComponent,
    CategoryAnalysisReportComponent,
    CategoryAnalysisReportListComponent,
    CategoryAnalysisReportListHeaderComponent,
    CategoryAnalysisReportListItemComponent,
    CheckBoxComponent,
    SelectMultiComponent,
    CategoryAnalysisReportFilterComponent,
    UserFilterComponent,
    CategoryFilterComponent,
    TranscriptionsFilterComponent,
    AnalyticalReportComponent,
    TranscriptionComponent,
    TranscriptionListComponent,
    TranscriptionListHeaderComponent,
    TranscriptionListItemComponent,
    DashboardComponent,
    DashboardTranscriptionsClientComponent,
    DashboardMentionedCategoriesRankingComponent,
    DashboardMentionedKeywordsRankingComponent,
    BarChartComponent,
    DashboardFilterComponent,
    DashboardBigNumberComponent,
    DashboardTabsComponent,
    DashboardTabItemComponent,
    DashboardTmaXNonTalkBySegmentComponent,
    DashboardDailyTMAComponent,
    DashboardDailyNonTalkComponent,
    TranscriptionResultComponent,
    ClientComponent,
    ClientListComponent,
    ClientDetailsComponent,
    ClientFilterComponent,
    ClientNewComponent,
    ClientListHeaderComponent,
    ClientListItemComponent,
    TranscriptionDetailsComponent,
    KeywordsCloudComponent,
    KeywordsCloudFilterComponent,
    KeywordsCloudListComponent,
    PieChartComponent,
    DashboardShortcallsTotalComponent,
    ResultTabsComponent,
    ResultTabComponent,
    ResolutenessReportComponent,
    DashboardShiftsComponent,
    HighlightKeywordsDirective,
    PartialBackgroundColorDirective,
    CapitalizePipe,
    LoginButtonComponent,
    TranscriptionNewComponent,
    DashboardResolutionInsightsComponent,
    DashboardResolutionChartComponent,
    CategoryViewComponent,
    ContactReasonComponent,
    ContactReasonDetailsComponent,
    ContactReasonFilterComponent,
    ContactReasonListComponent,
    ContactReasonNewComponent,
    ContactReasonListHeaderComponent,
    ContactReasonListItemComponent,
    IncidentsImportComponent,
    IncidentsImportListComponent,
    IncidentsImportNewComponent,
    IncidentsImportListHeaderComponent,
    IncidentsImportListItemComponent,
    DashboardMentionedMacroContactReasonsRankingComponent,
    DashboardMentionedMicroContactReasonsRankingComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    NgxMaskDirective,
    NgxMaskPipe,
    NgClickOutsideDirective,
    TagCloudComponent,
    MsalModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      closeButton: true,
    }),
  ],
  providers: [
    TranscriptionService,
    provideNgxMask(),
    // Log antes da configuração do HTTP_INTERCEPTORS com HttpInterceptorService
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    // Log antes da configuração do HTTP_INTERCEPTORS com MsalInterceptor
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    // Log antes da configuração do MSAL_INSTANCE com MSALInstanceFactory
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    // Log antes da configuração do MSAL_GUARD_CONFIG com MSALGuardConfigFactory
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    // Log antes da configuração do MSAL_INTERCEPTOR_CONFIG com MSALInterceptorConfigFactory
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],

  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
