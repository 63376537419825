import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { StorageService } from 'src/app/common/services/storage/storage.service';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { InputTypeEnum } from 'src/app/shared/input/enums/input-type.enum';
import { CategoryService } from '../../category/service/category.service';
import { GetCategoriesResponse } from '../../category/service/models/category.service.model';
import { IncidentStatusEnum } from '../enums/incident-status.enum';
import { GetTranscriptionsFilter } from '../service/models/transcription.service.model';

@Component({
  selector: 'app-transcriptions-filter',
  templateUrl: './transcriptions-filter.component.html',
  styleUrls: ['./transcriptions-filter.component.scss'],
})
export class TranscriptionsFilterComponent {
  constructor(
    public categoryService: CategoryService,
    public formBuilder: FormBuilder,
    public storageService: StorageService
  ) {}

  @Output() filterEmmiter: EventEmitter<GetTranscriptionsFilter> =
    new EventEmitter<GetTranscriptionsFilter>();

  form = this.formBuilder.group({
    protocolNumber: '',
    attendant: '',
    categories: [['']],
    status: [['']],
    initialDate: [''],
    finalDate: [''],
    types: [['']],
  });

  isOpen: boolean = false;
  categories: string[] = [];
  selectedCategories: string[] = [];
  status: string[] = [
    'Aguardando Análise',
    'Aguardando Informações',
    'Finalizado',
    'Erro',
  ];
  selectedStatus: string[] = [];
  isLoading: boolean = false;
  originalCategories: GetCategoriesResponse[] = [];
  types: string[] = ['Áudio', 'Chat'];
  selectedTypes: string[] = [];

  ButtonColorEnum = ButtonColorEnum;
  InputTypeEnum = InputTypeEnum;

  ngOnInit() {
    this.populateCategories();
    let strFilter = this.storageService.getCurrentFilter();
    if (strFilter != '') {
      let filter = JSON.parse(strFilter);
      this.form.controls.attendant.setValue(filter.attendant);
      this.form.controls.initialDate.setValue(filter.startDate);
      this.form.controls.finalDate.setValue(filter.endDate);
      this.form.controls.protocolNumber.setValue(filter.protocolNumber);
      this.selectedCategories = filter.categories;
      this.selectedStatus = filter.statusName;
    }
  }

  populateCategories() {
    this.categories = [];

    this.categoryService.getAll().subscribe({
      next: (data) => {
        this.originalCategories = data;
        this.categories = data.map((category) => category.name);
      },
    });
  }

  handleToggleAccordion() {
    this.isOpen = !this.isOpen;
  }

  clear() {
    this.form.reset();
    this.selectedCategories = [];
    this.selectedTypes = [];
    this.selectedStatus = [];
  }

  search() {
    let filter: GetTranscriptionsFilter = {} as GetTranscriptionsFilter;

    filter.protocolNumber = this.form.controls.protocolNumber.value ?? '';
    filter.attendant = this.form.controls.attendant.value ?? '';
    filter.startDate = this.form.controls.initialDate.value ?? '';
    filter.endDate = this.form.controls.finalDate.value ?? '';

    filter.categories = this.getCategoriesIdFromName(
      this.form.controls.categories.value || []
    );

    filter.statusName = this.form.controls.status.value || [];
    filter.types = filter.types ?? [];
    this.storageService.setCurrentFilter(JSON.stringify(filter));
    this.filterEmmiter.emit(filter);

    const array: string[] = this.form.controls.types.value ?? [];
    if (array.includes('Áudio')) filter.types.push(0);
    if (array.includes('Chat')) filter.types.push(1);

    this.isOpen = false;
  }

  getStatusEnumFromFilter(filter: string[]): IncidentStatusEnum[] {
    var result: IncidentStatusEnum[] = [];

    filter.forEach((status) => {
      var statusEnumValue = Object.values(IncidentStatusEnum).find(
        (i) => i === status
      );
      if (statusEnumValue) result.push(statusEnumValue);
    });

    return result;
  }

  getCategoriesIdFromName(categoryNames: string[]): string[] {
    let categoryIds: string[] = [];

    categoryNames.forEach((categoryName) => {
      let categoryId = this.getCategoryIdFromName(categoryName);
      if (categoryId) categoryIds.push(categoryId);
    });

    return categoryIds;
  }

  getCategoryIdFromName(text: string): string | null {
    if (!text || text.trim() === '') return null;

    var category = this.originalCategories.find((c) => c.name === text);

    return category ? category.id : null;
  }
}
