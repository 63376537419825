import { Component, Input } from '@angular/core';
import Chart, {
  BubbleDataPoint,
  ChartDataset,
  ChartOptions,
  ChartTypeRegistry,
  Point,
} from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { BarChartParams } from './models/bar-chart.model';

@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
})
export class BarChartComponent {
  constructor() {}

  currentChart: Chart | undefined;
  chartId: string | undefined;
  @Input() loading!: boolean;
  @Input() error!: boolean;
  options: ChartOptions = {};

  createChart(params: BarChartParams, chartId: string): void {
    this.chartId = chartId;

    setTimeout(() => {
      const ctx = document.getElementById(chartId) as HTMLCanvasElement;

      const datasets: ChartDataset<
        keyof ChartTypeRegistry,
        (number | [number, number] | Point | BubbleDataPoint | null)[]
      >[] = [];

      params.datasets.forEach((dataset) => {
        const convertedData = dataset.values.map((value) => {
          const parsedValue = parseFloat(value);
          return isNaN(parsedValue) ? null : parsedValue;
        });

        const currentDataset: ChartDataset<
          keyof ChartTypeRegistry,
          (number | [number, number] | Point | BubbleDataPoint | null)[]
        > = {
          label: dataset.title,
          data: convertedData,
          borderWidth: dataset.borderWidth,
          borderRadius: dataset.borderRadius,
          barPercentage: dataset.barPercentage,
          backgroundColor: dataset.backgroundColor,
          pointBackgroundColor: dataset.pointBackgroundColor,
          borderColor: dataset.borderColor,
          type: dataset.type,      
          fill: dataset.fill,
          borderDash: dataset.hasBorderDash ? [2, 2] : undefined,
          yAxisID: dataset.yAxisID,
          datalabels: {
            backgroundColor:
              dataset.type === 'line'
                ? dataset.datalabelsBackgroundColor
                : undefined,
            padding:
              dataset.type === 'line'
                ? { top: 3, bottom: 1, left: 3, right: 3 }
                : undefined,
            borderRadius: dataset.type === 'line' ? 4 : undefined,
            align: dataset.datalabelsAlign,
            font: {
              weight: dataset.datalabelsFontWeight,
              size: dataset.datalabelsFontSize,
            },
          },
        };

        datasets.push(currentDataset);
      });

      if (this.currentChart) this.currentChart.destroy();

      this.configureOptions(params);
      this.currentChart = new Chart(ctx, {
        type: params.type,
        data: {
          labels: params.footerOptions,
          datasets: datasets,
        },
        options: this.options,
        plugins: [ChartDataLabels],
      });
    }, 0);
  }

  configureOptions(params: BarChartParams) {
    this.options = {
      plugins: {
        datalabels: {
          display: (context) => {
            if (
              context.dataset.type == 'line' ||
              (params.showDatalabel &&
                Number(context.dataset.data[context.dataIndex]) > 15)
            )
              return true;
            return false;
          },
          color: 'white',
          formatter: Math.round,
        },
        legend: {
          position: 'bottom',
          align: 'start',
          labels: {
            boxWidth: 40,
            boxHeight: 10,
            useBorderRadius: false,
            padding: 30,
          },
          display: params.displayLegends,
        },
      },
      maintainAspectRatio: false,
      scales: {
        x: {
          stacked: params.isStacked,
          grid: {
            lineWidth: 0.5,
            display: true,
          },
        },
        y: {
          stacked: params.isStacked,
          grid: {
            lineWidth: 0.5,
            display: true,
            color: 'rgba(255,99,132,0.2)',
          },
          ticks: {
            display: params.displayYAxisTicks,
          },
        },
        yAxisPercentage: {
          display: false,
          min: 0,
          max: 100,
        },
      },
      layout: {
        padding: {
          top: 20,
        },
      },
    };
  }
}
