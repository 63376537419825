import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-navigate-button',
  templateUrl: './navigate-button.component.html',
  styleUrls: ['./navigate-button.component.scss']
})
export class NavigateButtonComponent {
  @Input() enabled:boolean = false;
  @Input() label:string = '';
  @Input() icon:string = '';
  @Input() right:boolean = false;
  @Input() white: boolean = false;

  @Output() clickButton:EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  handleClick(event: MouseEvent) {
    this.clickButton.emit(event);
  }
}
