<section>
  <strong>{{ label }}</strong>
  <label
    for="uploader"
    [ngClass]="{ selected: fileName }"
    (click)="handleButtonClick($event)"
  >
    <app-icon-button
      *ngIf="fileName"
      icon="assets/icons/trash.svg"
      [color]="ButtonColorEnum.warning"
      (click)="handleRemoveFile()"
    ></app-icon-button>
    <img
      *ngIf="!fileName"
      src="assets/icons/load_audio_folder.svg"
      alt="Upload Icon"
    />
    <span>{{ fileName ? fileName : description }}</span>
  </label>
  <input
    #uploader
    id="uploader"
    type="file"
    accept="{{ extensions.join(',') }}"
    (change)="handleSelectFile($event)"
  />
</section>
