import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { IncidentsImportService } from '../incidents-import.service';
import { finalize } from 'rxjs';
import { GetIncidentsImportResponse } from '../incidents-import.model';

@Component({
  selector: 'app-incidents-import-list',
  templateUrl: './incidents-import-list.component.html',
  styleUrls: ['./incidents-import-list.component.scss'],
})
export class IncidentsImportListComponent implements OnInit {
  constructor(
    private router: Router,
    private service: IncidentsImportService
  ) {}

  ButtonColorEnum = ButtonColorEnum;
  imports: GetIncidentsImportResponse[] = [];
  page: number = 1;
  pageSize: number = 10;
  totalPages: number = 0;
  totalItems: number = 0;
  isLoading: boolean = false;

  ngOnInit(): void {
    this.get();

    // var data1: GetIncidentsImportResponse = {
    //   id: "123",
    //   creationDate: "2024-10-23",
    //   client: "Comgás",
    //   cell: "37",
    //   subCell: "55",
    //   status: "Finalizado",
    // }

    // var data2: GetIncidentsImportResponse = {
    //   id: "124",
    //   creationDate: "2024-10-20",
    //   client: "Vivo",
    //   cell: "14",
    //   subCell: "29",
    //   status: "Em Fila",
    // }
    
    // var data3: GetIncidentsImportResponse = {
    //   id: "125",
    //   creationDate: "2024-10-18",
    //   client: "Claro",
    //   cell: "45",
    //   subCell: "62",
    //   status: "Em Andamento",
    // }
    
    // var data4: GetIncidentsImportResponse = {
    //   id: "126",
    //   creationDate: "2024-10-22",
    //   client: "Petrobras",
    //   cell: "23",
    //   subCell: "31",
    //   status: "Finalizado",
    // }
    
    // var data5: GetIncidentsImportResponse = {
    //   id: "127",
    //   creationDate: "2024-10-21",
    //   client: "Caixa",
    //   cell: "58",
    //   subCell: "74",
    //   status: "Em Fila",
    // }
    
    // var data6: GetIncidentsImportResponse = {
    //   id: "128",
    //   creationDate: "2024-10-19",
    //   client: "Bradesco",
    //   cell: "63",
    //   subCell: "22",
    //   status: "Em Andamento",
    // }
    
    // var data7: GetIncidentsImportResponse = {
    //   id: "129",
    //   creationDate: "2024-10-17",
    //   client: "Itaú",
    //   cell: "31",
    //   subCell: "15",
    //   status: "Finalizado",
    // }
    
    // var data8: GetIncidentsImportResponse = {
    //   id: "130",
    //   creationDate: "2024-10-24",
    //   client: "Santander",
    //   cell: "54",
    //   subCell: "88",
    //   status: "Em Andamento",
    // }
    
    // var data9: GetIncidentsImportResponse = {
    //   id: "131",
    //   creationDate: "2024-10-25",
    //   client: "Nestlé",
    //   cell: "21",
    //   subCell: "34",
    //   status: "Em Fila",
    // }
    
    // var data10: GetIncidentsImportResponse = {
    //   id: "132",
    //   creationDate: "2024-10-26",
    //   client: "Magazine Luiza",
    //   cell: "49",
    //   subCell: "17",
    //   status: "Finalizado",
    // }    

    // this.imports.push(data1);
    // this.imports.push(data2);
    // this.imports.push(data3);
    // this.imports.push(data4);
    // this.imports.push(data5);
    // this.imports.push(data6);
    // this.imports.push(data7);
    // this.imports.push(data8);
    // this.imports.push(data9);
    // this.imports.push(data10);
  }

  create() {
    this.router.navigate(['/incidents-import/new']);
  }

  handleSelectPage(index: number) {
    this.page = index;
    this.imports = [];
    this.get();
  }

  get() {
    if (this.isLoading) return;

    this.isLoading = true;
    this.imports = [];

    const filters: any = {};

    filters.pageNumber = this.page;
    filters.pageSize = this.pageSize;

    this.service
      .get(filters)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (data) => {
          this.totalItems = data.totalItems;
          this.totalPages = data.totalPages;

          if (data.items && data.items.length > 0) {
            this.imports = data.items.map((i) => ({
              id: i.id,
              createdOn: i.createdOn,
              client: i.client,
              cell: i.cell,
              subCell: i.subCell,
              status: i.status,
            }));
          }
        },
        error: () => {
          this.imports = [];
        },
      });
  }
}
