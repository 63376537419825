import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { InputTypeEnum } from 'src/app/shared/input/enums/input-type.enum';
import { MaskTypeEnum } from 'src/app/shared/input/enums/mask-type.enum';
import { CategoryService } from '../service/category.service';
import {
  Keyword,
  KeywordVariation,
} from '../service/models/category.service.model';

const MAX_WORDS_TO_SHOW = 5;

@Component({
  selector: 'app-category-view',
  templateUrl: './category-view.component.html',
  styleUrls: ['./category-view.component.scss'],
})
export class CategoryViewComponent {
  constructor(
    public formBuilder: FormBuilder,
    public categoryService: CategoryService,
    public router: Router,
    private route: ActivatedRoute
  ) {}

  originalKeywords: Keyword[] = [];
  keywords: string[] = [];
  textareaContent: string = '';
  showAllKeywords: boolean = false;
  showAllVariations: boolean = false;
  isLoading: boolean = false;
  success: boolean = false;
  error: boolean = false;
  categoryId: string = '';
  activeOptions: string[] = ['Ativo', 'Inativo'];

  selectedKeyword: Keyword | undefined | null = null;
  selectedStatus: string[] = [];

  form = this.formBuilder.group({
    name: ['', Validators.required],
    color: ['', Validators.required],
    active: ['', Validators.required],
    keyword: [''],
    variation: [''],
    client: ['', Validators.required],
  });

  ButtonColorEnum = ButtonColorEnum;
  MaskTypeEnum = MaskTypeEnum;
  InputTypeEnum = InputTypeEnum;

  isFormValid() {
    return this.form.valid;
  }

  ngOnInit() {
    this.route.params.subscribe((params) => {
      const categoryIdFromUrl = params['id'];

      if (categoryIdFromUrl) {
        this.categoryId = categoryIdFromUrl;
        this.getCategory();
      }
    });
  }

  loadingCategory = false;
  loadingCategoryError = false;
  showModalResponse = false;

  private getCategory() {
    this.loadingCategory = true;
    this.categoryService.getById(this.categoryId).subscribe({
      next: (category) => {
        if (category) {
          this.form.patchValue({
            name: category.name,
            active: category.active ? 'Ativo' : 'Inativo',
            color: category.color,
            client: category.client
              ? category.client.cnpj + ' | ' + category.client.name
              : null,
          });

          if (category.keywords.length > 0)
            this.selectedKeyword = category.keywords[0];

          this.selectedStatus = category.active ? ['Ativo'] : ['Inativo'];
          this.keywords = [];
          const keywords = category.keywords.map((keyword) => keyword.name);
          this.originalKeywords = category.keywords;
          this.keywords.push(...keywords);
          this.form.controls.keyword.reset();
          this.updateKeywordTextarea();

          this.loadingCategory = false;
        }
      },
      error: () => {
        this.loadingCategory = false;
        this.loadingCategoryError = true;
        this.showModalResponse = true;
      },
    });
  }

  closeModal() {
    this.form.reset();
    this.router.navigate(['/category/']);
  }

  showSeeAllKeywordsButton(): boolean {
    return !this.showAllKeywords && this.keywords.length > MAX_WORDS_TO_SHOW;
  }

  showSeeAllVariationsButton(): boolean | null | undefined {
    return (
      !this.showAllVariations &&
      this.selectedKeyword &&
      this.selectedKeyword.keywordVariations.length > MAX_WORDS_TO_SHOW
    );
  }

  toggleShowAllKeywords(): void {
    this.showAllKeywords = !this.showAllKeywords;
  }

  toggleShowAllVariations(): void {
    this.showAllVariations = !this.showAllVariations;
  }

  visibleKeywords(): string[] {
    if (this.showAllKeywords) return this.keywords;
    else return this.keywords.slice(0, MAX_WORDS_TO_SHOW);
  }

  visibleVariations(): KeywordVariation[] | null | undefined {
    if (this.showAllVariations) return this.selectedKeyword?.keywordVariations;
    else
      return this.selectedKeyword?.keywordVariations.slice(
        0,
        MAX_WORDS_TO_SHOW
      );
  }

  updateKeywordTextarea() {
    if (this.keywords.length <= MAX_WORDS_TO_SHOW || this.showAllKeywords)
      this.textareaContent = this.keywords.join(' ');
  }

  isFormComplete() {
    return (
      this.form.controls.name.valid &&
      this.form.controls.active.valid &&
      this.form.controls.color.valid &&
      this.keywords.length > 0
    );
  }

  getKeywordsFromText(keywordTexts: string[]): Keyword[] {
    var response: Keyword[] = [];

    keywordTexts.forEach((keywordText) => {
      var keyword = this.originalKeywords.find(
        (originalKeyword) => originalKeyword.name === keywordText
      );
      if (keyword) response.push(keyword);
      else {
        var newKeywork: Keyword = {
          active: true,
          name: keywordText,
          keywordVariations: [],
        };
        response.push(newKeywork);
      }
    });

    return response;
  }

  setSelectedKeyword(keywordName: string) {
    var keyword = this.originalKeywords.find(
      (keyword) => keyword.name == keywordName
    );

    this.selectedKeyword = keyword;
  }

  normalizeString(str: string): string {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();
  }

  stringExistsInList(target: string, list: string[]): boolean {
    const normalizedTarget = this.normalizeString(target);
    return list.some((item) => this.normalizeString(item) === normalizedTarget);
  }
}
