<div class="container">
  <article>
    <header>
      <strong>Atualização de Motivo de Contato</strong>
    </header>
    <main>
      <div main class="main">
        <div *ngIf="loadingContactReason" class="loading-animation"></div>

        <div *ngIf="!loadingContactReason" class="grid-content">
          <div class="grid">
            <app-input
              label="Nome do Arquivo"
              [control]="form.controls.name"
              placeholder="Descreva brevemente o tipo de motivo"
              errorMessage="campo obrigatório"
              [error]="!form.controls.name.valid"
              [disabled]="false"
              [required]="true"
            ></app-input>

            <app-select
              label="Cliente"
              placeholder="Selecione um cliente"
              errorMessage="campo obrigatório"
              [options]="clientOptions"
              [disabled]="false"
              [control]="form.controls.client"
              [loading]="loadingClients"
              [disabled]="loadingClients"
              [error]="!loadingClients && !form.controls.client.valid"
              [isSearch]="true"
              [required]="true"
            ></app-select>

            <app-select
              label="Status"
              placeholder="Selecione um status"
              errorMessage="campo obrigatório"
              [options]="activeOptions"
              [control]="form.controls.active"
              [error]="!form.controls.active.valid"
              [required]="true"
            ></app-select>

            <div class="period">
              <app-input
                label="Data de Vigência"
                placeholder="De"
                [control]="form.controls.initialDate"
                [disabled]="false"
                [type]="InputTypeEnum.date"
              ></app-input>
              <app-input
                label=""
                placeholder="Até"
                [control]="form.controls.finalDate"
                [disabled]="false"
                [type]="InputTypeEnum.date"
              ></app-input>
            </div>
          </div>

          <app-primary-button
            class="test"
            label="Download"
            icon="assets/icons/download_white.svg"
            width="100%"
            [color]="ButtonColorEnum.primary"
            [loading]="downloading"
            [enabled]="!downloading"
            (clickFunc)="downloadFile()"
          ></app-primary-button>

          <app-uploader
            #appUploader
            description="Selecione ou arraste o arquivo para upload aqui"
            [control]="form.controls.file"
            [extensions]="['.xlsm', '.xlsx']"
            (change)="updateButtonState()"
          ></app-uploader>
        </div>
      </div>
      <div footer class="footer">
        <app-secondary-button
          label="Cancelar"
          [color]="ButtonColorEnum.primary"
          (clickFunc)="closeModal()"
        ></app-secondary-button>
        <app-primary-button
          label="Salvar"
          [color]="ButtonColorEnum.common"
          [loading]="isUpdating"
          [enabled]="!isUpdating && !loadingClients && isFormValid()"
          (clickFunc)="updateContactReason()"
        ></app-primary-button>
      </div>
    </main>
  </article>
</div>

<app-modal-response
  [showModal]="showModalResponse"
  [title]="titleModalResponse"
  (closeModalFunc)="closeModalResponse()"
  [icon]="iconButtonModalResponse"
>
  <strong main> {{ message }} </strong>

  <app-secondary-button
    footer
    label="Voltar"
    [right]="false"
    [color]="ButtonColorEnum.primary"
    (clickFunc)="closeModalResponse()"
  ></app-secondary-button>

  <app-primary-button
    footer
    *ngIf="!error"
    class="btn-ok"
    label="Ok"
    [right]="false"
    [color]="ButtonColorEnum.common"
    (clickFunc)="closeModalResponse()"
  ></app-primary-button>
</app-modal-response>
