import { Component, Input, ViewChild } from '@angular/core';
import {
  DatasetPieChart,
  PieChartParams,
} from 'src/app/shared/pie-chart/models/pie-chart.model';
import { PieChartComponent } from 'src/app/shared/pie-chart/pie-chart.component';

@Component({
  selector: 'app-dashboard-shortcalls-total',
  templateUrl: './dashboard-shortcalls-total.component.html',
  styleUrls: ['./dashboard-shortcalls-total.component.scss'],
})
export class DashboardShortcallsTotalComponent {
  @Input() shortCalls!: number;
  @Input() otherTranscriptions!: number;
  @Input() loading!: boolean;

  @ViewChild(PieChartComponent) pieChartComponent!: PieChartComponent;

  error: boolean = false;
  params!: PieChartParams;

  ngOnInit() {
    this.generate();
  }

  ngOnChanges() {
    this.generate();
  }

  generate() {
    this.error = false;

    if (this.loading) return;

    if (isNaN(this.shortCalls) || isNaN(this.otherTranscriptions)) {
      this.error = true;
      return;
    }

    const datasetPie: DatasetPieChart = {
      title: 'Relação Short Calls e Total de Ligações',
      values: [this.shortCalls.toString(), this.otherTranscriptions.toString()],
      backgroundColor: ['rgba(145, 202, 197, 1)', 'rgba(14, 165, 233, 1)'],
      borderColor: undefined,
      borderWidth: 1,
    };

    this.params = {
      footerOptions: ['Short Calls', 'Demais Lig.'],
      datasets: [datasetPie],
    };

    this.createChart();
  }

  createChart() {
    this.pieChartComponent.createChart(this.params, 'shortCallsTotal');
  }
}
