import { Component, ContentChildren, ElementRef, EventEmitter, HostListener, Output, QueryList, ViewChild } from '@angular/core';
import { ResultTabComponent } from './result-tab/result-tab.component';

@Component({
  selector: 'app-result-tabs',
  templateUrl: './result-tabs.component.html',
  styleUrls: ['./result-tabs.component.scss'],
})
export class ResultTabsComponent {
  @ContentChildren(ResultTabComponent)
  tabs!: QueryList<ResultTabComponent>;
  @ViewChild('footer') footer!: ElementRef<HTMLElement>;
  @Output() emmiter: EventEmitter<ResultTabComponent> =
    new EventEmitter<ResultTabComponent>();

  public activeTab!: ResultTabComponent;
  public pressed: boolean = false;
  public grabbing: boolean = false;
  public position = {
    left: 0,
    x: 0,
  };

  @HostListener('document:mousedown', ['$event'])
  mouseDownHandler(event: MouseEvent) {
    const children = Array.from(this.footer.nativeElement.children);
    const element = event.target as Element;

    if (
      event.target !== this.footer.nativeElement &&
      !children.includes(element)
    )
      return;

    this.pressed = true;
    this.position = {
      left: this.footer.nativeElement.scrollLeft,
      x: event.clientX,
    };
  }

  @HostListener('document:mousemove', ['$event'])
  mouseMoveHandler(event: MouseEvent) {
    if (!this.pressed) return;

    this.grabbing = true;
    const dx = event.clientX - this.position.x;
    this.footer.nativeElement.scrollLeft = this.position.left - dx;
  }

  @HostListener('document:mouseup', ['$event'])
  mouseUpHandler() {
    this.pressed = false;

    setTimeout(() => {
      this.grabbing = false;
    }, 0);
  }

  ngAfterViewInit() {
    if (!this.activeTab) {
      setTimeout(() => {
        this.activeTab = this.tabs.first;
      }, 0);
    }
  }

  selectTab(tab: ResultTabComponent) {
    if (this.activeTab === tab || this.grabbing) return;

    this.activeTab = tab;

    this.emmiter.emit(tab);
  }
}
