import { Component } from '@angular/core';
import { GetUsersFilter as GetUsersFilter } from './service/models/user.service.model';

@Component({
  selector: 'app-admin',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent {
  filter!: GetUsersFilter;

  setFilter(filter: GetUsersFilter) {
    this.filter = filter;
  }
}
