import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { finalize } from 'rxjs';
import { UserRoleEnum } from 'src/app/common/services/auth/enums/user-role.enum';
import { StorageService } from 'src/app/common/services/storage/storage.service';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { CategoryAnalysisReportService } from '../service/category-analysis-report.service';
import {
  CategoryAnalysisReportGetFilter,
  CategoryAnalysisReportGetResponse,
} from '../service/models/category-analysis-report.service.model';

@Component({
  selector: 'app-category-analysis-report-list',
  templateUrl: './category-analysis-report-list.component.html',
  styleUrls: ['./category-analysis-report-list.component.scss'],
})
export class CategoryAnalysisReportListComponent implements OnInit, OnChanges {
  constructor(
    private storageService: StorageService,
    public router: Router,
    public categoryAnalysisReportService: CategoryAnalysisReportService
  ) {}

  @Input() filter!: CategoryAnalysisReportGetFilter;

  hasSupervisorRole: boolean = false;
  isLoading: boolean = false;
  categoryAnalysisReportList: CategoryAnalysisReportGetResponse[] = [];
  totalPages: number = 0;
  page: number = 1;
  totalItems: number = 0;
  pageSize: number = 10;
  isExporting: boolean = false;

  ButtonColorEnum = ButtonColorEnum;

  ngOnInit(): void {
    const role = this.storageService.getRole();
    this.hasSupervisorRole = role === UserRoleEnum.supervisor;
  }

  ngOnChanges() {
    if (this.filter) this.get();
  }

  handleSelectPage(index: number) {
    this.page = index;
    this.clearItems();
    this.get();
  }

  clearItems() {
    this.categoryAnalysisReportList = [];
  }

  get() {
    if (this.isLoading) return;

    this.isLoading = true;
    this.categoryAnalysisReportList = [];

    const filters = JSON.parse(JSON.stringify(this.filter));

    filters.pageNumber = this.page;
    filters.pageSize = this.pageSize;

    this.categoryAnalysisReportService
      .get(filters)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (data) => {
          this.totalPages = data.totalPages;
          this.totalItems = data.totalItems;
          this.setSaReportList(data.items);
        },
      });
  }

  setSaReportList(data: CategoryAnalysisReportGetResponse[]) {
    if (!data?.length) return;

    this.categoryAnalysisReportList = data.map((form) => ({
      attendant: form.attendant,
      serviceDate: form.serviceDate,
      category: form.category,
      keyword: form.keyword,
      quantity: form.quantity,
    }));
  }

  export() {
    this.isExporting = true;

    this.categoryAnalysisReportService
      .export(this.filter)
      .pipe(finalize(() => (this.isExporting = false)))
      .subscribe({
        next: this.handleReportDownloadSuccess.bind(this),
      });
  }

  handleReportDownloadSuccess(reportFile: Blob) {
    const fileAnchor = document.createElement('a');
    const data = new Date();
    fileAnchor.download = `RelatorioCategoriasMencionadas-${data
      .getDate()
      .toString()
      .padStart(2, '0')}_${(data.getMonth() + 1)
      .toString()
      .padStart(
        2,
        '0'
      )}_${data.getFullYear()}_${data.getHours()}_${data.getMinutes()}.xlsx`;

    fileAnchor.href = window.URL.createObjectURL(reportFile);
    fileAnchor.click();
    fileAnchor.hidden = true;
    window.URL.revokeObjectURL(fileAnchor.href);
  }
}
