<article>
  <header (click)="handleToggleAccordion()">
    <strong>Filtro</strong>
    <img
      class="accordion-img"
      [ngClass]="{ 'rotate-animation': !isOpen }"
      src="assets/icons/arrow.svg"
    />
  </header>

  <main [ngClass]="{ 'is-collapsed': !isOpen }">
    <div main class="main">
      <div class="grid-content">
        <app-input
          label="Protocolo"
          placeholder="Digite o protocolo"
          [control]="form.controls.protocolNumber"
          [disabled]="false"
        ></app-input>
        <app-input
          label="Atendente"
          placeholder="Digite um nome de atendente"
          [control]="form.controls.attendant"
          [disabled]="false"
        ></app-input>
        <app-select-multi
          label="Categoria"
          placeholder="Selecione múltiplas categorias"
          [multiControl]="form.controls.categories"
          [options]="categories"
          [selected]="selectedCategories"
          property="Type"
          [disabled]="false"
          [multi]="true"
        ></app-select-multi>
      </div>
      <div class="grid-content">
        <div class="period">
          <app-input
            label="Data de Atendimento"
            placeholder="De"
            [control]="form.controls.initialDate"
            [disabled]="false"
            [type]="InputTypeEnum.date"
          ></app-input>
          <app-input
            label=""
            placeholder="Até"
            [control]="form.controls.finalDate"
            [disabled]="false"
            [type]="InputTypeEnum.date"
          ></app-input>
        </div>
        <app-select-multi
          label="Status"
          placeholder="Selecione múltiplos status"
          [multiControl]="form.controls.status"
          [options]="status"
          [selected]="selectedStatus"
          property="Type"
          [disabled]="false"
          [multi]="true"
        ></app-select-multi>
        <app-select-multi
          label="Tipos"
          placeholder="Selecione múltiplos tipos"
          [multiControl]="form.controls.types"
          [options]="types"
          [selected]="selectedTypes"
          property="types"
          [disabled]="false"
          [multi]="true"
        ></app-select-multi>
      </div>
    </div>

    <hr />

    <div footer class="footer">
      <app-secondary-button
        (click)="clear()"
        (keydown.enter)="clear()"
        label="Limpar"
        [color]="ButtonColorEnum.warning"
        [icon]="'assets/icons/trash.svg'"
        [enabled]="true"
        [widthFull]="true">
      </app-secondary-button>
      <app-primary-button
        [label]="'Filtrar'"
        [color]="ButtonColorEnum.common"
        [loading]="isLoading"
        (clickFunc)="search()"
      ></app-primary-button>
    </div>
  </main>
</article>
