<div class="container">
  <strong>Resolutividade</strong>
  <label
    >Período: {{ filter.startDate | date : "dd/MM/yyyy" }} a
    {{ filter.endDate | date : "dd/MM/yyyy" }}</label
  >
  <div *ngIf="!loadingPercentage" class="resoluteness">
    <div>
      <div>
        <div class="bar" [style.width.px]="resolutenessFullBarSize"></div>
        <div
          class="percentage-bar"
          [style.width.px]="resolutenessBarSize"
        ></div>
      </div>
      <div class="percentage">{{ averageResolutenessPercentage }}%</div>
    </div>
  </div>

  <div *ngIf="loadingPercentage" class="skeleton">
    <div class="skeleton-column">
      <div class="skeleton-item"></div>
    </div>
  </div>

  <app-primary-button
    icon="assets/icons/ia_insights.svg"
    label="Ver Insights da I.A"
    (clickFunc)="generate()"
    [loading]="loadingInsight"
  >
  </app-primary-button>
</div>

<app-modal-response
  [showModal]="showModal"
  title="Insights da I.A"
  (closeModalFunc)="closeModal()"
  icon="assets/icons/ia_insights.svg"
>
  <strong main> {{ insight }} </strong>

  <app-secondary-button
    footer
    class="secondary-buttom"
    label="Voltar"
    (clickFunc)="closeModal()"
  ></app-secondary-button>

  <div footer class="copy">
    <app-primary-button
      label="Copiar"
      icon="assets/icons/copy.svg"
      [color]="ButtonColorEnum.common"
      (clickFunc)="copy()"
    >
    </app-primary-button>
    <div main class="mensagem-flutuante" *ngIf="copied">copiado!</div>
  </div>
</app-modal-response>
