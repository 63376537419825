import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/common/services/storage/storage.service';
import { GetClientsFilter, GetClientsResponse } from '../models/client.model';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { UserRoleEnum } from 'src/app/common/services/auth/enums/user-role.enum';
import { finalize } from 'rxjs';
import { ClientService } from '../service/client.service';

@Component({
  selector: 'app-client-list',
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.scss']
})
export class ClientListComponent {
  constructor(
    private clientService: ClientService,
    private storageService: StorageService,
    public router: Router,
  ) {}

  @Input() filter!: GetClientsFilter;

  clientList: GetClientsResponse[] = [];
  page: number = 1;
  pageSize: number = 10;
  totalItems: number = 0;
  totalPages: number = 0;
  isLoading: boolean = false;
  hasSupervisorRole: boolean = false;

  get ButtonColorEnum() {
    return ButtonColorEnum;
  }

  ngOnInit(): void {
    const role = this.storageService.getRole();
    this.hasSupervisorRole = role === UserRoleEnum.supervisor;

    var currentPage = this.storageService.getCurrentPage();

    this.filter = {} as GetClientsFilter;
    this.filter.pageNumber = parseInt(currentPage);
    this.page = parseInt(currentPage);
    this.get();
  }

  ngOnChanges() {
    if (this.filter) {
      this.resetPage();
      this.get();
    }
  }

  resetPage() {
    this.page = 1;
  }

  get() {
    if (this.isLoading) return;

    this.isLoading = true;
    this.clearItems();

    const filters = JSON.parse(JSON.stringify(this.filter));
    
    filters.pageNumber = this.page;
    filters.pageSize = this.pageSize;

    this.clientService
      .get(filters)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (data) => {
          this.totalPages = data.totalPages;
          this.totalItems = data.totalItems;
          this.setClientList(data.items);
        },
        error: () => {
          this.clearItems();
        },
      });
  }

  setClientList(data: GetClientsResponse[]) {
    if (!data?.length) return;

    this.clientList = data.map((form) => ({
      id: form.id,
      name: form.name,
      active: form.active,
      cnpj: form.cnpj,
      creationDate: form.creationDate,
      skills: form.skills,
      holdingLineTime: form.holdingLineTime,
      idTimeExceeded: form.idTimeExceeded,
      returnTimeExceeded: form.returnTimeExceeded,
      minimumCallTime: form.minimumCallTime,
      shifts: form.shifts
    }));
  }

  handleSelectPage(index: number) {
    this.page = index;
    this.clearItems();
    this.get();
    this.storageService.setCurrentPage(index.toString());
  }

  clearItems() {
    this.clientList = [];
  }

  navigateToNewForm() {
    this.router.navigate(['/client/new']);
  }
}
