<div class="container">
  <article>
    <header>
      <strong>Editar Categoria</strong>
    </header>
    <main>
      <div main class="main">
        <div *ngIf="loadingCategory" class="loading-animation"></div>

        <div *ngIf="!loadingCategory" class="grid-content">
          <div class="grid">
            <app-input
              label="Nome da Categoria"
              placeholder="Informe o nome da nova categoria"
              errorMessage="campo obrigatório"
              [control]="form.controls.name"
              [error]="
                !form.controls.name.valid && !form.controls.name.pristine
              "
              [disabled]="false"
            ></app-input>

            <app-select
              label="Status"
              placeholder="Selecione um status"
              errorMessage="campo obrigatório"
              [options]="activeOptions"
              [control]="form.controls.active"
              [error]="!form.controls.active.valid"
            ></app-select>

            <app-select
              label="Vincular Cliente"
              placeholder="Selecione um cliente"
              errorMessage="campo obrigatório"
              [options]="clientOptions"
              [disabled]="false"
              [control]="form.controls.client"
              [loading]="loadingClients"
              [disabled]="loadingClients"
              [error]="!loadingClients && !form.controls.client.valid"
              [isSearch]="true"
            ></app-select>

            <app-input
              label="Cor"
              [control]="form.controls.color"
              errorMessage="campo obrigatório"
              [error]="
                !form.controls.color.valid && !form.controls.color.pristine
              "
              [type]="InputTypeEnum.color"
            ></app-input>
            <app-select
              label="Enviar Alerta de E-mail"
              placeholder="Selecione uma opção"
              errorMessage="campo obrigatório"
              [options]="sendEmailAlertOptions"
              [control]="form.controls.sendEmailAlert"
            ></app-select>

            <app-select-multi
              label="Lista de recebimento"
              placeholder="Selecione múltiplos colaboradores"
              errorMessage="campo obrigatório"
              [options]="receiversOptions"
              [disabled]="
                form.controls.sendEmailAlert.value === '' ||
                form.controls.sendEmailAlert.value === 'Não'
              "
              [loading]="loadingReceivers"
              [isSearch]="true"
              [multi]="true"
              [multiControl]="form.controls.usersEmailAlert"
              [selected]="selectedReceivers"
            ></app-select-multi>
          </div>

          <div class="flex">
            <app-input
              label="Adicionar Palavras-Chave"
              placeholder="Digite aqui as palavras que identifiquem a categoria"
              errorMessage="campo obrigatório"
              [control]="form.controls.keyword"
              [error]="
                !form.controls.keyword.valid && !form.controls.keyword.pristine
              "
              [disabled]="false"
              (keyup.enter)="addKeyword()"
              [maxlength]="100"
            ></app-input>
            <app-primary-button
              class="second-row-button"
              label="Adicionar"
              [icon]="'assets/icons/add.svg'"
              [color]="ButtonColorEnum.common"
              [widthFull]="true"
              [enabled]="isKeywordValid()"
              (clickFunc)="addKeyword()"
            ></app-primary-button>
          </div>

          <p>Palavras-Chave</p>
          <div class="flex">
            <div class="word-textarea">
              <ng-container
                *ngFor="let keyword of visibleKeywords(); let i = index"
              >
                <div
                  class="keyword tooltip"
                  [ngClass]="{
                    'selected-keyword': keyword == selectedKeyword?.name
                  }"
                >
                  <span
                    class="keyword-text"
                    [ngClass]="{ 'show-all': showAllKeywords }"
                    (click)="setSelectedKeyword(keyword)"
                  >
                    {{ keyword }}
                  </span>

                  <span class="tooltiptext">{{ keyword }}</span>

                  <img
                    class="remove-icon"
                    (click)="removeKeyword(i)"
                    src="assets/icons/trash.svg"
                    alt="Remover"
                  />
                </div>
              </ng-container>
              <button
                class="see-all-btn"
                *ngIf="showSeeAllKeywordsButton()"
                (click)="toggleShowAllKeywords()"
              >
                Ver Tudo ({{ keywords.length }})
              </button>
            </div>
          </div>

          <div *ngIf="selectedKeyword" class="flex">
            <app-input
              label="Adicionar Variações"
              placeholder="Digite aqui variações e clique no botão ao lado"
              [control]="form.controls.variation"
              [error]="
                !form.controls.variation.valid &&
                !form.controls.variation.pristine
              "
              (keyup.enter)="addKeywordVariation()"
              [maxlength]="100"
            ></app-input>
            <app-primary-button
              class="second-row-button"
              label="Adicionar"
              [icon]="'assets/icons/add.svg'"
              [color]="ButtonColorEnum.common"
              [enabled]="isKeywordVariationValid()"
              (click)="addKeywordVariation()"
            ></app-primary-button>
          </div>

          <p *ngIf="selectedKeyword">Variações</p>
          <div *ngIf="selectedKeyword" class="flex">
            <div class="word-textarea">
              <ng-container
                *ngFor="let variation of visibleVariations(); let i = index"
              >
                <div class="keyword-variation tooltip">
                  <span
                    class="keyword-variation-text"
                    [ngClass]="{ 'show-all': showAllVariations }"
                  >
                    {{ variation.name }}
                  </span>

                  <span class="tooltiptext">{{ variation.name }}</span>

                  <img
                    class="remove-icon"
                    (click)="removeKeywordVariation(i)"
                    src="assets/icons/trash.svg"
                    alt="Remover"
                  />
                </div>
              </ng-container>
              <button
                class="see-all-btn"
                *ngIf="showSeeAllVariationsButton()"
                (click)="toggleShowAllVariations()"
              >
                Ver Tudo ({{ selectedKeyword.keywordVariations.length }})
              </button>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div footer class="footer">
        <app-secondary-button
          label="Cancelar"
          [color]="ButtonColorEnum.primary"
          (clickFunc)="closeModal()"
        ></app-secondary-button>
        <app-primary-button
          label="Salvar"
          [color]="ButtonColorEnum.common"
          [loading]="isLoading"
          [enabled]="!loadingCategory && !isLoading && isFormComplete()"
          (clickFunc)="updateCategory()"
        ></app-primary-button>
      </div>
    </main>
  </article>
</div>

<app-modal-response
  [showModal]="showModalResponse"
  [title]="titleModalResponse"
  (closeModalFunc)="closeModalResponse()"
  [icon]="iconButtonModalResponse"
>
  <strong main> {{ message }} </strong>

  <app-secondary-button
    footer
    label="Voltar"
    [right]="false"
    [color]="ButtonColorEnum.primary"
    (clickFunc)="closeModalResponse()"
  ></app-secondary-button>

  <app-primary-button
    footer
    *ngIf="!error"
    class="btn-ok"
    label="Ok"
    [right]="false"
    [color]="ButtonColorEnum.common"
    (clickFunc)="closeModalResponse()"
  ></app-primary-button>
</app-modal-response>
