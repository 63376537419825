<article>
  <header>
    <strong>Atendimentos</strong>
    <section>
      <app-secondary-button
        *ngIf="hasSupervisorRole"
        label="Reprocessar"
        icon="assets/icons/refresh.svg"
        [color]="ButtonColorEnum.primary"
        (clickFunc)="handleReprocessButton()"
        [loading]="reprocessLoading"
        [enabled]="incidentIdsToReprocess.length > 0"
      ></app-secondary-button>
      <app-primary-button
        *ngIf="hasSupervisorRole"
        label="Novo Atendimento"
        icon="assets/icons/add.svg"
        [color]="ButtonColorEnum.common"
        (clickFunc)="handleShowNewTranscription()"
      ></app-primary-button>
    </section>
  </header>
  <main>
    <app-transcription-list-header
      [filter]="filter"
      [totalItems]="totalItems"
      [incidentIdsToReprocessCount]="incidentIdsToReprocess.length"
      [incidentIdsLoading]="isLoading"
      (incidentIdsToReprocess)="setIncidentIdsToReprocess($event)"
    ></app-transcription-list-header>
    <ng-container *ngIf="isLoading">
      <div *ngFor="let i of [1, 2, 3, 4, 5]" class="skeleton">
        <div class="skeleton-column">
          <div class="skeleton-item"></div>
        </div>
      </div>
    </ng-container>
    <app-transcription-list-item
      [incidentIdsToReprocessInput]="incidentIdsToReprocess"
      (incidentIdsToReprocessOutput)="setIncidentIdsToReprocess($event)"
      *ngFor="let transcription of transcriptionsList"
      [data]="transcription"
      (handleReloadPage)="handleReloadPage()"
    ></app-transcription-list-item>

    <app-paginator
      [page]="page"
      [totalPages]="totalPages"
      [elementsShowing]="transcriptionsList.length"
      [totalElements]="totalItems"
      (selectPageFunc)="handleSelectPage($event)"
    ></app-paginator>
  </main>
</article>

<!-- <app-transcription-modal-new
  [showModal]="showNewTranscriptionModal"
  (handleShowModal)="handleShowNewTranscriptionModal($event)"
></app-transcription-modal-new> -->

<app-modal-response
  [showModal]="showReprocessModal"
  [title]="
    incidentIdsToReprocess.length == 1
      ? 'Tem certeza que deseja reprocessar este atendimento?'
      : 'Tem certeza que deseja reprocessar estes atendimentos?'
  "
  (closeModalFunc)="closeReprocessModal()"
  [icon]="'assets/icons/warning.svg'"
>
  <strong main>
    <div *ngIf="incidentIdsToReprocess.length == 1">
      {{ incidentIdsToReprocess.length }} item selecionado.
    </div>
    <div *ngIf="incidentIdsToReprocess.length != 1">
      {{ incidentIdsToReprocess.length }} itens selecionados.
    </div>
  </strong>

  <app-secondary-button
    footer
    label="Cancelar"
    [right]="false"
    [color]="ButtonColorEnum.primary"
    (clickFunc)="closeReprocessModal()"
  ></app-secondary-button>

  <app-primary-button
    footer
    class="btn-ok"
    [label]="'Reprocessar'"
    [right]="false"
    [color]="ButtonColorEnum.common"
    (clickFunc)="reprocessIncidents()"
  ></app-primary-button>
</app-modal-response>
