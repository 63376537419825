import { Injectable } from '@angular/core';
import { Buffer } from 'buffer';
import { User } from '../auth/models/user.model';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private readonly TOKEN_KEY = 'NECXTT';
  private readonly ROLE_KEY = 'NECXTR';
  private readonly USER_KEY = 'NECXTU';
  private readonly LOGIN_TYPE = 'NECXTTR';
  private readonly CURRENT_PAGE = 'NECXTTRU';
  private readonly CURRENT_FILTER = 'NECXRTTRU';

  clear(): void {
    window.localStorage.clear();
    window.sessionStorage.clear();
  }

  getToken() {
    const tokenB64 = window.localStorage.getItem(this.TOKEN_KEY) ?? '';
    return Buffer.from(tokenB64, 'base64').toString();
  }

  setToken(token: string) {
    const tokenB64 = Buffer.from(token).toString('base64');
    window.localStorage.removeItem(this.TOKEN_KEY);
    window.localStorage.setItem(this.TOKEN_KEY, tokenB64);
  }

  getRole() {
    const rolesB64 = window.localStorage.getItem(this.ROLE_KEY) ?? '';

    try {
      return JSON.parse(Buffer.from(rolesB64, 'base64').toString()) as string;
    } catch (error: any) {
      return '';
    }
  }

  setRole(roles: string) {
    const rolesStr = JSON.stringify(roles);
    const rolesB64 = Buffer.from(rolesStr).toString('base64');
    window.localStorage.removeItem(this.ROLE_KEY);
    window.localStorage.setItem(this.ROLE_KEY, rolesB64);
  }

  getUser() {
    const userB64 = window.localStorage.getItem(this.USER_KEY) ?? '';

    try {
      return JSON.parse(Buffer.from(userB64, 'base64').toString()) as User;
    } catch (error: any) {
      return {} as User;
    }
  }

  setUser(user: User) {
    const userStr = JSON.stringify(user);
    const userB64 = Buffer.from(userStr).toString('base64');
    window.localStorage.removeItem(this.USER_KEY);
    window.localStorage.setItem(this.USER_KEY, userB64);
  }

  setCurrentPage(page: string) {
    const pageB64 = Buffer.from(page).toString('base64');
    window.localStorage.removeItem(this.CURRENT_PAGE);
    window.localStorage.setItem(this.CURRENT_PAGE, pageB64);
  }

  getCurrentPage() {
    const pageB64 = window.localStorage.getItem(this.CURRENT_PAGE) ?? '';

    try {
      return Buffer.from(pageB64, 'base64').toString();
    } catch (error: any) {
      return '';
    }
  }

  setCurrentFilter(filter: any) {
    const pageB64 = Buffer.from(filter).toString('base64');
    window.localStorage.removeItem(this.CURRENT_FILTER);
    window.localStorage.setItem(this.CURRENT_FILTER, pageB64);
  }

  getCurrentFilter() {
    const pageB64 = window.localStorage.getItem(this.CURRENT_FILTER) ?? '';

    try {
      return Buffer.from(pageB64, 'base64').toString();
    } catch (error: any) {
      return '';
    }
  }
}
