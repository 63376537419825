import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, throwError } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor {

  constructor(
    private router: Router,
    private authService: AuthService,
    private storageService: StorageService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    let request = req;
                                                                       
    if (!req.url.endsWith('/oauth/access-token') && !req.url.includes('speech-ai-api-s3-bucket.s3.amazonaws.com')) {
      const token = this.storageService.getToken();
      request = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }

    return next.handle(request).pipe(catchError((error) => {
      if (error.status === 401) {
        this.authService.logout(true);
      } else if (error.status === 403) {
        this.router.navigate(['access-denied']);
      }

      return throwError(() => error);
    }));
  }
}
