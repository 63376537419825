<section
  [ngClass]="{
    error: error,
    disabled: disabled,
  }"
  (clickOutside)="handleBlurSelect()"
>
  <label>{{ label }}</label>
  <div
    class="select"
    [ngClass]="{ focused: focused }"
    (click)="handleToggleFocus()"
  >
    <span
      [ngClass]="{ placeholder: !selectedOption || !selectedOption.display }"
    >
      {{
        selectedOption
          ? selectedOption.display
            ? selectedOption.display
            : placeholder
          : placeholder
      }}
    </span>
    <img src="assets/icons/arrow.svg" alt="Arrow Icon" />
  </div>

  <footer>
    <div *ngIf="error">
      <img src="assets/icons/error.svg" alt="Error Icon" />
      <span>{{ errorMessage }}</span>
    </div>
  </footer>

  <div *ngIf="focused" class="options">
    <p *ngIf="options.length == 0">nenhum dado para listagem.</p>
    <span
      *ngFor="let option of options"
      [ngClass]="{ selected: option.value === control.value }"
      (click)="handleSelectOption(option)"
    >
      {{ option.display }}
    </span>
  </div>
</section>
