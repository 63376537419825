import { Component } from '@angular/core';
import { UserRoleEnum } from 'src/app/common/services/auth/enums/user-role.enum';
import { StorageService } from 'src/app/common/services/storage/storage.service';

@Component({
  selector: 'app-sa_report-list-header',
  templateUrl: './sa_report-list-header.component.html',
  styleUrls: ['./sa_report-list-header.component.scss']
})
export class SaReportListHeaderComponent
{
  public hasSupervisorRole: boolean = false;

  constructor(private storageService: StorageService) { }

  ngOnInit(): void
  {
    const role = this.storageService.getRole();
    this.hasSupervisorRole = role === UserRoleEnum.supervisor;
  }
}
