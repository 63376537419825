import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { StatusEnum } from 'src/app/common/utils/status-enum.models';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { InputTypeEnum } from 'src/app/shared/input/enums/input-type.enum';
import { MaskTypeEnum } from 'src/app/shared/input/enums/mask-type.enum';
import {
  GetClientsFilter,
  GetClientsResponse,
} from '../../client/models/client.model';
import { ClientService } from '../../client/service/client.service';
import { ContactReasonService } from '../service/contact-reason.service';

@Component({
  selector: 'app-contact-reason-details',
  templateUrl: './contact-reason-details.component.html',
  styleUrls: ['./contact-reason-details.component.scss'],
})
export class ContactReasonDetailsComponent {
  constructor(
    public formBuilder: FormBuilder,
    public contactReasonService: ContactReasonService,
    public clientService: ClientService,
    public router: Router,
    private route: ActivatedRoute
  ) {}

  isUpdating: boolean = false;
  success: boolean = false;
  error: boolean = false;
  contactReasonId: string = '';
  activeOptions: string[] = ['Ativo', 'Inativo'];
  showModalResponse: boolean = false;
  titleModalResponse: string = '';
  iconButtonModalResponse: string = '';
  message: string = '';

  clientOptions: string[] = [];
  loadingClients: boolean = false;
  originalClients: GetClientsResponse[] = [];

  selectedStatus: string[] = [];

  uploadedFile: File | null = null;

  downloading: boolean = false;

  form = this.formBuilder.group({
    name: ['', Validators.required],
    active: ['', Validators.required],
    client: ['', Validators.required],
    initialDate: [''],
    finalDate: [''],
    file: [{} as File, Validators.required],
  });

  ButtonColorEnum = ButtonColorEnum;
  MaskTypeEnum = MaskTypeEnum;
  InputTypeEnum = InputTypeEnum;

  ngOnInit() {
    this.route.params.subscribe((params) => {
      const contactReasonIdFromUrl = params['id'];

      if (contactReasonIdFromUrl) {
        this.contactReasonId = contactReasonIdFromUrl;
        this.getContactReason();
      }
    });
    this.populateClients();
  }

  populateClients() {
    this.clientOptions = [];
    this.loadingClients = true;

    this.clientService
      .get({ status: [StatusEnum.ATIVO] } as GetClientsFilter)
      .subscribe({
        next: (data) => {
          this.originalClients = data.items;
          this.clientOptions = data.items.map((i) => i.cnpj + ' | ' + i.name);
          this.loadingClients = false;
        },
        error: (e) => {
          this.loadingClients = false;
          this.error = true;
          console.error(e);

          this.titleModalResponse = 'Ocorreu um erro ao carregar clientes';
          this.iconButtonModalResponse = 'assets/icons/error.svg';
          this.message = 'tente novamente mais tarde.';
          this.showModalResponse = true;
        },
      });
  }

  loadingContactReason = false;

  private getContactReason() {
    this.loadingContactReason = true;
    this.contactReasonService
      .getById(this.contactReasonId)
      .subscribe((contactReason) => {
        if (contactReason) {
          this.form.patchValue({
            name: contactReason.name,
            active: contactReason.active ? 'Ativo' : 'Inativo',
            initialDate: contactReason.initialDate,
            finalDate: contactReason.finalDate,
            client: contactReason.client
              ? contactReason.client.cnpj + ' | ' + contactReason.client.name
              : null,
          });

          this.selectedStatus = contactReason.active ? ['Ativo'] : ['Inativo'];

          this.loadingContactReason = false;
        }
      });
  }

  isFormValid() {
    return this.form.valid;
  }

  closeModalResponse() {
    if (this.error == false) this.closeModal();

    this.showModalResponse = false;
  }

  closeModal() {
    this.form.reset();
    this.router.navigate(['/contact-reason/']);
  }

  updateContactReason() {
    if (this.isUpdating) return;

    this.isUpdating = true;
    this.error = false;

    let formData = new FormData();
    formData.append('name', this.form.controls.name.value ?? '');
    formData.append(
      'clientId',
      this.getClientIdFromText(this.form.controls.client.value) ?? ''
    );
    formData.append(
      'active',
      this.form.controls.active.value === 'Ativo' ? 'true' : 'false'
    );
    formData.append('initialDate', this.form.controls.initialDate.value ?? '');
    formData.append('finalDate', this.form.controls.finalDate.value ?? '');
    formData.append('file', this.form.controls.file.value ?? '');

    this.contactReasonService.update(this.contactReasonId, formData).subscribe({
      next: () => {
        this.isUpdating = false;
        this.titleModalResponse =
          'Importação de motivo de contato atualizada com sucesso!';
        this.iconButtonModalResponse = 'assets/icons/success-primary.svg';
        this.message =
          'Alterações na importação do motivo de contato realizadas com êxito.';
        this.showModalResponse = true;
        this.form.reset();
      },
      error: (e) => {
        this.isUpdating = false;
        this.error = true;
        console.error(e);

        this.titleModalResponse =
          'Ocorreu um erro ao atualizar a importação do motivo de contato';
        this.iconButtonModalResponse = 'assets/icons/error.svg';
        this.showModalResponse = true;
        this.message = e.error ?? '';
      },
    });
  }

  getClientIdFromText(text: string | null): string | null {
    if (!text || text.trim() === '') return null;

    var [cnpj, name] = text.split('|').map((part) => part.trim());
    var client = this.originalClients.find((i) => i.cnpj === cnpj);

    return client ? client.id : null;
  }

  updateButtonState(): boolean {
    const fileControlValue = this.form.controls.file.value;

    if (fileControlValue && !fileControlValue.name) {
      this.titleModalResponse = 'Formato de arquivo inválido';
      this.iconButtonModalResponse = 'assets/icons/error.svg';
      this.message = 'Somente arquivos Excel (.xlsm, .xlsx) são aceitos.';
      this.showModalResponse = true;
      this.error = true;

      this.form.controls.file.setValue(null);
      this.uploadedFile = null;

      return false;
    }

    this.uploadedFile = fileControlValue;

    return (
      this.loadingClients === false &&
      this.uploadedFile !== null &&
      this.isFormValid()
    );
  }

  downloadFile() {
    this.downloading = true;

    this.contactReasonService.downloadFile(this.contactReasonId).subscribe({
      next: this.handleFileDownloadSuccess.bind(this),
      complete: () => {
        this.downloading = false;
      },
      error: (e: HttpErrorResponse) => {
        console.error(e);
        this.downloading = false;
      },
    });
  }

  handleFileDownloadSuccess(reportFile: Blob) {
    const fileAnchor = document.createElement('a');
    const data = new Date();
    fileAnchor.download = `MotivoContato-${data
      .getDate()
      .toString()
      .padStart(2, '0')}_${(data.getMonth() + 1)
      .toString()
      .padStart(
        2,
        '0'
      )}_${data.getFullYear()}_${data.getHours()}_${data.getMinutes()}.xlsx`;

    fileAnchor.href = window.URL.createObjectURL(reportFile);
    fileAnchor.click();
    fileAnchor.hidden = true;
    window.URL.revokeObjectURL(fileAnchor.href);
  }
}
