import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { finalize } from 'rxjs';
import { UserRoleEnum } from 'src/app/common/services/auth/enums/user-role.enum';
import { StorageService } from 'src/app/common/services/storage/storage.service';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import {
  GetUsersFilter,
  GetUserResponse,
} from '../../service/models/user.service.model';
import { UserService } from '../../service/user.service';
import { SignalrService } from 'src/app/pages/transcription/service/signalr.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent implements OnInit {
  constructor(
    private router: Router,
    private userService: UserService,
    private storageService: StorageService
  ) {}

  @Input() filter!: GetUsersFilter;

  userList: GetUserResponse[] = [];
  page: number = 1;
  pageSize: number = 10;
  totalPages: number = 0;
  totalItems: number = 0;
  isLoading: boolean = false;
  hasSupervisorRole: boolean = false;

  get ButtonColorEnum() {
    return ButtonColorEnum;
  }

  ngOnInit(): void {
    this.filter = {} as GetUsersFilter;
    this.get();
    const role = this.storageService.getRole();
    this.hasSupervisorRole = role === UserRoleEnum.supervisor;
  }

  ngOnChanges() {
    if (this.filter) {
      this.resetPage();
      this.get();
    }
  }

  resetPage() {
    this.page = 1;
  }

  get() {
    if (this.isLoading) return;

    this.isLoading = true;
    this.clearItems();

    const filters = JSON.parse(JSON.stringify(this.filter));

    filters.pageNumber = this.page;
    filters.pageSize = this.pageSize;

    this.userService
      .get(filters)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (data) => {
          this.totalItems = data.totalItems;
          this.totalPages = data.totalPages;
          this.setUserList(data.items);
        },
        error: () => {
          this.clearItems();
        },
      });
  }

  setUserList(data: GetUserResponse[]) {
    if (!data?.length || data.length == 0) {
      this.clearItems();
      return;
    }

    this.userList = data.map((user) => ({
      id: user.id,
      name: user.name,
      email: user.email,
      type: user.type,
      functional: user.functional,
      supervisor: user.supervisor,
      creationDate: user.creationDate,
      active: user.active,
      role: user.role,
      recorderId: user.recorderId,
      attendants: user.attendants
    }));
  }

  handleSelectPage(index: number) {
    this.page = index;
    this.clearItems();
    this.get();
  }

  clearItems() {
    this.userList = [];
  }

  navigateToNewForm() {
    this.router.navigate(['/user/new']);
  }
}
