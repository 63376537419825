<button
  [ngClass]="{
    disabled: !enabled,
    right: right,
    common: color === 'common',
    primary: color === 'primary',
    warning: color === 'warning',
    full: widthFull,
  }"
  [disabled]="!enabled"
  (click)="handleButtonClick($event)"
>
  <div *ngIf="loading" class="loading-animation"></div>
  <div *ngIf="icon" class="icon">
    <img *ngIf="!loading && icon" [src]="icon" />
  </div>
  <span>{{ label }}</span>
</button>
