import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { AuthService } from '../../services/auth/auth.service';
import { StorageService } from '../../services/storage/storage.service';

@Injectable(
{
  providedIn: 'root'
})

export class AuthGuardService implements CanActivate
{
  constructor(
    private router: Router,
    private authService: AuthService,
    private storageService: StorageService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot)
  {
    const role = this.storageService.getRole();
    const canActivate = route.data['expectedRole'].includes(role);

    if (!canActivate)
    {
      if (!role)
        this.authService.logout(false);
      else
        this.router.navigate(['access-denied']);
    }

    return canActivate;
  }
}
