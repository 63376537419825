<article>
  <header>
    <strong>Gestão de Categorias</strong>
    <section>
      <app-primary-button
        *ngIf="hasSupervisorRole"
        label="Nova Categoria"
        icon="assets/icons/add.svg"
        [color]="ButtonColorEnum.common"
        (clickFunc)="navigateToNewForm()"
      ></app-primary-button>
    </section>
  </header>
  <main>
    <app-category-list-header></app-category-list-header>
    <ng-container *ngIf="isLoading">
      <div *ngFor="let i of [1, 2, 3, 4, 5]" class="skeleton">
        <div class="skeleton-column">
          <div class="skeleton-item"></div>
        </div>
      </div>
    </ng-container>
    <app-category-list-item
      *ngFor="let form of categoryList"
      [data]="form"
    ></app-category-list-item>
    <app-paginator
      [page]="page"
      [totalPages]="totalPages"
      [elementsShowing]="categoryList.length"
      [totalElements]="totalItems"
      (selectPageFunc)="handleSelectPage($event)"
    ></app-paginator>
  </main>
</article>
