<div class="container">
  <article>
    <header>
      <strong>Novo Usuário</strong>
    </header>

    <main>
      <div main class="main">
        <div class="grid-content">
          <app-input
            label="Nome"
            placeholder="Nome completo"
            errorMessage="campo obrigatório"
            [control]="form.controls.name"
            [error]="!form.controls.name.valid && !form.controls.name.pristine"
            [disabled]="false"
          ></app-input>
          <app-select
            label="Perfil"
            placeholder="Selecione um perfil"
            errorMessage="campo obrigatório"
            [options]="roleOptions"
            [control]="form.controls.perfil"
            [error]="
              !form.controls.perfil.valid && !form.controls.perfil.pristine
            "
            [disabled]="false"
          ></app-select>
          <app-input
            label="Funcional"
            placeholder="Informe o Funcional"
            errorMessage="campo obrigatório"
            [control]="form.controls.functional"
            [error]="
              !form.controls.functional.valid &&
              !form.controls.functional.pristine
            "
            [mask]="MaskTypeEnum.number"
            [maxlength]="11"
            [disabled]="false"
          ></app-input>
          <app-select-multi
            *ngIf="form.controls.perfil.value != 'SUPERVISOR'"
            label="Supervisor"
            placeholder="Selecione um supervisor"
            [singleControl]="form.controls.supervisor"
            [options]="supervisors"
            property="Type"
            [disabled]="loadingSupervisors"
            [multi]="false"
            [loading]="loadingSupervisors"
            [errorMessage]="'campo obrigatório'"
          ></app-select-multi>
          <app-select
            label="Status"
            placeholder="Selecione um status"
            errorMessage="campo obrigatório"
            [options]="activeOptions"
            [control]="form.controls.active"
            [error]="
              !form.controls.active.valid && !form.controls.active.pristine
            "
            [disabled]="false"
          ></app-select>
          <app-input
            label="ID da Gravação"
            placeholder="Informe o código"
            [control]="form.controls.recorderId"
            [error]="
              !form.controls.recorderId.valid &&
              !form.controls.recorderId.pristine
            "
            [disabled]="false"
          ></app-input>
          <div
            class="email-container"
            *ngIf="form.controls.perfil.value != 'ATENDENTE'"
          >
            <app-input
              *ngIf="form.controls.perfil.value != 'ATENDENTE'"
              label="E-mail"
              placeholder="Ex: user@email.com"
              [errorMessage]="
                form.controls.perfil.value == 'ATENDENTE'
                  ? ''
                  : 'campo obrigatório'
              "
              [control]="form.controls.email"
              [error]="!form.controls.email.valid"
              [disabled]="form.controls.perfil.value == 'ATENDENTE'"
            ></app-input>
          </div>
        </div>
      </div>
      <div footer class="footer">
        <app-secondary-button
          label="Cancelar"
          [color]="ButtonColorEnum.primary"
          (clickFunc)="handleNavigateBack()"
        ></app-secondary-button>
        <app-primary-button
          [label]="'Salvar'"
          [color]="ButtonColorEnum.common"
          [loading]="isLoading"
          [enabled]="form.valid"
          (clickFunc)="handleCreateUser()"
        ></app-primary-button>
      </div>
    </main>
  </article>
</div>
