import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TranscriptionResultResponse } from '../transcription-list/transcription-list-item/models/transcription-list-item.model';
import { IResultDTO } from '../transcription-list/transcription-result/models/transcription-result.model';
import {
  GetTranscriptionsFilter,
  PagedTranscriptionGetAllResponse,
} from './models/transcription.service.model';

@Injectable({ providedIn: 'root' })
export class TranscriptionService {
  private baseURL: string = environment.apiUrl + '/api/Incident';

  constructor(private http: HttpClient) {}

  get(
    filter: GetTranscriptionsFilter
  ): Observable<PagedTranscriptionGetAllResponse> {
    const params = this.serializeQueryParams(filter);
    const options = { params: params };

    return this.http.get<PagedTranscriptionGetAllResponse>(
      this.baseURL,
      options
    );
  }

  getIds(filter: GetTranscriptionsFilter): Observable<string[]> {
    const params = this.serializeQueryParams(filter);
    const options = { params: params };
    return this.http.get<string[]>(this.baseURL + '/ids', options);
  }

  private serializeQueryParams(obj: any): any {
    let queryBuilder = new HttpParams();

    Object.keys(obj).forEach((k) => {
      if (Array.isArray(obj[k])) {
        (obj[k] as []).forEach(
          (value) => (queryBuilder = queryBuilder.append(k, value))
        );
      } else if (obj[k]) {
        queryBuilder = queryBuilder.append(k, obj[k]);
      }
    });

    return queryBuilder;
  }

  getTranscription(id: string): Observable<TranscriptionResultResponse> {
    return this.http.get<TranscriptionResultResponse>(
      `${this.baseURL}/${id}/Transcription`
    );
  }

  getAudioUri(id: string): Observable<string> {
    return this.http.get<string>(`${this.baseURL}/${id}/AudioUri`);
  }

  getAudioContent(uri: string): Observable<Blob> {
    return this.http.get(uri, { responseType: 'blob' });
  }

  getResult(id: string): Observable<IResultDTO> {
    return this.http.get<IResultDTO>(`${this.baseURL}/${id}/Result`);
  }

  getById(id: string): Observable<any> {
    return this.http.get<any>(this.baseURL + '/' + id);
  }

  submit(request: any): Observable<any> {
    return this.http.post(`${this.baseURL}/Submit`, request);
  }

  reprocess(ids: string[]): Observable<any> {
    return this.http.put(this.baseURL + '/' + ids + '/Reprocess', null);
  }

  update(id: string, request: any) {
    return this.http.put(this.baseURL + '/' + id, request);
  }

  delete(id: string): Observable<any> {
    return this.http.delete(this.baseURL + '/' + id);
  }
}
