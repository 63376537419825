import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { finalize } from 'rxjs';
import { UserRoleEnum } from 'src/app/common/services/auth/enums/user-role.enum';
import { StorageService } from 'src/app/common/services/storage/storage.service';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { ContactReasonService } from '../service/contact-reason.service';
import {
  GetContactReasonFilter,
  GetContactReasonResponse,
} from '../service/models/contact-reason.service.model';

@Component({
  selector: 'app-contact-reason-list',
  templateUrl: './contact-reason-list.component.html',
  styleUrls: ['./contact-reason-list.component.scss'],
})
export class ContactReasonListComponent {
  constructor(
    private contactReasonService: ContactReasonService,
    private storageService: StorageService,
    public router: Router
  ) {}

  @Input() filter!: GetContactReasonFilter;

  contactReasonList: GetContactReasonResponse[] = [];
  page: number = 1;
  pageSize: number = 10;
  totalItems: number = 0;
  totalPages: number = 0;
  isLoading: boolean = false;
  hasSupervisorRole: boolean = false;

  get ButtonColorEnum() {
    return ButtonColorEnum;
  }

  ngOnInit(): void {
    const role = this.storageService.getRole();
    this.hasSupervisorRole = role === UserRoleEnum.supervisor;

    this.filter = {} as GetContactReasonFilter;
    this.get();
  }

  ngOnChanges() {
    if (this.filter) {
      this.resetPage();
      this.get();
    }
  }

  resetPage() {
    this.page = 1;
  }

  get() {
    if (this.isLoading) return;

    this.isLoading = true;
    this.clearItems();

    const filters = JSON.parse(JSON.stringify(this.filter));

    filters.pageNumber = this.page;
    filters.pageSize = this.pageSize;

    this.contactReasonService
      .get(filters)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (data) => {
          this.totalPages = data.totalPages;
          this.totalItems = data.totalItems;
          this.setContactReasonList(data.items);
        },
        error: () => {
          this.clearItems();
        },
      });
  }

  setContactReasonList(data: GetContactReasonResponse[]) {
    if (!data?.length) return;

    this.contactReasonList = data.map((form) => ({
      id: form.id,
      createdOn: form.createdOn,
      createdBy: form.createdBy,
      updatedBy: form.updatedBy,
      updatedOn: form.updatedOn,
      active: form.active,
      name: form.name,
      client: form.client,
      initialDate: form.initialDate,
      finalDate: form.finalDate,
      isDeleted: form.isDeleted,
      file: form.file,
    }));
  }

  handleSelectPage(index: number) {
    this.page = index;
    this.clearItems();
    this.get();
  }

  clearItems() {
    this.contactReasonList = [];
  }

  navigateToNewForm() {
    this.router.navigate(['/contact-reason/new']);
  }
}
