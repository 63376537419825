import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { StatusEnum } from 'src/app/common/utils/status-enum.models';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { InputTypeEnum } from 'src/app/shared/input/enums/input-type.enum';
import { MaskTypeEnum } from 'src/app/shared/input/enums/mask-type.enum';
import {
  GetClientsFilter,
  GetClientsResponse,
} from '../../client/models/client.model';
import { ClientService } from '../../client/service/client.service';
import { ContactReasonService } from '../service/contact-reason.service';

@Component({
  selector: 'app-contact-reason-new',
  templateUrl: './contact-reason-new.component.html',
  styleUrls: ['./contact-reason-new.component.scss'],
})
export class ContactReasonNewComponent {
  constructor(
    public formBuilder: FormBuilder,
    public contactReasonService: ContactReasonService,
    public clientService: ClientService,
    public router: Router
  ) {}

  isLoading: boolean = false;
  error: boolean = false;
  activeOptions: string[] = ['Ativo', 'Inativo'];
  showModalResponse: boolean = false;
  titleModalResponse: string = '';
  iconButtonModalResponse: string = '';
  message: string = '';

  clientOptions: string[] = [];
  loadingClients: boolean = false;
  originalClients: GetClientsResponse[] = [];

  uploadedFile: File | null = null;

  downloading: boolean = false;

  form = this.formBuilder.group({
    name: ['', Validators.required],
    active: ['', Validators.required],
    client: ['', Validators.required],
    initialDate: [''],
    finalDate: [''],
    file: [{} as File, Validators.required],
  });

  ButtonColorEnum = ButtonColorEnum;
  MaskTypeEnum = MaskTypeEnum;
  InputTypeEnum = InputTypeEnum;

  ngOnInit() {
    this.populateClients();
  }

  populateClients() {
    this.clientOptions = [];
    this.loadingClients = true;

    this.clientService
      .get({ status: [StatusEnum.ATIVO] } as GetClientsFilter)
      .subscribe({
        next: (data) => {
          this.originalClients = data.items;
          this.clientOptions = data.items.map((i) => i.cnpj + ' | ' + i.name);
          this.loadingClients = false;
        },
        error: (e) => {
          this.loadingClients = false;
          this.error = true;
          console.error(e);

          this.titleModalResponse = 'Ocorreu um erro ao carregar clientes';
          this.iconButtonModalResponse = 'assets/icons/error.svg';
          this.message = 'tente novamente mais tarde.';
          this.showModalResponse = true;
        },
      });
  }

  isFormValid() {
    return this.form.valid;
  }

  closeModalResponse() {
    if (this.error == false) this.closeModal();

    this.showModalResponse = false;
  }

  closeModal() {
    this.form.reset();
    this.router.navigate(['/contact-reason/']);
  }

  createContactReason() {
    if (this.isLoading) return;

    this.isLoading = true;
    this.error = false;

    let formData = new FormData();
    formData.append('name', this.form.controls.name.value ?? '');
    formData.append(
      'clientId',
      this.getClientIdFromText(this.form.controls.client.value) ?? ''
    );
    formData.append(
      'active',
      this.form.controls.active.value === 'Ativo' ? 'true' : 'false'
    );
    formData.append('initialDate', this.form.controls.initialDate.value ?? '');
    formData.append('finalDate', this.form.controls.finalDate.value ?? '');
    formData.append('file', this.form.controls.file.value ?? '');

    this.contactReasonService.create(formData).subscribe({
      next: () => {
        this.isLoading = false;
        this.titleModalResponse =
          'Importação de motivo de contato realizada com sucesso!';
        this.iconButtonModalResponse = 'assets/icons/success-primary.svg';
        this.message =
          'Novo motivo de contato foi adicionado ao sistema com êxito.';
        this.showModalResponse = true;
        this.form.reset();
      },
      error: (e) => {
        this.isLoading = false;
        this.error = true;
        console.error(e);

        this.titleModalResponse =
          'Ocorreu um erro ao importar o motivo de contato';
        this.iconButtonModalResponse = 'assets/icons/error.svg';
        this.showModalResponse = true;
        this.message = e.error ?? '';
      },
    });
  }

  getClientIdFromText(text: string | null): string | null {
    if (!text || text.trim() === '') return null;

    var [cnpj, name] = text.split('|').map((part) => part.trim());
    var client = this.originalClients.find((i) => i.cnpj === cnpj);

    return client ? client.id : null;
  }

  updateButtonState(): boolean {
    const fileControlValue = this.form.controls.file.value;

    if (fileControlValue && !fileControlValue.name) {
      this.titleModalResponse = 'Formato de arquivo inválido';
      this.iconButtonModalResponse = 'assets/icons/error.svg';
      this.message = 'Somente arquivos Excel (.xlsm, .xlsx) são aceitos.';
      this.showModalResponse = true;
      this.error = true;

      this.form.controls.file.setValue(null);
      this.uploadedFile = null;

      return false;
    }

    this.uploadedFile = fileControlValue;

    return (
      this.loadingClients === false &&
      this.uploadedFile !== null &&
      this.isFormValid()
    );
  }

  downloadTemplate() {
    this.downloading = true;

    this.contactReasonService.downloadTemplate().subscribe({
      next: this.handleTemplateDownloadSuccess.bind(this),
      complete: () => {
        this.downloading = false;
      },
      error: (e: HttpErrorResponse) => {
        console.error(e);
        this.downloading = false;
      },
    });
  }

  handleTemplateDownloadSuccess(reportFile: Blob) {
    const fileAnchor = document.createElement('a');
    fileAnchor.download = `TemplateMotivoContato.xlsx`;

    fileAnchor.href = window.URL.createObjectURL(reportFile);
    fileAnchor.click();
    fileAnchor.hidden = true;
    window.URL.revokeObjectURL(fileAnchor.href);
  }
}
