<div class="header">
  <strong>NOME</strong>
  <strong>EMAIL</strong>
  <strong>PERFIL</strong>
  <strong>FUNCIONAL</strong>
  <strong>ID DA GRAVAÇÃO</strong>
  <strong>DATA DE CRIAÇÃO</strong>
  <strong>SUPERVISOR</strong>
  <strong>STATUS</strong>
  <strong *ngIf="hasSupervisorRole">AÇÕES</strong>
</div>
