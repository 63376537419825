import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { StatusEnum } from 'src/app/common/utils/status-enum.models';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { InputTypeEnum } from 'src/app/shared/input/enums/input-type.enum';
import {
  GetClientsFilter,
  GetClientsResponse,
} from '../../client/models/client.model';
import { ClientService } from '../../client/service/client.service';
import { GetContactReasonFilter } from '../service/models/contact-reason.service.model';
import { UserService } from '../../user/service/user.service';
import { GetUsersFilter } from '../../user/service/models/user.service.model';

@Component({
  selector: 'app-contact-reason-filter',
  templateUrl: './contact-reason-filter.component.html',
  styleUrls: ['./contact-reason-filter.component.scss'],
})
export class ContactReasonFilterComponent {
  constructor(
    public formBuilder: FormBuilder,
    public clientService: ClientService,
    public userService: UserService
  ) {}

  @Output() filterEmmiter: EventEmitter<GetContactReasonFilter> =
    new EventEmitter<GetContactReasonFilter>();

  form = this.formBuilder.group({
    name: '',
    status: [[]],
    initialDate: [''],
    finalDate: [''],
    clients: [[]],
    createdBy: [[]],
  });

  isOpen: boolean = false;
  status: string[] = [];
  selectedStatus: string[] = [];
  isLoading: boolean = false;

  clientOptions: string[] = [];
  originalClients: GetClientsResponse[] = [];
  selectedClients: string[] = [];
  loadingClients: boolean = false;

  createdByOptions: string[] = [];
  originalCreators: any[] = [];
  selectedCreators: string[] = [];
  loadingCreators: boolean = false;

  ButtonColorEnum = ButtonColorEnum;
  InputTypeEnum = InputTypeEnum;

  ngOnInit() {
    this.populateStatus();
    this.populateClients();
    this.populateCreators();
  }

  populateStatus() {
    this.status = [StatusEnum.ATIVO, StatusEnum.INATIVO, StatusEnum.EXCLUIDO];
  }

  handleToggleAccordion() {
    this.isOpen = !this.isOpen;
  }

  clear() {
    this.form.reset();
    this.selectedStatus = [];
    this.selectedClients = [];
    this.selectedCreators = [];
  }

  populateCreators() {
    this.loadingCreators = true;
    this.createdByOptions = [];

    this.userService
      .get({ status: [StatusEnum.ATIVO] } as GetUsersFilter)
      .subscribe({
        next: (data) => {
          this.originalCreators = data.items;
          this.createdByOptions = data.items.map(
            (i) => i.functional + ' | ' + i.name
          );
          this.loadingCreators = false;
        },
      });
  }

  populateClients() {
    this.loadingClients = true;
    this.clientOptions = [];

    this.clientService
      .get({ status: [StatusEnum.ATIVO] } as GetClientsFilter)
      .subscribe({
        next: (data) => {
          this.originalClients = data.items;
          this.clientOptions = data.items.map((i) => i.cnpj + ' | ' + i.name);
          this.loadingClients = false;
        },
      });
  }

  getClientIdsFromArray(array: string[]): string[] | null {
    if (!array || array.length === 0) return null;

    var clientIds: string[] = [];
    array.forEach((client) => {
      var [cnpj, name] = client.split('|').map((i) => i.trim());
      var objClient = this.originalClients.find((i) => i.cnpj === cnpj);
      if (objClient && objClient.id) clientIds.push(objClient.id);
    });

    return clientIds;
  }

  getCreatedByIdsFromArray(array: string[]): string[] | null {
    if (!array || array.length === 0) return null;

    var creators: string[] = [];
    array.forEach((creator) => {
      var [functional, name] = creator.split('|').map((i) => i.trim());
      var objCreator = this.originalCreators.find((i) => i.functional === Number(functional));
      
      if (objCreator && objCreator.name) creators.push(objCreator.name);
    });

    return creators;
  }

  search() {
    var clientIds = this.getClientIdsFromArray(
      this.form.controls.clients.value ?? []
    );

    var creators = this.getCreatedByIdsFromArray(
      this.form.controls.createdBy.value ?? []
    );

    let filter: GetContactReasonFilter = {} as GetContactReasonFilter;

    filter.name = this.form.controls.name.value ?? '';
    filter.status = this.form.controls.status.value ?? null;
    filter.startDate = this.form.controls.initialDate.value ?? '';
    filter.endDate = this.form.controls.finalDate.value ?? '';
    filter.clientIds = clientIds;
    filter.createdBy = creators;

    this.filterEmmiter.emit(filter);

    this.isOpen = false;
  }
}
