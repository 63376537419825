import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { BarChartComponent } from 'src/app/shared/bar-chart/bar-chart.component';
import {
  BarChartParams,
  DatasetBarChart,
} from 'src/app/shared/bar-chart/models/bar-chart.model';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { InputTypeEnum } from 'src/app/shared/input/enums/input-type.enum';
import { DashboardService } from '../service/dashboard.service';
import { DashboardFilterRequest } from '../service/models/dashboard.service.model';

@Component({
  selector: 'app-dashboard-daily-tma',
  templateUrl: './dashboard-daily-tma.component.html',
  styleUrls: ['./dashboard-daily-tma.component.scss'],
})
export class DashboardDailyTMAComponent {
  constructor(
    public formBuilder: FormBuilder,
    public dashboardService: DashboardService
  ) {}

  @Input() filter!: DashboardFilterRequest;
  @Output() isLoadingEmmiter: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @ViewChild(BarChartComponent) barChartComponent!: BarChartComponent;

  error: boolean = false;
  isLoading: boolean = false;
  params!: BarChartParams;

  form = this.formBuilder.group({
    initialDate: '',
    finalDate: '',
  });

  ngOnInit() {
    const currentDate = new Date();
    const firstDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );

    this.form.patchValue({
      initialDate: this.formatDate(firstDayOfMonth),
      finalDate: this.formatDate(currentDate),
    });

    this.generate();
  }

  ngOnChanges() {
    if (this.filter) {
      this.generate();
    }
  }

  formatDate(date: Date): string {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }

  get ButtonColorEnum() {
    return ButtonColorEnum;
  }

  get InputTypeEnum() {
    return InputTypeEnum;
  }

  clear() {
    this.form.reset();
  }

  generate() {
    if (this.isLoading) return;

    this.error = false;
    this.isLoading = true;
    this.isLoadingEmmiter.emit(this.isLoading);

    this.dashboardService.getDailyTma(this.filter).subscribe({
      next: (data) => {
        var footerOptions = data.map((i) => i.date);

        const dataset: DatasetBarChart = {
          title: '',
          values: data.map((d) => d.quantity),
          backgroundColor: 'rgba(187, 223, 220, 0.4)',
          pointBackgroundColor: 'rgb(10, 94, 86)',
          datalabelsBackgroundColor: 'rgb(10, 94, 86)',
          datalabelsFontSize: 9,
          borderColor: 'rgb(10, 94, 86)',
          borderWidth: 2,
          borderRadius: 1,
          type: 'line',
          datalabelsAlign: 'top',
          fill: true,
        };

        var datasets: DatasetBarChart[] = [];
        datasets.push(dataset);

        this.params = {
          type: 'line',
          footerOptions: footerOptions,
          datasets: datasets,
          displayLegends: false,
          isStacked: false,
          showDatalabel: true,
        };

        this.createChart();

        this.isLoading = false;
        this.isLoadingEmmiter.emit(this.isLoading);
      },
      error: () => {
        this.error = true;
        this.isLoading = false;
        this.isLoadingEmmiter.emit(this.isLoading);
      },
    });
  }

  createChart() {
    this.barChartComponent.createChart(this.params, 'dailyTMA');
  }
}
