import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs';
import { UserRoleEnum } from 'src/app/common/services/auth/enums/user-role.enum';
import { StorageService } from 'src/app/common/services/storage/storage.service';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import {
  GetTranscriptionsFilter,
  ITranscriptionGetAllResponse,
} from '../service/models/transcription.service.model';
import { SignalrService } from '../service/signalr.service';
import { TranscriptionService } from '../service/transcription.service';
import {
  AttendantOrClient,
  ITranscriptionListItemDTO,
} from './transcription-list-item/models/transcription-list-item.model';
import { ITranscriptionTranscriptionDTO } from './transcription-modal-transcription/models/transcription-modal-transcription.model';

@Component({
  selector: 'app-transcription-list',
  templateUrl: './transcription-list.component.html',
  styleUrls: ['./transcription-list.component.scss'],
})
export class TranscriptionListComponent implements OnInit {
  constructor(
    private transcriptionService: TranscriptionService,
    private signalrService: SignalrService,
    private storageService: StorageService,
    private toastrService: ToastrService,
    private router: Router
  ) {}

  @Input() filter!: GetTranscriptionsFilter;

  transcriptionsList: ITranscriptionListItemDTO[] = [];
  page: number = 1;
  pageSize: number = 10;
  totalPages: number = 0;
  totalItems: number = 0;
  searchTerm: string = '';
  isLoading: boolean = false;
  showNewTranscriptionModal: boolean = false;
  showNewTranscriptionServiceModal: boolean = false;
  showNewTranscriptionStefaniniModal: boolean = false;
  hasSupervisorRole: boolean = false;

  showReprocessModal: boolean = false;
  reprocessLoading: boolean = false;
  incidentIdsToReprocess: string[] = [];

  transcriptionDTO: ITranscriptionTranscriptionDTO = {
    id: '',
    serviceDate: '',
    attendant: {} as AttendantOrClient,
    client: {} as AttendantOrClient,
    transcription: '',
  };

  ButtonColorEnum = ButtonColorEnum;

  ngOnInit(): void {
    this.filter = {} as GetTranscriptionsFilter;

    let currentPage = this.storageService.getCurrentPage();
    let currentFilter = this.storageService.getCurrentFilter();

    if (currentFilter != '') {
      let filter = JSON.parse(currentFilter);
      this.filter.attendant = filter.attendant;
      this.filter.categories = filter.categories;
      this.filter.endDate = filter.endDate;
      this.filter.startDate = filter.startDate;
      this.filter.protocolNumber = filter.protocolNumber;
      this.filter.statusName = filter.statusName;
    }

    this.filter.pageNumber = parseInt(currentPage);
    this.page = parseInt(currentPage);
    this.get();

    const role = this.storageService.getRole();
    this.hasSupervisorRole = role === UserRoleEnum.supervisor;

    this.signalrService.startConnection();
    this.signalrService.hubConnection.on(
      'receivenotification',
      (data: ITranscriptionGetAllResponse) => {
        this.updateTranscriptionsList(data);
      }
    );

    this.showIncidentToast();
  }

  ngOnChanges() {
    this.incidentIdsToReprocess = [];
    if (this.filter) {
      this.resetPage();
      this.get();
    }
  }

  showIncidentToast() {
    const showIncidentReprocessSuccessMessage = localStorage.getItem(
      'showIncidentReprocessSuccessMessage'
    );

    if (showIncidentReprocessSuccessMessage) {
      this.toastrService.success(
        'Atendimento(s) enviado(s) para reprocessamento.'
      );
      localStorage.removeItem('showIncidentReprocessSuccessMessage');
    }

    const showIncidentReprocessErrorMessage = localStorage.getItem(
      'showIncidentReprocessErrorMessage'
    );

    if (showIncidentReprocessErrorMessage) {
      this.toastrService.error(
        'Erro ao enviar atendimento(s) para reprocessamento!'
      );
      localStorage.removeItem('showIncidentReprocessErrorMessage');
    }
  }

  resetPage() {
    this.page = 1;
  }

  updateTranscriptionsList(data: ITranscriptionGetAllResponse) {
    if (!data) return;

    let existent = this.transcriptionsList.find((i) => i.id === data.id);

    if (existent !== undefined) {
      existent.audioStatus = data.audioStatus;
      existent.transcribeStatus = data.transcribeStatus;
      existent.resultStatus = data.resultStatus;
      existent.statusName = data.statusName;
    }
  }

  handleReprocessButton() {
    if (!this.reprocessLoading) {
      this.showReprocessModal = true;
    }
  }

  closeReprocessModal() {
    this.showReprocessModal = false;
  }

  reprocessIncidents() {
    this.showReprocessModal = false;
    this.reprocessLoading = true;

    this.transcriptionService.reprocess(this.incidentIdsToReprocess).subscribe({
      next: () => {
        this.reprocessLoading = false;
        localStorage.setItem('showIncidentReprocessSuccessMessage', 'true');
        window.location.reload();
      },
      error: () => {
        this.reprocessLoading = false;
        localStorage.setItem('showIncidentReprocessErrorMessage', 'true');
        window.location.reload();
      },
    });
  }

  setIncidentIdsToReprocess(incidentIdsToReprocess: string[]) {
    this.incidentIdsToReprocess = incidentIdsToReprocess;
  }

  get() {
    if (this.isLoading) return;

    this.isLoading = true;
    this.clearItems();
    const filters = JSON.parse(JSON.stringify(this.filter));

    filters.pageNumber = this.page;
    filters.pageSize = this.pageSize;

    this.transcriptionService
      .get(filters)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (data) => {
          this.totalPages = data.totalPages;
          this.totalItems = data.totalItems;
          this.setTranscriptionsList(data.items);
        },
        error: () => {
          this.clearItems();
        },
      });
  }

  setTranscriptionsList(data: ITranscriptionGetAllResponse[]) {
    if (!data?.length || data.length == 0) {
      this.clearItems();
      return;
    }

    this.transcriptionsList = data.map((transcription) => ({
      id: transcription.id,
      createdOn: transcription.createdOn,
      createdBy: transcription.createdBy,
      protocolNumber: transcription.protocolNumber,
      serviceDate: transcription.serviceDate,
      mentionedCategories: transcription.mentionedCategories,
      attendant: transcription.attendant,
      client: transcription.client,
      audioStatus: transcription.audioStatus,
      transcribeStatus: transcription.transcribeStatus,
      resultStatus: transcription.resultStatus,
      reprocessStatus: transcription.reprocessStatus,
      deleteStatus: transcription.deleteStatus,
      statusName: transcription.statusName,
      resolutenessPercentage: transcription.resolutenessPercentage,
      shortCall: transcription.shortCall,
      type: transcription.type
    }));
  }

  handleSelectPage(index: number) {
    this.page = index;
    this.clearItems();
    this.get();
    this.storageService.setCurrentPage(index.toString());
  }

  clearItems() {
    this.transcriptionsList = [];
  }

  handleReloadPage() {
    this.page = 1;
    this.get();
  }

  handleShowNewTranscription() {
    this.router.navigate(['/transcription/new']);
  }
}
