<div class="category-item">
  <div class="category-item__column">
    <strong>{{ data.attendant || "-" }}</strong>
  </div>
  <div class="category-item__column">
    <div>{{ data.serviceDate | date : "dd/MM/yyyy" || "-" }}</div>
  </div>
  <div class="category-item__column">
    <strong>{{ data.category || "-" }}</strong>
  </div>
  <div class="category-item__column">
    <div>{{ data.keyword || "-" }}</div>
  </div>
  <div class="category-item__column">
    <div>{{ data.quantity || "-" }}</div>
  </div>
</div>
