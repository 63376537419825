import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dashboard-big-number',
  templateUrl: './dashboard-big-number.component.html',
  styleUrls: ['./dashboard-big-number.component.scss'],
})
export class DashboardBigNumberComponent {
  @Input() content: number | string | undefined = undefined;
  @Input() title!: string;
  @Input() loading!: boolean;
  @Input() maxWidth!: string;
  @Input() backgroundColor: string = '#075985';
  @Input() fontColor: string = '#FFF';
  @Input() titleFontSize!: string;
  @Input() valueFontSize!: string;
  @Input() titleWhiteSpace: string = 'nowrap';
  @Input() minWidth!: string;

  isNaN(value: number | string | undefined) {
    if (typeof value == 'number') return Number.isNaN(value);
    return false;
  }
}
