export function reduceName(value: string) {
  if (!value) return '';

  const values = value.split(' ');
  return values.length > 1
    ? `${values[0]} ${values[values.length - 1]}`
    : values[0];
}

export function getInitials(value: string) {
  if (!value) return '';

  const values = value.split(' ');
  return values.length > 1
    ? `${values[0][0]}${values[values.length - 1][0]}`
    : values[0][0];
}
