import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { StorageService } from 'src/app/common/services/storage/storage.service';
import { UserRoleEnum } from 'src/app/common/services/auth/enums/user-role.enum';
import { SaReportService } from '../../service/sa_report.service';
import { SaReportGetAllResponse } from '../../service/models/sa_report.service.model';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-sa_report-list-item',
  templateUrl: './sa_report-list-item.component.html',
  styleUrls: ['./sa_report-list-item.component.scss'],
})
export class SaReportListItemComponent {
  constructor(
    private sa_reportService: SaReportService,
    private storageService: StorageService,
    private router: Router
  ) {}

  public hasSupervisorRole: boolean = false;
  public downloading: boolean = false;
  public downloadIcon: string = 'download';

  ngOnInit(): void {
    const role = this.storageService.getRole();
    this.hasSupervisorRole = role === UserRoleEnum.supervisor;
  }

  @Input() data: SaReportGetAllResponse = {
    id: '',
    createdBy: '',
    attendants: [],
    startDate: '',
    endDate: '',
  };

  public get ButtonColorEnum() {
    return ButtonColorEnum;
  }

  handleDownloadReport(id: string) {
    this.downloading = true;

    this.sa_reportService.downloadReport(id).subscribe({
      next: this.handleReportDownloadSuccess.bind(this),
      complete: () => {
        this.downloading = false;
      },
      error: (e: HttpErrorResponse) => {
        console.error(e);
        this.downloading = false;
      },
    });
  }

  handleReportDownloadSuccess(reportFile: Blob) {
    const fileAnchor = document.createElement('a');
    const data = new Date();
    fileAnchor.download = `RelatorioAnaliseDeSentimento-${data
      .getDate()
      .toString()
      .padStart(2, '0')}_${(data.getMonth() + 1)
      .toString()
      .padStart(
        2,
        '0'
      )}_${data.getFullYear()}_${data.getHours()}_${data.getMinutes()}.xlsx`;

    fileAnchor.href = window.URL.createObjectURL(reportFile);
    fileAnchor.click();
    fileAnchor.hidden = true;
    window.URL.revokeObjectURL(fileAnchor.href);
  }

}
