import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { finalize } from 'rxjs';
import { SkillService } from 'src/app/common/services/skill/skill.service';
import { StatusEnum } from 'src/app/common/utils/status-enum.models';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { InputTypeEnum } from 'src/app/shared/input/enums/input-type.enum';
import { CategoryService } from '../category/service/category.service';
import { GetCategoriesResponse } from '../category/service/models/category.service.model';
import {
  GetClientsFilter,
  GetClientsResponse,
  GetSkillRequest,
  GetSkillResponse,
} from '../client/models/client.model';
import { ClientService } from '../client/service/client.service';
import {
  GetUserResponse,
  GetUsersFilter,
} from '../user/service/models/user.service.model';
import { UserService } from '../user/service/user.service';
import { AnalyticalReportService } from './service/analytical-report.service';
import { AnalyticalReportFilter } from './service/models/analytical-report.model';

@Component({
  selector: 'app-analytical-report',
  templateUrl: './analytical-report.component.html',
  styleUrls: ['./analytical-report.component.scss'],
})
export class AnalyticalReportComponent {
  constructor(
    public formBuilder: FormBuilder,
    public router: Router,
    public userService: UserService,
    public categoryService: CategoryService,
    public skillService: SkillService,
    public clientService: ClientService,
    public analyticalReportService: AnalyticalReportService,
    private toastrService: ToastrService
  ) {}

  isLoading: boolean = false;

  categories: string[] = [];
  originalCategories: GetCategoriesResponse[] = [];
  selectedCategories: string[] = [];

  supervisors: string[] = [];
  selectedSupervisors: string[] = [];
  originalSupervisors: GetUserResponse[] = [];

  selectedAttendants: string[] = [];
  attendants: string[] = [];
  originalAttendants: GetUserResponse[] = [];

  clients: string[] = [];
  selectedClients: string[] = [];
  originalClients: GetClientsResponse[] = [];

  skills: string[] = [];
  selectedSkills: string[] = [];
  originalSkills: GetSkillResponse[] = [];

  showModalResponse: boolean = false;
  titleModalResponse: string = '';
  iconButtonModalResponse: string = '';
  message: string = '';
  error: boolean = false;
  isValidPeriod: boolean = false;

  shifts: string[] = ['Manhã', 'Tarde', 'Noite', 'Madrugada'];
  selectedShifts: string[] = [];
  loadingShifts = false;

  types: string[] = ['Áudio', 'Chat'];
  selectedTypes: string[] = [];

  form = this.formBuilder.group({
    initialDate: ['', Validators.required],
    finalDate: ['', Validators.required],
    supervisors: [['']],
    attendants: [['']],
    categories: [['']],
    clients: [['']],
    skills: [['']],
    cells: [[]],
    subcells: [[]],
    shifts: [['']],
    types: [['']],
  });

  InputTypeEnum = InputTypeEnum;
  ButtonColorEnum = ButtonColorEnum;

  ngOnInit() {
    this.form.controls.supervisors.valueChanges.subscribe(
      (value: string[] | null) => {
        if (value && value.length > 0) {
          var supervisorIds = this.getSupervisorsIdFromText(value);

          var supervisors = this.originalSupervisors.filter((s) =>
            supervisorIds.includes(s.id)
          );

          const filteredAttendants = supervisors
            .map((s) => s.attendants.map((a) => a))
            .flat();

          this.attendants = filteredAttendants.map(
            (a) => a.name.trim() + ' | ' + a.functional
          );

          this.selectedAttendants = [];
          this.form.controls.attendants.reset();
        } else {
          this.attendants = this.originalAttendants.map(
            (attendant) => attendant.name + ' | ' + attendant.functional
          );

          this.selectedAttendants = [];
          this.form.controls.attendants.reset();
        }

        if (
          !this.form.controls.supervisors.value ||
          this.form.controls.supervisors.value.length === 0 ||
          (this.form.controls.supervisors.value.length === 1 &&
            this.form.controls.supervisors.value[0] === '')
        ) {
          this.attendants = this.originalAttendants.map(
            (attendant) => attendant.name + ' | ' + attendant.functional
          );

          this.selectedAttendants = [];
          this.form.controls.attendants.reset();
        }
      }
    );

    this.form.controls.clients.valueChanges.subscribe(
      (value: string[] | null) => {
        if (value && value.length > 0 && value[0] !== '') {
          var clientIds = this.getClientsIdFromName(value);

          var clients = this.originalClients.filter((s) =>
            clientIds.includes(s.id)
          );

          const filteredSkills = clients
            .map((s) => s.skills.map((a) => a))
            .flat();

          this.skills = filteredSkills.map(
            (i) => i.code + ': ' + i.cell + ' — ' + i.subCell
          );

          this.selectedSkills = [];
          this.form.controls.skills.reset();
        } else {
          this.skills = this.originalSkills.map(
            (i) => i.code + ': ' + i.cell + ' — ' + i.subCell
          );
          this.selectedSkills = [];
          this.form.controls.skills.reset();
        }

        if (
          !this.form.controls.clients.value ||
          this.form.controls.clients.value.length === 0 ||
          (this.form.controls.clients.value.length === 1 &&
            this.form.controls.clients.value[0] === '')
        ) {
          this.skills = this.originalSkills.map(
            (i) => i.code + ': ' + i.cell + ' — ' + i.subCell
          );
          this.selectedSkills = [];
          this.form.controls.skills.reset();
        }
      }
    );

    this.populateSupervisors();
    this.populateAttendants();
    this.populateCategories();
    this.populateClients();
    this.populateSkills();
    this.populateCells();
    this.populateSubCells();
  }

  loadingSupervisors: boolean = false;

  populateSupervisors() {
    this.loadingSupervisors = true;
    this.supervisors = [];

    this.userService
      .get({
        profiles: ['SUPERVISOR'],
        status: [StatusEnum.ATIVO],
      } as GetUsersFilter)
      .subscribe({
        next: (data) => {
          this.originalSupervisors = data.items;

          this.supervisors = this.originalSupervisors.map(
            (supervisor) =>
              supervisor.name.trim() + ' | ' + supervisor.functional
          );

          this.loadingSupervisors = false;
        },
        error: () => {
          this.loadingSupervisors = false;
          this.toastrService.error(
            'Não foi possível carregar os supervisores.'
          );
        },
      });
  }

  loadingAttendants: boolean = false;

  populateAttendants() {
    this.attendants = [];

    this.loadingAttendants = true;

    this.userService
      .get({
        profiles: ['ATENDENTE'],
        status: [StatusEnum.ATIVO],
      } as GetUsersFilter)
      .subscribe({
        next: (data) => {
          this.originalAttendants = data.items;

          this.attendants = this.originalAttendants.map(
            (attendant) => attendant.name + ' | ' + attendant.functional
          );

          this.loadingAttendants = false;
        },
        error: () => {
          this.loadingAttendants = false;
          this.toastrService.error('Não foi possível carregar os atendentes.');
        },
      });
  }

  getFilteredShiftsValue(text: string[] | null): string[] | null {
    if (!text || text.length == 0 || (text.length == 1 && text[0] == ''))
      return null;
    return this.form.controls.shifts.value;
  }

  loadingCategories = false;

  populateCategories() {
    this.categories = [];

    this.loadingCategories = true;

    this.categoryService.getAll().subscribe({
      next: (data) => {
        this.originalCategories = data;
        this.categories = this.originalCategories.map(
          (category) => category.name
        );
        this.loadingCategories = false;
      },
      error: () => {
        this.loadingCategories = false;
        this.toastrService.error('Não foi possível carregar as categorias.');
      },
    });
  }

  loadingClients: boolean = false;

  populateClients() {
    this.clients = [];

    this.loadingClients = true;

    this.clientService
      .get({ status: [StatusEnum.ATIVO] } as GetClientsFilter)
      .subscribe({
        next: (data) => {
          this.originalClients = data.items;
          this.clients = this.originalClients.map((i) => i.name.trim());
          this.loadingClients = false;
        },
        error: () => {
          this.loadingClients = false;
          this.toastrService.error('Não foi possível carregar os clientes.');
        },
      });
  }

  loadingSkills: boolean = false;

  populateSkills() {
    this.loadingSkills = true;
    this.skills = [];

    this.skillService.get({ active: true } as GetSkillRequest).subscribe({
      next: (data) => {
        this.originalSkills = data;

        this.skills = this.originalSkills.map(
          (i) => i.code + ': ' + i.cell + ' — ' + i.subCell
        );

        this.loadingSkills = false;
      },
      error: () => {
        this.loadingSkills = false;
        this.toastrService.error('Não foi possível carregar as skills.');
      },
    });
  }

  loadingCells: boolean = false;
  cells: string[] = [];

  populateCells() {
    this.loadingCells = true;

    this.skillService.getCells().subscribe({
      next: (data) => {
        this.cells = data;
        this.loadingCells = false;
      },
      error: () => {
        this.toastrService.error('Não foi possível carregar as células.');
        this.loadingCells = false;
      },
    });
  }

  loadingSubcells: boolean = false;
  subCells: string[] = [];

  populateSubCells() {
    this.loadingSubcells = true;

    this.skillService.getSubCells().subscribe({
      next: (data) => {
        this.subCells = data;
        this.loadingSubcells = false;
      },
      error: () => {
        this.toastrService.error('Não foi possível carregar as sub-células.');
        this.loadingSubcells = false;
      },
    });
  }

  isOpen: boolean = true;

  handleToggleAccordion() {
    this.isOpen = !this.isOpen;
  }

  generate() {
    this.isLoading = true;
    this.error = false;

    let filter: AnalyticalReportFilter = {} as AnalyticalReportFilter;

    filter.startDate = this.form.controls.initialDate.value ?? '';
    filter.endDate = this.form.controls.finalDate.value ?? '';

    this.isValidPeriod = this.isValidDate(filter.startDate, filter.endDate);

    if (!this.isValidPeriod) {
      this.isLoading = false;
      return;
    }

    filter.attendants = this.getAttendantsIdFromText(
      this.form.controls.attendants.value || []
    );

    filter.categories = this.getCategoriesIdFromName(
      this.form.controls.categories.value || []
    );

    filter.clients = this.getClientsIdFromName(
      this.form.controls.clients.value || []
    );

    filter.skills = this.getSkillIdFromText(
      this.form.controls.skills.value || []
    );

    filter.cells = this.form.controls.cells.value || [];

    filter.subcells = this.form.controls.subcells.value || [];

    filter.shifts = this.getFilteredShiftsValue(
      this.form.controls.shifts.value
    );

    var types: number[] | null;

    if (
      !this.form.controls.types ||
      !this.form.controls.types.value ||
      (this.form.controls.types.value.length == 1 &&
        this.form.controls.types.value[0].trim() == '')
    )
      types = null;
    else {
      types =
        this.form.controls.types.value?.map((i) => {
          return this.types.indexOf(i);
        }) ?? null;
    }

    filter.types = types;

    if (
      this.form.controls.supervisors.value &&
      this.form.controls.supervisors.value.length > 0 &&
      this.selectedAttendants &&
      this.selectedAttendants.length <= 0
    ) {
      this.form.get('attendants')?.updateValueAndValidity();
      this.form.patchValue({
        attendants: this.attendants,
      });

      filter.attendants = [];
      filter.attendants = this.getAttendantsIdFromText(this.attendants);
    }

    this.analyticalReportService
      .generateReport(filter)
      .pipe(finalize(() => (this.isLoading = false)))
      .subscribe({
        next: (data) => {
          if (data.status === 204) {
            this.isLoading = false;
            this.error = true;
            this.titleModalResponse =
              'Não há resultados para gerar o relatório';
            this.iconButtonModalResponse = 'assets/icons/error.svg';
            this.showModalResponse = true;
          } else {
            this.handleReportDownloadSuccess(data.body);
          }
        },
        error: () => {
          this.error = true;
          this.showModalResponse = true;
          this.titleModalResponse = 'Não foi possível gerar o relatório.';
          this.iconButtonModalResponse = 'assets/icons/error.svg';
          this.message = 'o relatório não possui dados o suficiente.';
        },
      });
  }

  getSupervisorsIdFromText(text: string[]): string[] {
    let supervidorIds: string[] = [];

    text.forEach((i) => {
      var [name, functionalString] = i.split('|').map((part) => part.trim());
      var supervisor = this.originalSupervisors.find(
        (j) =>
          j.functional.toString() === functionalString &&
          j.name.trim() === name.trim()
      );

      if (supervisor) supervidorIds.push(supervisor.id);
    });

    return supervidorIds;
  }

  getAttendantsIdFromText(text: string[]): string[] {
    let attendantIds: string[] = [];

    text.forEach((i) => {
      var [name, functionalString] = i.split('|').map((part) => part.trim());

      var attendant = this.originalAttendants.find(
        (j) =>
          j.functional.toString().trim() == functionalString.trim() &&
          j.name.trim() == name.trim()
      );

      if (attendant) attendantIds.push(attendant.id);
    });

    return attendantIds;
  }

  getCategoriesIdFromName(categoryNames: string[]): string[] {
    let categoryIds: string[] = [];

    categoryNames.forEach((categoryName) => {
      let categoryId = this.getCategoryIdFromName(categoryName);
      if (categoryId) categoryIds.push(categoryId);
    });

    return categoryIds;
  }

  getCategoryIdFromName(text: string): string | null {
    if (!text || text.trim() === '') return null;

    var category = this.originalCategories.find((c) => c.name === text);

    return category ? category.id : null;
  }

  getClientsIdFromName(clientNames: string[]): string[] {
    let clientIds: string[] = [];

    clientNames.forEach((i) => {
      let clientId = this.getClientIdFromName(i);
      if (clientId) clientIds.push(clientId);
    });

    return clientIds;
  }

  getClientIdFromName(name: string): string | null {
    if (!name || name.trim() === '') return null;

    var client = this.originalClients.find(
      (i) => i.name.trim() === name.trim()
    );

    return client ? client.id : null;
  }

  getSkillsPlaceholder(): string {
    const clients = this.form.controls.clients.value;
    if (clients && clients.length > 0 && clients[0] !== '') {
      return this.skills.length > 0
        ? 'Selecione múltiplas skills'
        : 'Nenhuma skill disponível';
    }
    return 'Selecione múltiplas skills';
  }

  getSkillIdFromText(text: string[]): string[] | null {
    if (!text || text.length === 0) return null;

    var skills: string[] = [];

    for (let i = 0; i < text.length; i++) {
      if (!text[i] || text[i].trim() === '') return null;

      var [code, cellAndSubCell] = text[i].split(':').map((i) => i.trim());
      var [cell, subCell] = cellAndSubCell.split('—').map((i) => i.trim());

      var skillFiltered = this.originalSkills.find(
        (i) =>
          i.code.toString() === code &&
          i.cell.trim() === cell &&
          i.subCell.trim() === subCell
      );

      if (!skillFiltered || skillFiltered == undefined) {
        var clients = this.form.controls.clients;

        if (
          clients &&
          clients.value &&
          clients.value.length > 0 &&
          clients.value[0] != ''
        ) {
          const filteredClients = this.originalClients.filter((client) =>
            clients.value?.includes(client.name)
          );

          skillFiltered = filteredClients
            .flatMap((client) => client.skills)
            .find(
              (skill) =>
                skill.code.toString() === code &&
                skill.cell.trim() === cell &&
                skill.subCell.trim() === subCell
            );

          if (!skillFiltered) return null;
        }
      }

      if (skillFiltered) skills.push(skillFiltered.id);
    }

    return skills;
  }

  handleReportDownloadSuccess(reportFile: Blob | null) {
    const fileAnchor = document.createElement('a');
    const data = new Date();
    fileAnchor.download = `RelatorioAnalitico-${data
      .getDate()
      .toString()
      .padStart(2, '0')}_${(data.getMonth() + 1)
      .toString()
      .padStart(
        2,
        '0'
      )}_${data.getFullYear()}_${data.getHours()}_${data.getMinutes()}.xlsx`;

    fileAnchor.href = window.URL.createObjectURL(reportFile as Blob);
    fileAnchor.click();
    fileAnchor.hidden = true;
    window.URL.revokeObjectURL(fileAnchor.href);
  }

  isFormComplete() {
    return (
      this.form.controls.initialDate.valid && this.form.controls.finalDate.valid
    );
  }

  selectedCells: string[] = [];
  selectedSubcells: string[] = [];

  clear() {
    this.form.reset();
    this.selectedCategories = [];
    this.selectedSupervisors = [];
    this.selectedAttendants = [];
    this.selectedClients = [];
    this.selectedSkills = [];
    this.selectedCells = [];
    this.selectedSubcells = [];
    this.selectedShifts = [];
    this.selectedTypes = [];
  }

  handleCloseModalResponse() {
    if (this.error == false) this.handleCloseModal();

    this.showModalResponse = false;
  }

  handleCloseModal() {
    this.form.reset();
    this.router.navigate(['/analytical-report']);
  }

  resetPeriod() {
    this.isValidPeriod = this.isValidDate(
      this.form.controls.initialDate.value ?? '',
      this.form.controls.finalDate.value ?? ''
    );
  }

  isValidDate(startDate: string, endDate: string) {
    return startDate != '' && endDate != '';
  }

  getAttendantsPlaceholder(): string {
    var supervisors = this.form.controls.supervisors;
    if (
      supervisors &&
      supervisors.value &&
      supervisors.value.length > 0 &&
      supervisors.value[0] !== ''
    ) {
      return this.attendants.length > 0
        ? 'Selecione múltiplos atendentes'
        : 'Nenhum atendente disponível';
    }
    return 'Selecione múltiplos atendentes';
  }
}
