import { Component } from '@angular/core';

@Component({
  selector: 'app-category-analysis-report-list-header',
  templateUrl: './category-analysis-report-list-header.component.html',
  styleUrls: ['./category-analysis-report-list-header.component.scss']
})
export class CategoryAnalysisReportListHeaderComponent {

}
