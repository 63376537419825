import { Component } from '@angular/core';
import { CategoryAnalysisReportGetFilter } from './service/models/category-analysis-report.service.model';

@Component({
  selector: 'app-category-analysis-report',
  templateUrl: './category-analysis-report.component.html',
  styleUrls: ['./category-analysis-report.component.scss'],
})
export class CategoryAnalysisReportComponent {
  filter!: CategoryAnalysisReportGetFilter;

  setFilter(filter: CategoryAnalysisReportGetFilter) {
    this.filter = filter;
  }
}
