import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  GetSkillRequest,
  GetSkillResponse,
} from 'src/app/pages/client/models/client.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SkillService {
  private baseURL: string = environment.apiUrl + '/api/Skill';

  constructor(private http: HttpClient) {}

  private serializeQueryParams(obj: any): any {
    let queryBuilder = new HttpParams();

    Object.keys(obj).forEach((k) => {
      if (Array.isArray(obj[k])) {
        (obj[k] as []).forEach(
          (value) => (queryBuilder = queryBuilder.append(k, value))
        );
      } else if (obj[k]) {
        queryBuilder = queryBuilder.append(k, obj[k]);
      }
    });

    return queryBuilder;
  }

  get(filter: GetSkillRequest): Observable<GetSkillResponse[]> {
    const params = this.serializeQueryParams(filter);
    return this.http.get<GetSkillResponse[]>(this.baseURL, { params });
  }

  getCells(): Observable<string[]> {
    return this.http.get<string[]>(this.baseURL + '/cells');
  }

  getSubCells(): Observable<string[]> {
    return this.http.get<string[]>(this.baseURL + '/subcells');
  }
}
