import { HttpParams } from '@angular/common/http';

export function serializeQueryParams(obj: any): any {
  let queryBuilder = new HttpParams();

  Object.keys(obj).forEach((k) => {
    if (Array.isArray(obj[k])) {
      (obj[k] as []).forEach(
        (value) => (queryBuilder = queryBuilder.append(k, value))
      );
    } else if (obj[k]) {
      queryBuilder = queryBuilder.append(k, obj[k]);
    }
  });

  return queryBuilder;
}
