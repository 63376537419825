import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  CreateCategoryRequest,
  DuplicateCategoryRequest,
  GetCategoriesFilter,
  GetCategoriesResponse,
  PagedGetCategoriesResponse,
  UpdateCategoryRequest,
} from './models/category.service.model';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  private baseURL: string = `${environment.apiUrl}/api/Category`;

  constructor(private http: HttpClient) {}

  private serializeQueryParams(obj: any): any {
    let queryBuilder = new HttpParams();

    Object.keys(obj).forEach((k) => {
      if (Array.isArray(obj[k])) {
        (obj[k] as []).forEach(
          (value) => (queryBuilder = queryBuilder.append(k, value))
        );
      } else if (obj[k]) {
        queryBuilder = queryBuilder.append(k, obj[k]);
      }
    });

    return queryBuilder;
  }

  create(request: CreateCategoryRequest) {
    return this.http.post(this.baseURL, request);
  }

  duplicate(id: string, request: DuplicateCategoryRequest) {
    return this.http.post(this.baseURL + '/duplicate/' + id, request);
  }

  get(filter: GetCategoriesFilter): Observable<PagedGetCategoriesResponse> {
    const params = this.serializeQueryParams(filter);
    return this.http.get<PagedGetCategoriesResponse>(this.baseURL, { params });
  }

  getAll(): Observable<GetCategoriesResponse[]> {
    return this.http.get<GetCategoriesResponse[]>(this.baseURL + '/all');
  }

  getById(id: string): Observable<GetCategoriesResponse> {
    return this.http.get<GetCategoriesResponse>(this.baseURL + '/' + id);
  }

  update(id: string, request: UpdateCategoryRequest) {
    return this.http.put(this.baseURL + '/' + id, request);
  }

  delete(id: string) {
    return this.http.delete(this.baseURL + '/' + id);
  }
}
