<div class="category-item">
    <div class="category-item__column">
      <strong>{{ data.createdBy || "-" }}</strong>
    </div>
    <div class="category-item__column">
      <strong>{{ data.startDate| date : "dd/MM/yyyy" || "-"}} - {{data.endDate| date : "dd/MM/yyyy" || "-"}}</strong>
    </div>
    <div class="incident-item__column actions">
      <app-icon-button
        [icon]="'../../../../../assets/icons/' + downloadIcon + '.svg'"
        [color]="ButtonColorEnum.primary" 
        [loading]="downloading"
        [enabled]="!downloading"
        (clickFunc)="handleDownloadReport(data.id)"
      ></app-icon-button>
    </div>
  </div>
  