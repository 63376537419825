<section>
  <app-dashboard-filter
    class="justify-content-center"
    (filterEmmiter)="setFilter($event)"
  ></app-dashboard-filter>

  <div class="big-numbers-container">
    <div class="big-numbers">
      <app-dashboard-big-number
        [title]="'Ligações Analisadas'"
        [content]="totalTranscriptionsByPeriod"
        [loading]="loadingTranscriptionsByPeriod"
        [maxWidth]="'131px'"
      ></app-dashboard-big-number>

      <app-dashboard-big-number
        [title]="'Ligações < 500 seg'"
        [content]="tmaLowerThen500s"
        [loading]="loadingBigNumbers"
        [maxWidth]="'190px'"
      ></app-dashboard-big-number>

      <app-dashboard-big-number
        [title]="'% Non Talk'"
        [content]="
          averageNonTalkPercentage !== undefined &&
          !isNaN(averageNonTalkPercentage)
            ? averageNonTalkPercentage + '%'
            : undefined
        "
        [loading]="loadingDailyNonTalk"
        [maxWidth]="'122px'"
      ></app-dashboard-big-number>

      <app-dashboard-big-number
        [title]="'Média TMA em seg'"
        [content]="tmaInSeconds"
        [loading]="loadingTmaXNonTalkBySegment"
        [maxWidth]="'146px'"
      ></app-dashboard-big-number>

      <app-dashboard-big-number
        [title]="'Média TMA'"
        [content]="averageTma"
        [loading]="loadingBigNumbers"
        [maxWidth]="'152px'"
      ></app-dashboard-big-number>

      <app-dashboard-big-number
        [title]="'Short Call'"
        [content]="shortCalls"
        [loading]="loadingBigNumbers"
        [maxWidth]="'152px'"
      ></app-dashboard-big-number>

      <app-dashboard-big-number
        [title]="'Categorias Mencionadas'"
        [content]="totalMentionedCategories"
        [loading]="loadingMentionedCategories"
        [maxWidth]="'187px'"
      ></app-dashboard-big-number>

      <app-dashboard-big-number
        [title]="'Palavras Mencionadas'"
        [content]="totalMentionedKeywords"
        [loading]="loadingMentionedKeywords"
        [maxWidth]="'169px'"
      ></app-dashboard-big-number>
    </div>

    <app-dashboard-shortcalls-total
      [shortCalls]="shortCalls"
      [otherTranscriptions]="totalTranscriptionsByPeriod - shortCalls"
      [loading]="loadingTranscriptionsByPeriod || loadingBigNumbers"
    ></app-dashboard-shortcalls-total>
  </div>

  <div class="resolution">
    <app-dashboard-resolution-insights
      class="justify-content-center"
      [filter]="filter"
    ></app-dashboard-resolution-insights>

    <app-dashboard-resolution-chart
      [filter]="filter"
    ></app-dashboard-resolution-chart>
  </div>

  <app-dashboard-tabs (emmiter)="selectTab($event)">
    <app-dashboard-tab-item [title]="'1'" #tab1.1>
      <app-dashboard-transcriptions-client
        #chart1.1
        class="justify-content-center"
        [filter]="filter"
        (totalTranscriptions)="setTotalTranscriptionsByPeriod($event)"
        (isLoadingEmmiter)="setLoadingTranscriptionsByPeriod($event)"
      ></app-dashboard-transcriptions-client>
    </app-dashboard-tab-item>

    <app-dashboard-tab-item [title]="'2'" #tab1.2>
      <app-dashboard-mentioned-categories-ranking
        #chart1.2
        class="justify-content-center"
        [filter]="filter"
        (totalMentions)="setTotalMentionedCategories($event)"
        (isLoadingEmmiter)="setLoadingMentionedCategories($event)"
      ></app-dashboard-mentioned-categories-ranking>
    </app-dashboard-tab-item>

    <app-dashboard-tab-item [title]="'3'" #tab1.3>
      <app-dashboard-mentioned-keywords-ranking
        #chart1.3
        class="justify-content-center"
        [filter]="filter"
        (totalMentions)="setTotalMentionedKeywords($event)"
        (isLoadingEmmiter)="setLoadingMentionedKeywords($event)"
      ></app-dashboard-mentioned-keywords-ranking>
    </app-dashboard-tab-item>
  </app-dashboard-tabs>

  <app-dashboard-tabs (emmiter)="selectTab($event)" [tabText]="true">
    <app-dashboard-tab-item [title]="'Macro'" #tab4.1>
      <app-dashboard-mentioned-macro-contact-reasons-ranking
        #chart4.1
        class="justify-content-center"
        [filter]="filter"
        (isLoadingEmmiter)="setLoadingTranscriptionsByPeriod($event)"
      ></app-dashboard-mentioned-macro-contact-reasons-ranking>
    </app-dashboard-tab-item>

    <app-dashboard-tab-item [title]="'Micro'" #tab4.2>
      <app-dashboard-mentioned-micro-contact-reasons-ranking
        #chart4.2
        class="justify-content-center"
        [filter]="filter"
        (isLoadingEmmiter)="setLoadingTranscriptionsByPeriod($event)"
      ></app-dashboard-mentioned-micro-contact-reasons-ranking>
    </app-dashboard-tab-item>
  </app-dashboard-tabs>

  <app-dashboard-tabs (emmiter)="selectTab($event)">
    <app-dashboard-tab-item [title]="'1'" #tab2.1>
      <app-dashboard-tma-x-non-talk-by-segment
        #chart2.1
        class="justify-content-center"
        [filter]="filter"
        (tmaInSeconds)="setTMAInSeconds($event)"
        (isLoadingEmmiter)="setLoadingTmaXNonTalkBySegment($event)"
      ></app-dashboard-tma-x-non-talk-by-segment>
    </app-dashboard-tab-item>

    <app-dashboard-tab-item [title]="'2'" #tab2.2>
      <app-dashboard-daily-tma
        #chart2.2
        class="justify-content-center"
        [filter]="filter"
        (isLoadingEmmiter)="setLoadingDailyTMA($event)"
      >
      </app-dashboard-daily-tma>
    </app-dashboard-tab-item>

    <app-dashboard-tab-item [title]="'3'" #tab2.3>
      <app-dashboard-daily-non-talk
        #chart2.3
        class="justify-content-center"
        [filter]="filter"
        (averageNonTalkPercentage)="setAverageNonTalkPercentage($event)"
        (isLoadingEmmiter)="setLoadingDailyNonTalk($event)"
      >
      </app-dashboard-daily-non-talk>
    </app-dashboard-tab-item>
  </app-dashboard-tabs>

  <app-dashboard-tabs (emmiter)="selectTab($event)">
    <app-dashboard-tab-item [title]="'1'" #tab3.1>
      <app-dashboard-shifts
        #chart3.1
        class="justify-content-center"
        [filter]="filter"
        (isLoadingEmmiter)="setLoadingShifts($event)"
      ></app-dashboard-shifts>
    </app-dashboard-tab-item>
  </app-dashboard-tabs>
</section>
