import { Component, ViewChild } from '@angular/core';
import { DashboardDailyNonTalkComponent } from './dashboard-daily-non-talk/dashboard-daily-non-talk.component';
import { DashboardDailyTMAComponent } from './dashboard-daily-tma/dashboard-daily-tma.component';
import { DashboardMentionedCategoriesRankingComponent } from './dashboard-mentioned-categories-ranking/dashboard-mentioned-categories-ranking.component';
import { DashboardMentionedKeywordsRankingComponent } from './dashboard-mentioned-keywords-ranking/dashboard-mentioned-keywords-ranking.component';
import { DashboardTabItemComponent } from './dashboard-tabs/dashboard-tab-item/dashboard-tab-item.component';
import { DashboardTmaXNonTalkBySegmentComponent } from './dashboard-tma-x-non-talk-by-segment/dashboard-tma-x-non-talk-by-segment.component';
import { DashboardTranscriptionsClientComponent } from './dashboard-transcriptions-client/dashboard-transcriptions-client.component';
import { DashboardFilterRequest } from './service/models/dashboard.service.model';
import { DashboardService } from './service/dashboard.service';
import { finalize } from 'rxjs';
import { DashboardShiftsComponent } from './dashboard-shifts/dashboard-shifts.component';
import { DashboardMentionedMacroContactReasonsRankingComponent } from './dashboard-mentioned-macro-contact-reasons-ranking/dashboard-mentioned-macro-contact-reasons-ranking.component';
import { DashboardMentionedMicroContactReasonsRankingComponent } from './dashboard-mentioned-micro-contact-reasons-ranking/dashboard-mentioned-micro-contact-reasons-ranking.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {
  constructor(public dashboardService: DashboardService) {}

  @ViewChild('tab1.1') tab_1_1!: DashboardTabItemComponent;
  @ViewChild('tab1.2') tab_1_2!: DashboardTabItemComponent;
  @ViewChild('tab1.3') tab_1_3!: DashboardTabItemComponent;
  @ViewChild('tab2.1') tab_2_1!: DashboardTabItemComponent;
  @ViewChild('tab2.2') tab_2_2!: DashboardTabItemComponent;
  @ViewChild('tab2.3') tab_2_3!: DashboardTabItemComponent;
  @ViewChild('tab3.1') tab_3_1!: DashboardTabItemComponent;
  @ViewChild('tab4.1') tab_4_1!: DashboardTabItemComponent;
  @ViewChild('tab4.2') tab_4_2!: DashboardTabItemComponent;

  @ViewChild('chart1.1') chart_1_1!: DashboardTranscriptionsClientComponent;
  @ViewChild('chart1.2') chart_1_2!: DashboardMentionedCategoriesRankingComponent;
  @ViewChild('chart1.3') chart_1_3!: DashboardMentionedKeywordsRankingComponent;
  @ViewChild('chart2.1') chart_2_1!: DashboardTmaXNonTalkBySegmentComponent;
  @ViewChild('chart2.2') chart_2_2!: DashboardDailyTMAComponent;
  @ViewChild('chart2.3') chart_2_3!: DashboardDailyNonTalkComponent;
  @ViewChild('chart3.1') chart_3_1!: DashboardShiftsComponent;
  @ViewChild('chart4.1') chart_4_1!: DashboardMentionedMacroContactReasonsRankingComponent;
  @ViewChild('chart4.2') chart_4_2!: DashboardMentionedMicroContactReasonsRankingComponent;

  filter!: DashboardFilterRequest;

  totalTranscriptionsByPeriod!: number;
  totalMentionedCategories!: number;
  totalMentionedKeywords!: number;
  averageNonTalkPercentage!: number;
  tmaInSeconds!: number;
  tmaLowerThen500s!: string;
  averageTma!: string;
  shortCalls!: number;

  loadingTranscriptionsByPeriod!: boolean;
  loadingMentionedCategories!: boolean;
  loadingMentionedKeywords!: boolean;
  loadingTmaXNonTalkBySegment!: boolean;
  loadingDailyTMA!: boolean;
  loadingDailyNonTalk!: boolean;
  loadingShifts!: boolean;
  loadingBigNumbers!: boolean;

  ngOnInit() {
    this.getBigNumbers();
  }

  getBigNumbers() {
    this.loadingBigNumbers = true;

    setTimeout(() => {
      this.dashboardService
        .getBigNumbers(this.filter)
        .pipe(finalize(() => (this.loadingBigNumbers = false)))
        .subscribe({
          next: (resp) => {
            this.tmaLowerThen500s = resp.tmaLowerThen500sPercentage;
            this.averageTma = resp.averageTma;
            this.tmaInSeconds = this.getSecondsFromHHMMSS(this.averageTma);
            this.shortCalls = resp.shortCalls;
          },
        });
    }, 0);
  }

  getSecondsFromHHMMSS(time: string): number {
    const [hours, minutes, seconds] = time.split(':').map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  }

  selectTab(tab: DashboardTabItemComponent) {
    switch (tab) {
      case this.tab_1_1:
        this.chart_1_1.createChart();
        break;
      case this.tab_1_2:
        this.chart_1_2.createChart();
        break;
      case this.tab_1_3:
        this.chart_1_3.createChart();
        break;
      case this.tab_2_1:
        this.chart_2_1.createChart();
        break;
      case this.tab_2_2:
        this.chart_2_2.createChart();
        break;
      case this.tab_2_3:
        this.chart_2_3.createChart();
        break;
      case this.tab_3_1:
        this.chart_3_1.createChart();
        break;
      case this.tab_4_1:
        this.chart_4_1.createChart();
        break;
      case this.tab_4_2:
        this.chart_4_2.createChart();
        break;
    }
  }

  setFilter(filter: DashboardFilterRequest) {
    this.filter = filter;
    this.getBigNumbers()
  }

  setTotalTranscriptionsByPeriod(totalTranscriptionsByPeriod: number) {
    this.totalTranscriptionsByPeriod = totalTranscriptionsByPeriod;
  }

  setTotalMentionedCategories(totalMentionedCategories: number) {
    this.totalMentionedCategories = totalMentionedCategories;
  }

  setTotalMentionedKeywords(totalMentionedKeywords: number) {
    this.totalMentionedKeywords = totalMentionedKeywords;
  }

  setAverageNonTalkPercentage(averageNonTalkPercentage: number) {
    this.averageNonTalkPercentage = Number(averageNonTalkPercentage.toFixed(2));
  }

  setTMAInSeconds(tmaInSeconds: number) {
    this.tmaInSeconds = tmaInSeconds;
  }

  setLoadingTranscriptionsByPeriod(loadingTranscriptionsByPeriod: boolean) {
    this.loadingTranscriptionsByPeriod = loadingTranscriptionsByPeriod;
  }

  setLoadingMentionedCategories(loadingMentionedCategories: boolean) {
    this.loadingMentionedCategories = loadingMentionedCategories;
  }

  setLoadingMentionedKeywords(loadingMentionedKeywords: boolean) {
    this.loadingMentionedKeywords = loadingMentionedKeywords;
  }

  setLoadingTmaXNonTalkBySegment(loadingTmaXNonTalkBySegment: boolean) {
    this.loadingTmaXNonTalkBySegment = loadingTmaXNonTalkBySegment;
  }

  setLoadingDailyTMA(loadingDailyTMA: boolean) {
    this.loadingDailyTMA = loadingDailyTMA;
  }

  setLoadingDailyNonTalk(loadingDailyNonTalk: boolean) {
    this.loadingDailyNonTalk = loadingDailyNonTalk;
  }

  setLoadingShifts(loadingShifts: boolean) {
    this.loadingShifts = loadingShifts;
  }

  isNaN(value: number) {
    return isNaN(value);
  }
}
