<article>
  <header (click)="handleToggleAccordion()">
    <strong>Filtro</strong>
    <img
      class="accordion-img"
      [ngClass]="{ 'rotate-animation': !isOpen }"
      src="assets/icons/arrow.svg"
    />
  </header>
  <main [ngClass]="{ 'is-collapsed': !isOpen }">
    <div main class="main">
      <div class="grid-content">
        <div class="period">
          <div class="period-input">
            <app-input
              label="Período"
              placeholder="De"
              [control]="form.controls.initialDate"
              [disabled]="false"
              [type]="InputTypeEnum.date"
            ></app-input>
            <app-input
              label=""
              placeholder="Até"
              [control]="form.controls.finalDate"
              [disabled]="false"
              [type]="InputTypeEnum.date"
            ></app-input>
          </div>
        </div>
        <app-select-multi
          label="Clientes"
          placeholder="Selecione múltiplos clientes"
          [multiControl]="form.controls.clients"
          [options]="clientOptions"
          [selected]="selectedClients"
          property="Type"
          [multi]="true"
          [disabled]="loadingClients"
          [loading]="loadingClients"
          [isSearch]="true"
        ></app-select-multi>

        <app-select-multi
          label="Skills"
          placeholder="Selecione múltiplas skills"
          [multiControl]="form.controls.skills"
          [options]="skills"
          [selected]="selectedSkills"
          property="Type"
          [multi]="true"
          [disabled]="loadingSkills"
          [loading]="loadingSkills"
          [isSearch]="true"
        ></app-select-multi>

        <app-select-multi
          label="Células"
          placeholder="Selecione múltiplas células"
          [multiControl]="form.controls.cells"
          [options]="cells"
          [selected]="selectedCells"
          property="Type"
          [multi]="true"
          [disabled]="loadingCells"
          [loading]="loadingCells"
          [isSearch]="true"
        ></app-select-multi>

        <app-select-multi
          label="Sub-células"
          placeholder="Selecione múltiplas sub-células"
          [multiControl]="form.controls.subCells"
          [options]="subCells"
          [selected]="selectedSubCells"
          property="Type"
          [multi]="true"
          [loading]="loadingSubcells"
          [disabled]="loadingSubcells"
          [isSearch]="true"
        ></app-select-multi>

        <app-select-multi
          label="Supervisor"
          placeholder="Selecione um supervisor"
          [singleControl]="form.controls.supervisor"
          [options]="supervisors"
          [selected]="selectedSupervisors"
          property="Type"
          [multi]="false"
          [loading]="loadingSupervisors"
          [disabled]="loadingSupervisors"
          [isSearch]="true"
        ></app-select-multi>

        <app-select-multi
          label="Atendentes"
          placeholder="Selecione múltiplos atendentes"
          [multiControl]="form.controls.attendants"
          [options]="attendants"
          [selected]="selectedAttendants"
          property="Type"
          [multi]="true"
          [loading]="loadingAttendants"
          [disabled]="loadingAttendants"
          [isSearch]="true"
        ></app-select-multi>

        <app-select-multi
          label="Turnos"
          placeholder="Selecione múltiplos turnos"
          [multiControl]="form.controls.shifts"
          [options]="shifts"
          [selected]="selectedShifts"
          property="Type"
          [disabled]="loadingShifts"
          [multi]="true"
          [loading]="loadingShifts"
        ></app-select-multi>

        <app-select-multi
          label="Tipos"
          placeholder="Selecione múltiplos tipos"
          [multiControl]="form.controls.types"
          [options]="types"
          [selected]="selectedTypes"
          property="Type"
          [disabled]="false"
          [multi]="true"
        ></app-select-multi>

        <app-select-multi
          label="Motivo de Contato (Macro)"
          placeholder="Selecione múltiplas opções"
          [multiControl]="form.controls.macroContactReason"
          [options]="macroContactReasonsOptions"
          [selected]="selectedMacroContactReason"
          property="Type"
          [disabled]="loadingContactReasons"
          [multi]="true"
          [isSearch]="true"
          [loading]="loadingContactReasons"
        ></app-select-multi>

        <app-select-multi
          label="Motivo de Contato (Micro)"
          placeholder="Selecione múltiplas opções"
          [multiControl]="form.controls.microContactReason"
          [options]="microContactReasonsOptions"
          [selected]="selectedMicroContactReason"
          property="Type"
          [disabled]="loadingContactReasons"
          [multi]="true"
          [isSearch]="true"
          [loading]="loadingContactReasons"
        ></app-select-multi>
      </div>
    </div>
    <hr />
    <div footer class="footer">
      <app-secondary-button
        label="Limpar"
        [color]="ButtonColorEnum.warning"
        [icon]="'assets/icons/trash.svg'"
        [enabled]="true"
        [widthFull]="true"
        (clickFunc)="clear()"
      >
      </app-secondary-button>
      <app-primary-button
        [label]="'Aplicar Filtros'"
        [color]="ButtonColorEnum.common"
        [loading]="isLoading"
        [enabled]="true"
        (clickFunc)="filter()"
      ></app-primary-button>
    </div>
  </main>
</article>

<app-modal-response
  [showModal]="showModalResponse"
  [title]="titleModalResponse"
  (closeModalFunc)="handleCloseModalResponse()"
  [icon]="iconButtonModalResponse"
>
  <strong main> {{ message }} </strong>

  <app-secondary-button
    footer
    label="Voltar"
    [right]="false"
    [color]="ButtonColorEnum.primary"
    (clickFunc)="handleCloseModalResponse()"
  ></app-secondary-button>

  <app-primary-button
    footer
    *ngIf="!error"
    class="btn-ok"
    label="Ok"
    [right]="false"
    [color]="ButtonColorEnum.common"
    (clickFunc)="handleCloseModalResponse()"
  ></app-primary-button>
</app-modal-response>
