import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SkillService } from 'src/app/common/services/skill/skill.service';
import { StatusEnum } from 'src/app/common/utils/status-enum.models';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { InputTypeEnum } from 'src/app/shared/input/enums/input-type.enum';
import { MaskTypeEnum } from 'src/app/shared/input/enums/mask-type.enum';
import {
  GetClientsFilter,
  GetClientsResponse,
  GetSkillResponse,
} from '../../client/models/client.model';
import { ClientService } from '../../client/service/client.service';
import {
  GetUserResponse,
  GetUsersFilter,
} from '../../user/service/models/user.service.model';
import { UserService } from '../../user/service/user.service';
import { TranscriptionService } from '../service/transcription.service';

@Component({
  selector: 'app-transcription-details',
  templateUrl: './transcription-details.component.html',
  styleUrls: ['./transcription-details.component.scss'],
})
export class TranscriptionDetailsComponent implements OnInit {
  constructor(
    public formBuilder: FormBuilder,
    public router: Router,
    public transcriptionService: TranscriptionService,
    public userService: UserService,
    public clientService: ClientService,
    public skillService: SkillService,
    private route: ActivatedRoute
  ) {}

  transcriptionId: string = '';
  isLoading: boolean = false;

  attendantOptions: string[] = [];
  selectedAttendant: string[] = [];
  selectedAttendantId: string = '';
  attendantDisabled: boolean = true;
  attendantPlaceHolder: string = 'Carregando...';
  originalAttendants: GetUserResponse[] = [];
  loadingAttendants: boolean = true;

  clientOptions: string[] = [];
  selectedClient: string[] = [];
  selectedClientId: string = '';
  clientDisabled: boolean = true;
  clientPlaceHolder: string = 'Carregando...';
  originalClients: GetClientsResponse[] = [];
  loadingClients: boolean = true;

  skillOptions: string[] = [];
  selectedSkill: string[] = [];
  selectedSkillId: string = '';
  skillDisabled: boolean = true;
  skillPlaceHolder: string = 'Carregando...';
  originalSkills: GetSkillResponse[] = [];
  skillErrorMessage: string = 'campo obrigatório';

  form = this.formBuilder.group({
    attendantId: ['', Validators.required],
    clientId: ['', Validators.required],
    skillId: ['', Validators.required],
  });

  error: boolean = false;

  showModalResponse: boolean = false;
  titleModalResponse: string = '';
  iconButtonModalResponse: string = '';
  corporateEmailIsChecked: boolean = false;
  message: string = '';

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      const id = params['id'];

      if (id) this.transcriptionId = id;
    });

    this.populateIncident();
  }

  ButtonColorEnum = ButtonColorEnum;
  InputTypeEnum = InputTypeEnum;
  MaskTypeEnum = MaskTypeEnum;

  handleNavigateToList() {
    this.router.navigate(['transcription']);
  }

  populateAttendants(incidentById: any) {
    this.attendantOptions = [];

    if (incidentById.attendant) {
      this.selectedAttendant = [incidentById.attendant.name];
      this.selectedAttendantId = incidentById.attendant.id;
    } else {
      this.userService
        .get({
          profiles: ['ATENDENTE'],
          status: [StatusEnum.ATIVO],
        } as GetUsersFilter)
        .subscribe({
          next: (data) => {
            this.originalAttendants = data.items;
            this.attendantOptions = data.items.map(
              (attendant) => attendant.functional + ' | ' + attendant.name
            );
            this.attendantDisabled = false;
            this.attendantPlaceHolder = 'Selecione um atendente';
            this.loadingAttendants = false;
          },
          error: (e) => {
            this.loadingAttendants = false;
            this.error = true;
            console.error(e);

            this.titleModalResponse = 'Ocorreu um erro ao carregar atendentes';
            this.iconButtonModalResponse = 'assets/icons/error.svg';
            this.message = 'tente novamente mais tarde.';
            this.showModalResponse = true;
          },
        });
    }
  }

  populateClients(incidentById: any) {
    this.clientOptions = [];

    this.clientService
      .get({ status: [StatusEnum.ATIVO] } as GetClientsFilter)
      .subscribe({
        next: (data) => {
          this.originalClients = data.items;
          this.clientOptions = data.items.map((i) => i.cnpj + ' | ' + i.name);

          if (incidentById.client) {
            var client = this.originalClients.find(
              (i) => i.id === incidentById.client.id
            );
            if (client) {
              this.selectedClient = [client.cnpj + ' | ' + client.name];
              this.selectedClientId = client.id;
            } else {
              this.clientDisabled = false;
              this.clientPlaceHolder = 'Selecione um cliente';
            }

            if (incidentById.skill) {
              this.selectedSkill = [
                incidentById.skill.code +
                  ': ' +
                  incidentById.skill.cell +
                  ' — ' +
                  incidentById.skill.subCell,
              ];
              this.selectedSkillId = incidentById.skill.id;
            } else {
              this.skillDisabled = false;
              this.skillPlaceHolder = 'Selecione uma skill';
              this.skillOptions =
                this.originalClients
                  .find((i) => i.id === this.selectedClientId)
                  ?.skills.map(
                    (i) => i.code + ': ' + i.cell + ' — ' + i.subCell
                  ) ?? [];
            }
          } else {
            this.clientDisabled = false;
            this.clientPlaceHolder = 'Selecione um cliente';
            this.skillDisabled = false;
            this.skillPlaceHolder = 'Selecione uma skill';
            this.skillErrorMessage = 'requer cliente selecionado';
            this.form.controls.skillId.reset();
          }

          this.loadingClients = false;
        },
        error: (e) => {
          this.loadingClients = false;
          this.error = true;
          console.error(e);

          this.titleModalResponse = 'Ocorreu um erro ao carregar clientes';
          this.iconButtonModalResponse = 'assets/icons/error.svg';
          this.message = 'tente novamente mais tarde.';
          this.showModalResponse = true;
        },
      });
  }

  populateIncident() {
    this.transcriptionService.getById(this.transcriptionId).subscribe({
      next: (data) => {
        this.populateAttendants(data);
        this.populateClients(data);
      },
      error: (e) => {
        this.error = true;
        console.error(e);

        this.titleModalResponse = 'Ocorreu um erro ao carregar incidente';
        this.iconButtonModalResponse = 'assets/icons/error.svg';
        this.message = 'tente novamente mais tarde.';
        this.showModalResponse = true;
      },
    });
  }

  getClientIdFromText(text: string): string | null {
    if (!text || text.trim() === '') return null;

    var [cnpj, name] = text.split('|').map((part) => part.trim());
    var client = this.originalClients.find((i) => i.cnpj === cnpj);

    return client ? client.id : null;
  }

  getSkillIdFromText(text: string): string | null {
    if (!text || text.trim() === '') return null;

    var [code, cellAndSubCell] = text.split(':').map((part) => part.trim());
    var [cell, subCell] = cellAndSubCell.split('—').map((part) => part.trim());
    var skills = this.originalClients.map((i) => i.skills).flat();

    var skill = skills.find(
      (i) =>
        i.code.toString() === code &&
        i.cell.trim() === cell &&
        i.subCell.trim() === subCell
    );
    return skill ? skill.id : null;
  }

  getAttendantIdFromText(text: string): string | null {
    if (!text || text.trim() === '') return null;
    var [functional, name] = text.split('|').map((part) => part.trim());
    var attendant = this.originalAttendants.find(
      (i) => i.functional.toString() === functional
    );

    return attendant ? attendant.id : null;
  }

  handleAttendantStatusChanged(event: any) {
    var id = this.getAttendantIdFromText(event[0]);
    this.selectedAttendantId = id ?? '';
  }

  handleSkillStatusChanged(event: any) {
    var id = this.getSkillIdFromText(event[0]) ?? '';
    this.selectedSkillId = id;
  }

  handleClientStatusChanged(event: any) {
    var id = this.getClientIdFromText(event[0]) ?? '';
    if (event[0] != undefined) this.skillErrorMessage = 'campo obrigatório';
    else this.skillErrorMessage = 'requer cliente selecionado';
    this.selectedSkillId = '';
    this.selectedSkill = [];
    this.form.controls.skillId.reset();
    this.selectedClientId = id;
    this.skillOptions =
      this.originalClients
        .find((i) => i.id === id)
        ?.skills.map((i) => i.code + ': ' + i.cell + ' — ' + i.subCell) ?? [];
  }

  handleUpdateClient() {
    if (!this.selectedAttendantId || this.selectedAttendantId.trim() == '') {
      this.form.controls.attendantId.reset();
      return;
    }

    if (!this.selectedClientId || this.selectedClientId.trim() == '') {
      this.form.controls.clientId.reset();
      return;
    }

    if (!this.selectedSkillId || this.selectedSkillId.trim() == '') {
      this.form.controls.skillId.reset();
      return;
    }

    if (this.isLoading) return;

    this.isLoading = true;
    this.error = false;

    var request = {
      skillId: this.selectedSkillId,
      attendantId: this.selectedAttendantId,
      clientId: this.selectedClientId,
    } as any;

    this.transcriptionService.update(this.transcriptionId, request).subscribe({
      next: () => {
        this.isLoading = false;
        this.titleModalResponse = 'Alterações salvas com sucesso!';
        this.iconButtonModalResponse = 'assets/icons/success-primary.svg';
        this.message =
          'suas atualizações foram registradas com êxito no sistema.';
        this.showModalResponse = true;
        this.form.reset();
      },
      error: (e) => {
        this.isLoading = false;
        this.error = true;
        console.error(e);
        this.titleModalResponse = 'Ocorreu um erro ao editar cliente';
        this.iconButtonModalResponse = 'assets/icons/error.svg';
        this.message = e.error.Message ?? 'tente novamente mais tarde.';
        this.showModalResponse = true;
      },
    });
  }

  handleCloseModalResponse() {
    if (this.error == false) this.handleCloseModal();

    this.showModalResponse = false;
  }

  handleCloseModal() {
    this.form.reset();
    this.router.navigate(['/transcription']);
  }
}
