<div class="container">
  <button
    [ngClass]="{
      disabled: !enabled
    }"
    [ngClass]="{ 'no-filters-selected': count < 1 }"
    [disabled]="!enabled"
    (click)="handleButtonClick()"
  >
    <img src="assets/icons/filter.svg" />
    <span>Filtros</span>
    <div class="filter-count" [ngClass]="{ 'no-filters-selected': count < 1 }">
      {{ count }}
    </div>
  </button>

  <div
    id="overlay"
    [ngClass]="{
      hidden: !isOpen,
      'no-filters-selected': count < 1,
      'open-left': orientation === 'left',
      'open-middle': orientation === 'middle',
      'open-right': orientation === 'right'
    }"
  >
    <div class="selected-items" *ngIf="hasSelectedItems">
      <div class="selected-item" *ngIf="getValues().length != 0">
        {{ getValues()[0][1] }}
        <img
          class="selected-item-remove"
          src="assets/icons/close.svg"
          (click)="remove(getValues()[0])"
        />
      </div>
      <div class="selected-item" *ngIf="getValues().length >= 2">
        {{ getValues()[1][1] }}
        <img
          class="selected-item-remove"
          src="assets/icons/close.svg"
          (click)="remove(getValues()[1])"
        />
      </div>
      <div class="selected-item" *ngIf="getValues().length > 2">
        +{{ getValues().length - 2 }}
        <img
          class="selected-item-remove"
          src="assets/icons/close.svg"
          (click)="removeMultiple(getValues())"
        />
      </div>
    </div>
    <app-input
      class="internal-search"
      placeholder="O que você está buscando?"
      icon="assets/icons/search.svg"
      (valueChange)="getFilteredOptions($event)"
    ></app-input>
    <div class="sessao" *ngFor="let item of filteredItems">
      <div class="item-header" (click)="handleToggleAccordion(item)">
        <strong>{{ item.title }}</strong>
        <img
          class="accordion-img"
          [ngClass]="{ 'rotate-animation': item.isOpen }"
          src="assets/icons/arrow.svg"
        />
      </div>
      <hr class="line" />
      <div class="item-body" [ngClass]="{ 'is-collapsed': item.isOpen }">
        <div class="item" *ngFor="let i of item.items">
          <input
            class="checkbox"
            type="checkbox"
            id="{{ item.title + ':' + i }}"
            name="{{ item.title + ':' + i }}"
            (change)="onChange(item.title, i, $event)"
          />
          <label class="checkbox-label" for="{{ item.title + ':' + i }}">{{
            i
          }}</label>
        </div>
      </div>
    </div>
    <div class="buttons">
      <app-primary-button
        (click)="onSearch()"
        label="Buscar"
        [color]="buttonColorEnum.common"
        [widthFull]="true"
        [icon]="''"
        [enabled]="!loading"
        [loading]="loading"
      >
      </app-primary-button>

      <app-secondary-button
        (click)="refresh()"
        label="Limpar"
        [color]="buttonColorEnum.warning"
        [icon]="'assets/icons/trash.svg'"
        [enabled]="count > 0"
        [widthFull]="true"
      >
      </app-secondary-button>
    </div>
  </div>
</div>
