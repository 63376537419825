import { Component, Input } from '@angular/core';
import { InputTypeEnum } from '../enums/input-type.enum';
import { PasswordValidationModel } from '../model/password-validation.model';

@Component({
  selector: 'app-password-validation',
  templateUrl: './password-validation.component.html',
  styleUrls: ['./password-validation.component.scss']
})
export class PasswordValidationComponent {
  @Input() validator: PasswordValidationModel = new PasswordValidationModel();

  public eInputTypeEnum = InputTypeEnum;
}
