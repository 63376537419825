<article>
  <header>
    <strong>Resolvidos x Não Resolvidos</strong>
  </header>
  <main>
    <app-pie-chart
      [inlineLegend]="true"
      [inlineContainer]="true"
      [loading]="loading"
      [error]="error"
    ></app-pie-chart>
  </main>
</article>
