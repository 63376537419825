<div *ngIf="showModal" class="modal">
  <section>
    <header>
      <div class="header-title">
        <img src="{{ icon }}" alt="" />
        <strong>{{ title }}</strong>
      </div>
      <div class="actions">
        <ng-content select="[actions]"></ng-content>
        <app-icon-button class="gray-background"
          icon="../../../assets/icons/red_close.ico"
          [color]="ButtonColorEnum.common"
          (click)="handleCloseModal()"
        ></app-icon-button>
      </div>
    </header>
    <div class="extra">
      <ng-content select="[extra]"></ng-content>
    </div>
    <main *ngIf="includeMain">
      <ng-content select="[main]"></ng-content>
    </main>
    <footer>
      <ng-content select="[footer]"></ng-content>
    </footer>
  </section>
</div>
