import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { finalize } from 'rxjs';
import { UserRoleEnum } from 'src/app/common/services/auth/enums/user-role.enum';
import { StorageService } from 'src/app/common/services/storage/storage.service';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { ContactReasonService } from '../../service/contact-reason.service';
import { GetContactReasonResponse } from '../../service/models/contact-reason.service.model';

@Component({
  selector: 'app-contact-reason-list-item',
  templateUrl: './contact-reason-list-item.component.html',
  styleUrls: ['./contact-reason-list-item.component.scss'],
})
export class ContactReasonListItemComponent {
  constructor(
    private storageService: StorageService,
    private router: Router,
    private contactReasonService: ContactReasonService
  ) {}

  hasSupervisorRole: boolean = false;

  showModalResponseDelete: boolean = false;
  titleModalResponseDelete: string = '';
  iconButtonModalResponseDelete: string = '';
  messageModalResponseDelete: string = '';
  isSuccessModalResponseDelete: boolean = false;
  isConfirmQuestionDelete: boolean = false;
  idForDelete: string = '';
  isDeleting: boolean = false;
  activeOptions: string[] = ['Ativo', 'Inativo'];

  ButtonColorEnum = ButtonColorEnum;

  @Input() data: GetContactReasonResponse = {
    id: '',
    createdBy: '',
    createdOn: '',
    updatedBy: '',
    updatedOn: '',
    active: false,
    name: '',
    client: null,
    initialDate: '',
    finalDate: '',
    isDeleted: false,
    file: '',
  };

  ngOnInit(): void {
    const role = this.storageService.getRole();
    this.hasSupervisorRole = role === UserRoleEnum.supervisor;
  }

  getStatus(): string {
    if (this.data.isDeleted) return 'Excluído';
    return this.data.active ? 'Ativo' : 'Inativo';
  }

  handleDetails(id: string) {
    this.router.navigate(['/contact-reason/', id]);
  }

  handleShowModalDelete(id: string) {
    this.idForDelete = id;
    this.isConfirmQuestionDelete = true;
    this.showModalResponseDelete = true;
    this.titleModalResponseDelete =
      'Tem certeza que deseja excluir a importação do motivo de contato?';
    this.iconButtonModalResponseDelete = 'assets/icons/delete.svg';
    this.messageModalResponseDelete = 'essa ação não pode ser desfeita.';
  }

  handleCloseModalDelete() {
    this.showModalResponseDelete = false;
  }

  handleCloseModalDeleteAndReload() {
    this.showModalResponseDelete = false;
    window.location.reload();
  }

  deleteContactReason() {
    this.isDeleting = true;

    this.contactReasonService
      .delete(this.idForDelete)
      .pipe(
        finalize(() => {
          this.isConfirmQuestionDelete = false;
          this.isDeleting = false;
        })
      )
      .subscribe({
        next: () => {
          this.isSuccessModalResponseDelete = true;
          this.showModalResponseDelete = true;
          this.titleModalResponseDelete =
            'Importação do motivo de contato excluída com sucesso!';
          this.iconButtonModalResponseDelete =
            'assets/icons/success-primary.svg';
          this.messageModalResponseDelete =
            'o registro foi removido com êxito do sistema.';
          (')');
        },
        error: (e) => {
          console.error(e);
          this.isSuccessModalResponseDelete = false;
          this.showModalResponseDelete = true;
          this.titleModalResponseDelete =
            'Ocorreu um erro ao remover a importação do motivo de contato.';
          this.iconButtonModalResponseDelete = 'assets/icons/error.svg';
          this.messageModalResponseDelete =
            'não foi possível excluir o registro no momento, tente novamente mais tarde ou entre em contato com a administração.';
        },
      });
  }
}
