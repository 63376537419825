import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { BarChartComponent } from 'src/app/shared/bar-chart/bar-chart.component';
import {
  BarChartParams,
  DatasetBarChart,
} from 'src/app/shared/bar-chart/models/bar-chart.model';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { InputTypeEnum } from 'src/app/shared/input/enums/input-type.enum';
import { DashboardService } from '../service/dashboard.service';
import { DashboardFilterRequest } from '../service/models/dashboard.service.model';

@Component({
  selector: 'app-dashboard-transcriptions-client',
  templateUrl: './dashboard-transcriptions-client.component.html',
  styleUrls: ['./dashboard-transcriptions-client.component.scss'],
})
export class DashboardTranscriptionsClientComponent implements OnInit {
  constructor(
    public formBuilder: FormBuilder,
    public dashboardService: DashboardService
  ) {}

  @Input() filter!: DashboardFilterRequest;
  @Output() totalTranscriptions: EventEmitter<number> =
    new EventEmitter<number>();
  @Output() isLoadingEmmiter: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @ViewChild(BarChartComponent) barChartComponent!: BarChartComponent;

  error: boolean = false;
  isLoading: boolean = false;
  clients: string[] = ['COMGÁS'];
  selectedClients: string[] = [];

  params!: BarChartParams;

  form = this.formBuilder.group({
    initialDate: '',
    finalDate: '',
    clients: [['']],
  });

  get ButtonColorEnum() {
    return ButtonColorEnum;
  }

  get InputTypeEnum() {
    return InputTypeEnum;
  }

  ngOnInit() {
    const currentDate = new Date();
    const firstDayOfYear = new Date(currentDate.getFullYear(), 0, 1);

    this.form.patchValue({
      initialDate: this.formatMonthYear(firstDayOfYear),
      finalDate: this.formatMonthYear(currentDate),
    });

    this.generate();
  }

  ngOnChanges() {
    if (this.filter) {
      this.generate();
    }
  }

  formatMonthYear(date: Date): string {
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${year}-${month}`;
  }

  clear() {
    this.form.reset();
    this.selectedClients = [];
  }

  generate() {
    if (this.isLoading) return;

    this.error = false;
    this.isLoading = true;
    this.isLoadingEmmiter.emit(this.isLoading);

    this.dashboardService.getTranscriptionsByPeriod(this.filter).subscribe({
      next: (data) => {
        var footerOptions = data.map((i) => i.date);
        var clients = [
          ...new Set(
            data.flatMap((i) =>
              i.transcriptionsByPeriodQuantity.map((j) => j.clientName)
            )
          ),
        ];
        var datasets: DatasetBarChart[] = [];
        let totalTranscriptions = 0;

        clients.forEach((i) => {
          var values: string[] = [];

          data.forEach((j) => {
            var hasTranscriptionMonth = false;

            j.transcriptionsByPeriodQuantity.forEach((k) => {
              if (k.clientName == i) {
                values.push(k.quantity.toString());
                totalTranscriptions += k.quantity;
                hasTranscriptionMonth = true;
              }
            });

            if (!hasTranscriptionMonth) values.push('0');
          });

          const dataset: DatasetBarChart = {
            title: i,
            values: values,
            backgroundColor: undefined,
            borderColor: undefined,
            borderWidth: 3.5,
            borderRadius: 5,
            barPercentage: 0.3 * (clients.length / 1.7),
            type: undefined,
            hasBorderDash: false,
          };

          datasets.push(dataset);
        });

        this.totalTranscriptions.emit(totalTranscriptions);

        this.params = {
          type: 'bar',
          footerOptions: footerOptions,
          datasets: datasets,
          displayLegends: true,
          isStacked: false,
          showDatalabel: false,
        };

        this.createChart();

        this.isLoading = false;
        this.isLoadingEmmiter.emit(this.isLoading);
      },
      error: () => {
        this.error = true;
        this.isLoading = false;
        this.isLoadingEmmiter.emit(this.isLoading);
      },
    });
  }

  createChart() {
    this.barChartComponent.createChart(this.params, 'transcriptionsByClient');
  }
}
