import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { UserRoleEnum } from 'src/app/common/services/auth/enums/user-role.enum';
import { StorageService } from 'src/app/common/services/storage/storage.service';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { FilterOrientationEnum } from 'src/app/shared/filter/filter-orientation-enum';
import { FilterComponent } from 'src/app/shared/filter/filter.component';
import {
  SaReportGetAllFilter,
  SaReportGetAllResponse,
} from '../service/models/sa_report.service.model';
import { SaReportService } from '../service/sa_report.service';

@Component({
  selector: 'app-sa_report-list',
  templateUrl: './sa_report-list.component.html',
  styleUrls: ['./sa_report-list.component.scss'],
})
export class SaReportListComponent implements OnInit {
  @ViewChild(FilterComponent) filter!: FilterComponent;

  public totalItems: number = 0;
  public totalPages: number = 0;
  public page: number = 1;
  public pageSize: number = 10;
  public isLoading: boolean = false;
  public hasSupervisorRole: boolean = false;
  public sa_reportList: SaReportGetAllResponse[] = [];

  public searchForm = this.formBuilder.group({
    search: [''],
  });

  public get ButtonColorEnum() {
    return ButtonColorEnum;
  }

  public get filterOrientationEnum() {
    return FilterOrientationEnum;
  }

  constructor(
    private sa_reportService: SaReportService,
    private storageService: StorageService,
    public formBuilder: FormBuilder,
    public router: Router
  ) {}

  ngOnInit(): void {
    const role = this.storageService.getRole();
    this.hasSupervisorRole = role === UserRoleEnum.supervisor;
    this.getAll();
  }

  updateSaReportList(data: SaReportGetAllResponse) {
    this.sa_reportList.push({
      id: data.id,
      createdBy: data.createdBy,
      attendants: data.attendants,
      startDate: data.startDate,
      endDate: data.endDate,
    });
  }

  getAll() {
    if (this.isLoading) return;

    this.isLoading = true;
    this.sa_reportList = [];
    const filters = {} as SaReportGetAllFilter;
    filters.pageNumber = this.page;
    filters.pageSize = this.pageSize;

    this.sa_reportService.get(filters).subscribe({
      next: (data) => {
        this.isLoading = false;
        this.totalPages = data.totalPages;
        this.totalItems = data.totalItems;
        this.setSaReportList(data.items);
      },
      error: (e) => {
        this.isLoading = false;
        console.error(e);
      },
    });
  }

  setSaReportList(data: SaReportGetAllResponse[]) {
    if (!data?.length) return;

    this.sa_reportList = data.map((form) => ({
      id: form.id,
      createdBy: form.createdBy,
      attendants: form.attendants,
      startDate: form.startDate,
      endDate: form.endDate,
    }));
  }

  clearItems() {
    this.sa_reportList = [];
  }

  handleSelectPage(index: number) {
    this.page = index;
    this.clearItems();
    this.getAll();
  }

  navigateToNewForm() {
    this.router.navigate(['/sa_report/new']);
  }
}
