import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/common/services/storage/storage.service';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { GetClientsResponse } from '../../models/client.model';
import { UserRoleEnum } from 'src/app/common/services/auth/enums/user-role.enum';

@Component({
  selector: 'app-client-list-item',
  templateUrl: './client-list-item.component.html',
  styleUrls: ['./client-list-item.component.scss']
})
export class ClientListItemComponent {
  constructor(private storageService: StorageService, private router: Router) {}

  hasSupervisorRole: boolean = false;

  get ButtonColorEnum() {
    return ButtonColorEnum;
  }

  @Input() data: GetClientsResponse = {
    id: '',
    name: '',
    cnpj: '',
    active: false,
    creationDate: '',
    skills: [],
    holdingLineTime: '',
    idTimeExceeded: '',
    returnTimeExceeded: '',
    minimumCallTime: '',
    shifts: null,
  };

  ngOnInit(): void {
    const role = this.storageService.getRole();
    this.hasSupervisorRole = role === UserRoleEnum.supervisor;
  }

  handleDetails(id: string) {
    this.router.navigate(['/client/', id]);
  }

  formatCNPJ(cnpj: string) {
    cnpj = cnpj.replace(/\D/g, ''); // Remove tudo o que não é dígito
  
    if (cnpj.length === 14) {
      cnpj = cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, "$1.$2.$3/$4-$5");
    }
  
    return cnpj;
  }
}
