<div class="container" *ngIf="step == 1">
  <article>
    <header>
      <strong>Nova Importação</strong>
      <p>Passo 1 de 2</p>
    </header>

    <div main class="main">
      <div class="grid-content">
        <app-input
          label="Data do Atendimento"
          errorMessage="campo obrigatório"
          [control]="form.controls.serviceDate"
          [error]="
            !form.controls.serviceDate.valid &&
            !form.controls.serviceDate.pristine
          "
          [disabled]="false"
          [type]="InputTypeEnum.date"
          [required]="true"
        ></app-input>
        <app-select
          label="Cliente"
          placeholder="Selecione um cliente"
          [control]="form.controls.client"
          [options]="clients"
          [disabled]="loadingClients"
          [loading]="loadingClients"
          errorMessage="campo obrigatório"
          [error]="clientsError"
          [required]="true"
        ></app-select>
        <app-select
          label="Célula"
          [placeholder]="cellsPlaceHolder"
          [control]="form.controls.cell"
          [options]="cells"
          [disabled]="!form.controls.client.value || cellsDisabled"
          [required]="true"
          errorMessage="campo obrigatório"
          [error]="cellsError"
        ></app-select>
        <app-select
          label="Sub-Célula"
          [placeholder]="subCellsPlaceHolder"
          [control]="form.controls.subCell"
          [options]="subCells"
          [disabled]="!form.controls.client.value || subCellsDisabled"
          [required]="true"
          errorMessage="campo obrigatório"
          [error]="subCellsError"
        ></app-select>
        <app-input
          label="Quantidade Solicitada"
          placeholder="--"
          [errorMessage]="errorMessageQuantity"
          [control]="form.controls.quantity"
          [error]="
            (!form.controls.quantity.valid &&
              !form.controls.quantity.pristine) ||
            quantityError
          "
          [mask]="MaskTypeEnum.number"
          [disabled]="false"
          [required]="true"
          [maxlength]="5"
        ></app-input>
      </div>
    </div>

    <div footer class="footer">
      <app-secondary-button
        label="Cancelar"
        [color]="ButtonColorEnum.primary"
        (clickFunc)="handleNavigateBack()"
      ></app-secondary-button>
      <app-primary-button
        icon="assets/icons/arrow-right.svg"
        label="Próximo"
        [color]="ButtonColorEnum.primary"
        [loading]="isLoading"
        [enabled]="true"
        (clickFunc)="handleNextStep()"
      ></app-primary-button>
    </div>
  </article>
</div>

<div class="container" *ngIf="step == 2">
  <article>
    <header>
      <strong>Resumo da Importação</strong>
      <p>Passo 2 de 2</p>
    </header>

    <header class="resume">
      <div class="data">
        <label>Data do Atendimento</label>
        <span>{{ form.controls.serviceDate.value | date : "dd/MM/yyyy" }}</span>
      </div>

      <div class="data">
        <label>Cliente</label>
        <span>{{ form.controls.client.value }}</span>
      </div>

      <div class="data">
        <label>Célula</label>
        <span>{{ form.controls.cell.value }}</span>
      </div>

      <div class="data">
        <label>Sub-Célula</label>
        <span>{{ form.controls.subCell.value }}</span>
      </div>
    </header>

    <header>
      <div *ngIf="validQuantity()" class="audios">
        <img src="assets/icons/check.svg" />
        <div class="audios-quantity">
          <strong
            >{{ form.controls.quantity.value }}/{{ availableAudios }}</strong
          >
          <span>Áudios prontos para importação</span>
        </div>
      </div>
      <div *ngIf="!validQuantity()" class="audios">
        <img src="assets/icons/error-warning.svg" />
        <div class="audios-quantity error">
          <strong
            >{{ form.controls.quantity.value }}/{{ availableAudios }}</strong
          >
          <span
            >A quantidade de áudios a serem processados não é suficiente
          </span>
        </div>
      </div>
    </header>

    <div footer class="footer">
      <app-secondary-button
        label="Cancelar"
        [color]="ButtonColorEnum.primary"
        (clickFunc)="handleNavigateBack()"
      ></app-secondary-button>

      <app-primary-button
        icon="assets/icons/arrow-left.svg"
        label="Voltar"
        [color]="ButtonColorEnum.primary"
        [enabled]="!isLoading"
        (clickFunc)="step = 1"
      ></app-primary-button>

      <app-primary-button
        icon="assets/icons/arrow-right.svg"
        label="Executar"
        [color]="ButtonColorEnum.primary"
        [loading]="isLoading"
        [enabled]="validQuantity()"
        (clickFunc)="create()"
      ></app-primary-button>
    </div>
  </article>
</div>
