import { FormArray, FormGroup } from '@angular/forms';

export function markAllControlsAsDirty(form: FormGroup | FormArray): void {
  Object.keys(form.controls).forEach((key) => {
    const control = form.get(key);
    if (control instanceof FormGroup || control instanceof FormArray) {
      markAllControlsAsDirty(control);
    } else {
      control?.markAsDirty();
    }
  });
}
