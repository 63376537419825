import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalRedirectComponent } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { AuthGuardService } from './common/guards/auth/auth-guard.service';
import { UserRoleEnum } from './common/services/auth/enums/user-role.enum';
import { AccessDeniedComponent } from './pages/access-denied/access-denied.component';
import { AnalyticalReportComponent } from './pages/analytical-report/analytical-report.component';
import { CategoryAnalysisReportComponent } from './pages/category-analysis-report/category-analysis-report.component';
import { CategoryDetailsComponent } from './pages/category/category-details/category-details.component';
import { CategoryNewComponent } from './pages/category/category-new/category-new.component';
import { CategoryViewComponent } from './pages/category/category-view/category-view.component';
import { CategoryComponent } from './pages/category/category.component';
import { ClientDetailsComponent } from './pages/client/client-details/client-details.component';
import { ClientNewComponent } from './pages/client/client-new/client-new.component';
import { ClientComponent } from './pages/client/client.component';
import { ContactReasonDetailsComponent } from './pages/contact-reason/contact-reason-details/contact-reason-details.component';
import { ContactReasonNewComponent } from './pages/contact-reason/contact-reason-new/contact-reason-new.component';
import { ContactReasonComponent } from './pages/contact-reason/contact-reason.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { IncidentsImportNewComponent } from './pages/incidents-import/incidents-import-new/incidents-import-new.component';
import { IncidentsImportComponent } from './pages/incidents-import/incidents-import.component';
import { KeywordsCloudComponent } from './pages/keywords-cloud/keywords-cloud.component';
import { LoginComponent } from './pages/login/login.component';
import { ResolutenessReportComponent } from './pages/resoluteness-report/resoluteness-report.component';
import { SaReportNewComponent } from './pages/sa_report/sa_report-new/sa_report-new.component';
import { SaReportComponent } from './pages/sa_report/sa_report.component';
import { TranscriptionDetailsComponent } from './pages/transcription/transcription-details/transcription-details.component';
import { TranscriptionResultComponent } from './pages/transcription/transcription-list/transcription-result/transcription-result.component';
import { TranscriptionNewComponent } from './pages/transcription/transcription-new/transcription-new.component';
import { TranscriptionComponent } from './pages/transcription/transcription.component';
import { UserComponent } from './pages/user/user.component';
import { UserDetailsComponent } from './pages/user/user/user-details/user-details.component';
import { UserNewComponent } from './pages/user/user/user-new/user-new.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'login/reset-password',
    component: LoginComponent,
  },
  {
    path: 'login/reset-password/:token',
    component: LoginComponent,
  },
  {
    path: 'access-denied',
    component: AccessDeniedComponent,
  },
  {
    path: 'home',
    component: DashboardComponent,
    canActivate: [AuthGuardService],
    data: { expectedRole: [UserRoleEnum.supervisor, UserRoleEnum.monitor] },
  },
  {
    path: 'user',
    component: UserComponent,
    canActivate: [AuthGuardService],
    data: { expectedRole: [UserRoleEnum.supervisor, UserRoleEnum.monitor] },
  },
  {
    path: 'user/new',
    component: UserNewComponent,
    canActivate: [AuthGuardService],
    data: { expectedRole: [UserRoleEnum.supervisor, UserRoleEnum.monitor] },
  },
  {
    path: 'user/:id',
    component: UserDetailsComponent,
    canActivate: [AuthGuardService],
    data: { expectedRole: [UserRoleEnum.supervisor, UserRoleEnum.monitor] },
  },
  {
    path: 'category',
    component: CategoryComponent,
    canActivate: [AuthGuardService],
    data: { expectedRole: [UserRoleEnum.supervisor, UserRoleEnum.monitor] },
  },
  {
    path: 'category/new',
    component: CategoryNewComponent,
    canActivate: [AuthGuardService],
    data: { expectedRole: [UserRoleEnum.supervisor, UserRoleEnum.monitor] },
  },
  {
    path: 'category/:id',
    component: CategoryDetailsComponent,
    canActivate: [AuthGuardService],
    data: { expectedRole: [UserRoleEnum.supervisor, UserRoleEnum.monitor] },
  },
  {
    path: 'category/view/:id',
    component: CategoryViewComponent,
    canActivate: [AuthGuardService],
    data: { expectedRole: [UserRoleEnum.supervisor, UserRoleEnum.monitor] },
  },
  {
    path: 'contact-reason',
    component: ContactReasonComponent,
    canActivate: [AuthGuardService],
    data: { expectedRole: [UserRoleEnum.supervisor] },
  },
  {
    path: 'contact-reason/new',
    component: ContactReasonNewComponent,
    canActivate: [AuthGuardService],
    data: { expectedRole: [UserRoleEnum.supervisor] },
  },
  {
    path: 'contact-reason/:id',
    component: ContactReasonDetailsComponent,
    canActivate: [AuthGuardService],
    data: { expectedRole: [UserRoleEnum.supervisor] },
  },
  {
    path: 'transcription',
    component: TranscriptionComponent,
    canActivate: [AuthGuardService],
    data: { expectedRole: [UserRoleEnum.supervisor, UserRoleEnum.monitor] },
  },
  {
    path: 'transcription/new',
    component: TranscriptionNewComponent,
    canActivate: [AuthGuardService],
    data: { expectedRole: [UserRoleEnum.supervisor, UserRoleEnum.monitor] },
  },
  {
    path: 'transcription/:id',
    component: TranscriptionDetailsComponent,
    canActivate: [AuthGuardService],
    data: { expectedRole: [UserRoleEnum.supervisor, UserRoleEnum.monitor] },
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [AuthGuardService],
    data: { expectedRole: [UserRoleEnum.supervisor, UserRoleEnum.monitor] },
  },
  {
    path: 'sa_report',
    component: SaReportComponent,
    canActivate: [AuthGuardService],
    data: { expectedRole: [UserRoleEnum.supervisor, UserRoleEnum.monitor] },
  },
  {
    path: 'sa_report/new',
    component: SaReportNewComponent,
    canActivate: [AuthGuardService],
    data: { expectedRole: [UserRoleEnum.supervisor, UserRoleEnum.monitor] },
  },
  {
    path: 'category-analysis-report',
    component: CategoryAnalysisReportComponent,
    canActivate: [AuthGuardService],
    data: { expectedRole: [UserRoleEnum.supervisor, UserRoleEnum.monitor] },
  },
  {
    path: 'analytical-report',
    component: AnalyticalReportComponent,
    canActivate: [AuthGuardService],
    data: { expectedRole: [UserRoleEnum.supervisor] },
  },
  {
    path: 'resoluteness-report',
    component: ResolutenessReportComponent,
    canActivate: [AuthGuardService],
    data: { expectedRole: [UserRoleEnum.supervisor] },
  },
  {
    path: 'keywords-cloud',
    component: KeywordsCloudComponent,
    canActivate: [AuthGuardService],
    data: { expectedRole: [UserRoleEnum.supervisor, UserRoleEnum.monitor] },
  },
  {
    path: 'transcription-result/:id',
    component: TranscriptionResultComponent,
    canActivate: [AuthGuardService],
    data: { expectedRole: [UserRoleEnum.supervisor, UserRoleEnum.monitor] },
  },
  {
    path: 'client',
    component: ClientComponent,
    canActivate: [AuthGuardService],
    data: { expectedRole: [UserRoleEnum.supervisor, UserRoleEnum.monitor] },
  },
  {
    path: 'client/new',
    component: ClientNewComponent,
    canActivate: [AuthGuardService],
    data: { expectedRole: [UserRoleEnum.supervisor, UserRoleEnum.monitor] },
  },
  {
    path: 'client/:id',
    component: ClientDetailsComponent,
    canActivate: [AuthGuardService],
    data: { expectedRole: [UserRoleEnum.supervisor, UserRoleEnum.monitor] },
  },
  {
    path: 'incidents-import',
    component: IncidentsImportComponent,
    canActivate: [AuthGuardService],
    data: { expectedRole: [UserRoleEnum.supervisor, UserRoleEnum.monitor] },
  },
  {
    path: 'incidents-import/new',
    component: IncidentsImportNewComponent,
    canActivate: [AuthGuardService],
    data: { expectedRole: [UserRoleEnum.supervisor, UserRoleEnum.monitor] },
  },
  {
    path: 'auth',
    component: MsalRedirectComponent,
  },
  {
    path: '**',
    redirectTo: 'access-denied',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      initialNavigation:
        !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
          ? 'enabledNonBlocking'
          : 'disabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
