<div class="contact-reason-item">
  <div class="contact-reason-item__column bold">
    <strong>{{ data.name || "-" }}</strong>
  </div>
  <div class="contact-reason-item__column">
    <strong>{{ data.client != null ? data.client.name : "-" }}</strong>
  </div>
  <div class="contact-reason-item__column">
    <strong>{{ data.createdBy || "-"}}</strong>
  </div>
  <div class="contact-reason-item__column">
    <strong>{{ data.createdOn | date : "dd/MM/yyyy - HH:mm" }}</strong>
  </div>
  <div class="contact-reason-item__column status">
    <strong [ngClass]="{ green: data.active, red: !data.active }">{{
      getStatus()
    }}</strong>
  </div>
  <div class="incident-item__column actions" *ngIf="hasSupervisorRole">
    <app-icon-button
      title="Editar"
      icon="assets/icons/edit.svg"
      (clickFunc)="handleDetails(data.id)"
      [enabled]="!data.isDeleted"
    ></app-icon-button>

    <app-icon-button
      title="Excluir"
      icon="assets/icons/trash.svg"
      (clickFunc)="handleShowModalDelete(data.id)"
      [enabled]="!data.isDeleted"
    ></app-icon-button>
  </div>
</div>

<app-modal-response
  [showModal]="showModalResponseDelete"
  [title]="titleModalResponseDelete"
  (closeModalFunc)="handleCloseModalDelete()"
  [icon]="iconButtonModalResponseDelete"
>
  <strong main> {{ messageModalResponseDelete }} </strong>

  <app-secondary-button
    *ngIf="isConfirmQuestionDelete"
    footer
    label="Voltar"
    [right]="false"
    [color]="ButtonColorEnum.primary"
    (clickFunc)="handleCloseModalDelete()"
  ></app-secondary-button>

  <app-primary-button
    footer
    *ngIf="isSuccessModalResponseDelete && !isConfirmQuestionDelete"
    class="btn-ok"
    [label]="'Ok'"
    [right]="false"
    [color]="ButtonColorEnum.common"
    (clickFunc)="handleCloseModalDeleteAndReload()"
  ></app-primary-button>

  <app-primary-button
    footer
    *ngIf="isConfirmQuestionDelete"
    class="btn-ok"
    [label]="'Excluir'"
    [right]="false"
    [loading]="isDeleting"
    [color]="ButtonColorEnum.common"
    (clickFunc)="deleteContactReason()"
  ></app-primary-button>
</app-modal-response>
