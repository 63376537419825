import { Component } from '@angular/core';

@Component({
  selector: 'app-sa_report',
  templateUrl: './sa_report.component.html',
  styleUrls: ['./sa_report.component.scss']
})
export class SaReportComponent {

}
