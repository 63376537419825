import { Component } from '@angular/core';
import { GetCategoriesFilter } from './service/models/category.service.model';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.scss'],
})
export class CategoryComponent {
  filter!: GetCategoriesFilter;

  setFilter(filter: GetCategoriesFilter) {
    this.filter = filter;
  }
}
