<div class="container">
  <article>
    <header>
      <strong>Novo Atendimento</strong>
    </header>

    <main>
      <div main class="main">
        <div class="grid-content">
          <app-input
            label="Monitor"
            [control]="form.controls.monitor"
            [disabled]="true"
          ></app-input>
          <app-input
            label="Data e Hora do Atendimento"
            [control]="form.controls.serviceDateTime"
            [disabled]="false"
            [type]="InputTypeEnum.datetime"
            [error]="
              !form.controls.serviceDateTime.valid &&
              !form.controls.serviceDateTime.pristine
            "
            [errorMessage]="'campo obrigatório'"
          ></app-input>
          <app-input
            label="Protocolo"
            [placeholder]="'Informe o número do protocolo'"
            [control]="form.controls.protocolNumber"
            [disabled]="false"
            [error]="
              !form.controls.protocolNumber.valid &&
              !form.controls.protocolNumber.pristine
            "
            [errorMessage]="'campo obrigatório'"
          ></app-input>
          <app-select-multi
            label="Atendente"
            [placeholder]="'Selecione o atendente'"
            [options]="attendantOptions"
            [disabled]="loadingAttendants"
            [multi]="false"
            [singleControl]="form.controls.attendantId"
            [errorMessage]="'campo obrigatório'"
            [selected]="selectedAttendant"
            [loading]="loadingAttendants"
            [isSearch]="true"
          ></app-select-multi>
          <app-input
            label="Ramal"
            [placeholder]="'Informe o número do ramal'"
            [control]="form.controls.extension"
            [disabled]="false"
          ></app-input>
          <app-select-multi
            label="Cliente"
            [placeholder]="'Selecione o cliente'"
            [options]="clientOptions"
            [disabled]="loadingClients"
            [multi]="false"
            [errorMessage]="'campo obrigatório'"
            [singleControl]="form.controls.clientId"
            [selected]="selectedClient"
            [loading]="loadingClients"
            [isSearch]="true"
          ></app-select-multi>
          <app-select-multi
            label="Skill"
            [placeholder]="getSkillPlaceholder()"
            [selected]="selectedSkill"
            [options]="skillOptions"
            [singleControl]="form.controls.skillId"            
            [disabled]="isSkillDisabled()"
            [multi]="false"
            [errorMessage]="'campo obrigatório'"
          ></app-select-multi>
          <app-select
            label="Tipo"
            description="Selecione o tipo de transcrição"
            [placeholder]="getTypePlaceholder()"
            [options]="transcriptionTypeOptions"
            [errorMessage]="'campo obrigatório'"
            [error]="this.onTypeSelected()"
            (selectionChange)="onOptionSelected($event)"
          ></app-select>
        </div>
        <div class="chat" *ngIf="this.typeTranscription === 'Chat'">
          <app-text-area
            #appUploader
            label="Chat"
            description="Carregar transcrição de chat"
            [control]="form.controls.chat"
            [options]="transcriptionTypeOptions"
            (input)="onOptionSendEnabled($event)"
          ></app-text-area>
        </div>
        <div class="audio" *ngIf="this.typeTranscription === 'Áudio'">
          <app-uploader
            #appUploader
            label="Áudio"            
            description="Carregar arquivo de áudio"
            [control]="form.controls.audio"
            [extensions]="['.wav', '.mp3']"
            (change)="updateButtonState()"
          ></app-uploader>
        </div>
      </div>

      <hr />

      <div footer class="footer">
        <app-secondary-button
          footer
          label="Cancelar"
          [right]="false"
          [color]="ButtonColorEnum.primary"
          (clickFunc)="handleNavigateToList()"
        ></app-secondary-button>
        <app-primary-button
          [label]="'Enviar'"
          [icon]="'assets/icons/send_audio.svg'"
          [color]="ButtonColorEnum.common"
          [loading]="loadingRequest"
          [enabled]="(this.updateButtonState())"
          (clickFunc)="handleCreateTranscription()"
        ></app-primary-button>
      </div>
    </main>
  </article>
</div>

<app-modal-response
  [showModal]="showModalResponse"
  [title]="titleModalResponse"
  (closeModalFunc)="handleCloseModalResponse()"
  [icon]="iconButtonModalResponse"
>
  <strong main> {{ message }} </strong>

  <app-secondary-button
    footer
    label="Voltar"
    [right]="false"
    [color]="ButtonColorEnum.primary"
    (clickFunc)="handleCloseModalResponse()"
  ></app-secondary-button>

  <app-primary-button
    footer
    *ngIf="!error"
    class="btn-ok"
    label="Ok"
    [right]="false"
    [color]="ButtonColorEnum.common"
    (clickFunc)="handleCloseModalResponse()"
  ></app-primary-button>
</app-modal-response>
