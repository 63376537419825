<div class="category-item">
  <div class="category-item__column bold">
    <strong>{{ data.name || "-" }}</strong>
  </div>
  <div class="category-item__column">
    <strong>{{ data.creationDate | date : "dd/MM/yyyy - HH:mm" }}</strong>
  </div>
  <div class="category-item__column">
    <strong>{{ (data.updatedOn | date : "dd/MM/yyyy - HH:mm") ?? "-" }}</strong>
  </div>
  <div class="category-item__column">
    <strong>{{ data.client != null ? data.client.name : "-" }}</strong>
  </div>
  <div class="category-item__column status">
    <strong
      [ngClass]="{ green: data.active, red: !data.active || data.isDeleted }"
      >{{ getStatus() }}</strong
    >
  </div>
  <div class="incident-item__column actions" *ngIf="hasSupervisorRole">
    <app-icon-button
      title="Editar"
      icon="assets/icons/edit.svg"
      (clickFunc)="handleDetails(data.id)"
      [enabled]="!data.isDeleted"
    ></app-icon-button>

    <app-icon-button
      title="Visualizar"
      icon="assets/icons/eye.svg"
      (clickFunc)="handleView(data.id)"
    ></app-icon-button>

    <app-icon-button
      title="Duplicar"
      icon="assets/icons/duplicate.svg"
      [color]="ButtonColorEnum.primary"
      (clickFunc)="handleOpenDuplicateModal(data.name)"
      [enabled]="!data.isDeleted"
    ></app-icon-button>

    <app-icon-button
      title="Excluir"
      icon="assets/icons/trash.svg"
      (clickFunc)="handleShowModalDelete(data.id)"
      [enabled]="!data.isDeleted"
    ></app-icon-button>
  </div>
</div>

<app-modal-response
  [showModal]="showModalResponseDelete"
  [title]="titleModalResponseDelete"
  (closeModalFunc)="handleCloseModalDelete()"
  [icon]="iconButtonModalResponseDelete"
>
  <strong main> {{ messageModalResponseDelete }} </strong>

  <app-secondary-button
    *ngIf="isConfirmQuestionDelete"
    footer
    label="Voltar"
    [right]="false"
    [color]="ButtonColorEnum.primary"
    (clickFunc)="handleCloseModalDelete()"
  ></app-secondary-button>

  <app-primary-button
    footer
    *ngIf="isSuccessModalResponseDelete && !isConfirmQuestionDelete"
    class="btn-ok"
    [label]="'Ok'"
    [right]="false"
    [color]="ButtonColorEnum.common"
    (clickFunc)="handleCloseModalDeleteAndReload()"
  ></app-primary-button>

  <app-primary-button
    footer
    *ngIf="isConfirmQuestionDelete"
    class="btn-ok"
    [label]="'Excluir'"
    [right]="false"
    [loading]="isDeleting"
    [color]="ButtonColorEnum.common"
    (clickFunc)="deleteCategory()"
  ></app-primary-button>
</app-modal-response>

<app-modal-response
  [showModal]="showModalDuplicateResponse"
  [title]="titleModalDuplicate"
  (closeModalFunc)="handleCloseModalDuplicateResponse()"
  [icon]="iconButtonModalDuplicate"
>
  <strong main> {{ messageModalDuplicate }} </strong>

  <app-secondary-button
    footer
    *ngIf="!isSuccessDuplicate"
    label="Voltar"
    [right]="false"
    [color]="ButtonColorEnum.primary"
    (clickFunc)="handleCloseModalDuplicateResponse()"
  ></app-secondary-button>

  <app-primary-button
    footer
    *ngIf="isSuccessDuplicate"
    class="btn-ok"
    [label]="'Ok'"
    [right]="false"
    [color]="ButtonColorEnum.common"
    (clickFunc)="handleCloseModalDuplicateAndReload()"
  ></app-primary-button>
</app-modal-response>

<app-modal
  title="Duplicar Categoria"
  [showModal]="showModalDuplicate"
  (closeModalFunc)="handleCloseModalDuplicate()"
  [closeIcon]="'assets/icons/red_close.ico'"
>
  <div main class="main">
    <app-input
      label="Nome da Categoria"
      [control]="form.controls.name"
      placeholder="Informe o nome da nova categoria"
      errorMessage="campo obrigatório"
      [error]="!form.controls.name.valid"
      [disabled]="false"
    ></app-input>

    <app-select
      label="Vincular Cliente"
      placeholder="Selecione um cliente"
      errorMessage="campo obrigatório"
      [options]="clientOptions"
      [disabled]="false"
      [control]="form.controls.client"
      [loading]="loadingClients"
      [disabled]="loadingClients"
      [error]="!loadingClients && !form.controls.client.valid"
      [isSearch]="true"
    ></app-select>
  </div>
  <div footer class="footer">
    <app-secondary-button
      footer
      label="Cancelar"
      [right]="false"
      [color]="ButtonColorEnum.primary"
      (clickFunc)="handleCloseModalDuplicate()"
    ></app-secondary-button>

    <app-primary-button
      footer
      class="btn-ok"
      [label]="'Salvar'"
      [right]="false"
      [loading]="loadingDuplicateCategory"
      [color]="ButtonColorEnum.common"
      (clickFunc)="duplicateCategory(data.id)"
      [enabled]="form.valid"
    ></app-primary-button>
  </div>
</app-modal>
