import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  CreateSaReportRequest,
  PagedSaReportGetAllResponse,
  SaReportGetAllFilter,
} from './models/sa_report.service.model';

@Injectable({
  providedIn: 'root',
})
export class SaReportService {
  private baseURL: string = `${environment.apiUrl}/api/SentimentReport`;

  constructor(private http: HttpClient) {}

  private serializeQueryParams(obj: any): any {
    let queryBuilder = new HttpParams();

    Object.keys(obj).forEach((k) => {
      if (Array.isArray(obj[k])) {
        (obj[k] as []).forEach(
          (value) => (queryBuilder = queryBuilder.append(k, value))
        );
      } else if (obj[k]) {
        queryBuilder = queryBuilder.append(k, obj[k]);
      }
    });

    return queryBuilder;
  }

  get(filter: SaReportGetAllFilter): Observable<PagedSaReportGetAllResponse> {
    const params = this.serializeQueryParams(filter);
    return this.http.get<PagedSaReportGetAllResponse>(this.baseURL, { params });
  }

  create(params: CreateSaReportRequest) {
    return this.http.post(this.baseURL, params, { responseType: 'blob', observe: 'response' });
  }

  downloadReport(id: string) {
    return this.http.get(this.baseURL + '/' + id + '/download', {
      responseType: 'blob',
    });
  }
}
