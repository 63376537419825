<div [ngClass]="inlineContainer ? 'inline-container' : 'container'">
  <div *ngIf="loading" class="loading">
    <div class="loading-animation"></div>
  </div>

  <canvas *ngIf="!loading && !error" id="{{ chartId }}"></canvas>

  <div class="error" *ngIf="error">
    <img src="assets/icons/error.svg" alt="" />
    <strong>Houve um problema ao processar os dados.</strong>
    <label>por favor tente novamente mais tarde.</label>
  </div>
</div>
