import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, tap, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StorageService } from '../storage/storage.service';
import { LoginResponse, LogoutRequest } from './models/login-response.model';
import { User } from './models/user.model';
import { MsalService } from '@azure/msal-angular';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private baseURL: string = `${environment.apiUrl}/api/Authenticate`;

  constructor(
    private http: HttpClient,
    private router: Router,
    private storageService: StorageService,
    private authCorpService: MsalService
  ) {}

  endpointLogout(request: LogoutRequest): Observable<any> {
    return this.http.post<any>(this.baseURL + '/Logout', request);
  }

  logout(callLogoutEndpoint: boolean) {
    if (callLogoutEndpoint) {
      var email = this.storageService.getUser().email;
      this.endpointLogout({ email } as LogoutRequest).subscribe({
        next: (data) => {},
        error: (e) => {
          console.error(e);
        },
      });
    }

    this.storageService.clear();
    this.router.navigate(['login']);
  }

  login(): Observable<LoginResponse> {
    return this.http.get<any>(this.baseURL);
  }
}
