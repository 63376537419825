import { Component } from '@angular/core';
import { GetClientsFilter } from './models/client.model';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss']
})
export class ClientComponent {
  filter!: GetClientsFilter;

  setFilter(filter: GetClientsFilter) {
    this.filter = filter;
  }
}
