<div class="container">
  <article>
    <header>
      <strong>Atendimentos Importados</strong>
      <section>
        <div *ngIf="!isLoading">
          <span>{{ totalItems }} resultado(s)</span>
        </div>
        <app-icon-button
          icon="assets/icons/refresh.svg"
          [color]="ButtonColorEnum.primary"
          [loading]="isLoading"
          (clickFunc)="get()"
        ></app-icon-button>
        <app-primary-button
          label="Nova Importação"
          icon="assets/icons/add.svg"
          [color]="ButtonColorEnum.common"
          (clickFunc)="create()"
        ></app-primary-button>
      </section>
    </header>
    <main>
      <app-incidents-import-list-header></app-incidents-import-list-header>
      <ng-container *ngIf="isLoading">
        <div *ngFor="let i of [1, 2, 3, 4, 5]" class="skeleton">
          <div class="skeleton-column">
            <div class="skeleton-item"></div>
          </div>
        </div>
      </ng-container>
      <app-incidents-import-list-item
        *ngFor="let import of imports"
        [data]="import"
      ></app-incidents-import-list-item>
      <app-paginator
        [page]="page"
        [totalPages]="totalPages"
        [elementsShowing]="imports.length"
        [totalElements]="totalItems"
        (selectPageFunc)="handleSelectPage($event)"
      ></app-paginator>
    </main>
  </article>
</div>
