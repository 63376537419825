<article>
    <header>
      <strong>Gestão de Clientes</strong>
      <section>
        <app-primary-button
          *ngIf="hasSupervisorRole"
          label="Novo Cliente"
          icon="assets/icons/add.svg"
          [color]="ButtonColorEnum.common"
          (clickFunc)="navigateToNewForm()"
        ></app-primary-button>
      </section>
    </header>
    <main>
      <app-client-list-header></app-client-list-header>
      <ng-container *ngIf="isLoading">
        <div *ngFor="let i of [1, 2, 3, 4, 5]" class="skeleton">
          <div class="skeleton-column">
            <div class="skeleton-item"></div>
          </div>
        </div>
      </ng-container>
      <app-client-list-item
        *ngFor="let form of clientList"
        [data]="form"
      ></app-client-list-item>
      <app-paginator
        [page]="page"
        [totalPages]="totalPages"
        [elementsShowing]="clientList.length"
        [totalElements]="totalItems"
        (selectPageFunc)="handleSelectPage($event)"
      ></app-paginator>
    </main>
  </article>
  