import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  CreateIncidentsImportRequest,
  GetAudiosIncidentsImportRequest,
  GetIncidentsImportRequest,
  GetPagedIncidentsImportResponse,
} from './incidents-import.model';

@Injectable({
  providedIn: 'root',
})
export class IncidentsImportService {
  private baseURL: string = environment.apiUrl + '/api/AudioImportControl';

  constructor(private http: HttpClient) {}

  private serializeQueryParams(obj: any): any {
    let queryBuilder = new HttpParams();

    Object.keys(obj).forEach((k) => {
      if (Array.isArray(obj[k])) {
        (obj[k] as []).forEach(
          (value) => (queryBuilder = queryBuilder.append(k, value))
        );
      } else if (obj[k]) {
        queryBuilder = queryBuilder.append(k, obj[k]);
      }
    });

    return queryBuilder;
  }

  get(
    filter: GetIncidentsImportRequest
  ): Observable<GetPagedIncidentsImportResponse> {
    const params = this.serializeQueryParams(filter);

    return this.http.get<GetPagedIncidentsImportResponse>(this.baseURL, {
      params,
    });
  }

  post(request: CreateIncidentsImportRequest): Observable<any> {
    return this.http.post<any>(this.baseURL, request);
  }

  getAudios(request: GetAudiosIncidentsImportRequest): Observable<any> {
    const params = this.serializeQueryParams(request);

    return this.http.get<any>(this.baseURL + '/Count', {
      params,
    });
  }
}
