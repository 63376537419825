import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  MSAL_GUARD_CONFIG,
  MsalBroadcastService,
  MsalGuardConfiguration,
  MsalService,
} from '@azure/msal-angular';
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionStatus,
  RedirectRequest,
} from '@azure/msal-browser';
import { ToastrService } from 'ngx-toastr';
import { filter, finalize } from 'rxjs';
import { createClaimsTable } from 'src/app/claim-utils';
import { AuthService } from 'src/app/common/services/auth/auth.service';
import { User } from 'src/app/common/services/auth/models/user.model';
import { StorageService } from 'src/app/common/services/storage/storage.service';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { CardColorEnum } from 'src/app/shared/card/enums/card-color.enum';
import { InputTypeEnum } from 'src/app/shared/input/enums/input-type.enum';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  isLoading: boolean = false;
  error: boolean = false;
  loginDisplay = false;
  displayedColumns: string[] = ['claim', 'value', 'description'];
  dataSource: any = [];

  public get ButtonColorEnum() {
    return ButtonColorEnum;
  }
  public get CardColorEnum() {
    return CardColorEnum;
  }
  public get InputTypeEnum() {
    return InputTypeEnum;
  }

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public formBuilder: FormBuilder,
    private storageService: StorageService,
    private authService: AuthService,
    private authCorpService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private toastrService: ToastrService,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration
  ) {}

  ngOnInit() {
    this.storageService.clear();

    this.authCorpService.initialize();

    this.msalBroadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        finalize(() => {
          const activeAccounts = this.authCorpService.instance.getAllAccounts();

          if (activeAccounts.length > 0) {
            this.checkAndSetActiveAccount();
            this.login();
          } else {
            this.authCorpService.loginPopup();
          }
          this.setLoginDisplay();
          this.getClaims(
            this.authCorpService.instance.getActiveAccount()?.idTokenClaims
          );
        })
      )
      .subscribe(() => {});

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
      )
      .subscribe((result: EventMessage) => {
        this.isLoading = true;

        const payload = result.payload as AuthenticationResult;
        const accessToken = payload.accessToken;
        this.storageService.setToken(accessToken);

        this.authService.login().subscribe({
          next: (data) => {
            const user = {
              id: data.id,
              name: data.name,
              email: data.email,
            } as User;

            this.storageService.setUser(user);
            this.storageService.setRole(data.role);
            this.storageService.setToken(data.token);

            this.isLoading = false;

            this.router.navigate(['home']);
          },
          error: (e) => {
            this.error = true;
            this.showError();
            this.isLoading = false;
            this.storageService.clear();
            console.error(e);
          },
        });
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => {
          return msg.eventType !== EventType.LOGIN_SUCCESS;
        })
      )
      .subscribe((result: EventMessage) => {});

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) => msg.eventType === EventType.LOGOUT_SUCCESS
        )
      )
      .subscribe((result: EventMessage) => {
        this.router.navigate(['login']);
      });
  }

  setLoginDisplay() {
    this.loginDisplay =
      this.authCorpService.instance.getAllAccounts().length > 0;
  }

  checkAndSetActiveAccount() {
    let activeAccount = this.authCorpService.instance.getActiveAccount();

    if (
      !activeAccount &&
      this.authCorpService.instance.getAllAccounts().length > 0
    ) {
      let accounts = this.authCorpService.instance.getAllAccounts();
      this.authCorpService.instance.setActiveAccount(accounts[0]);
    }
  }

  getClaims(claims: any) {
    if (claims) {
      const claimsTable = createClaimsTable(claims);
      this.dataSource = [...claimsTable];
    }
  }

  handleLogin() {
    this.error = false;

    if (this.isLoading) return;

    this.isLoading = true;
    this.storageService.clear();

    const activeAccounts = this.authCorpService.instance.getAllAccounts();
    if (activeAccounts.length > 0) {
      this.checkAndSetActiveAccount();
      this.login();
    } else {
      this.authCorpService.loginRedirect({
        prompt: 'select_account',
      } as RedirectRequest);
    }
  }

  login() {
    if (this.msalGuardConfig.authRequest) {
      this.authCorpService.loginRedirect({
        ...this.msalGuardConfig.authRequest,
      } as RedirectRequest);
    } else {
      this.authCorpService.loginRedirect();
    }
  }

  showError() {
    this.toastrService.warning(
      'Seu usuário não tem acesso à essa aplicação.\nFavor consultar o Administrador.'
    );
  }
}