import { Component, Input } from '@angular/core';
import { InfoboxTypeEnum } from './enums/infobox-type.enum';

@Component({
  selector: 'app-infobox',
  templateUrl: './infobox.component.html',
  styleUrls: ['./infobox.component.scss']
})

export class InfoboxComponent {
  @Input() type: InfoboxTypeEnum = InfoboxTypeEnum.attention;
}
