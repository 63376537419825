import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AnalyticalReportFilter } from './models/analytical-report.model';

@Injectable({
  providedIn: 'root',
})
export class AnalyticalReportService {
  constructor(private http: HttpClient) {}

  private baseURL: string = `${environment.apiUrl}/api/AnalyticalReport`;

  generateReport(filters: AnalyticalReportFilter) {
    return this.http.post(this.baseURL, filters, { responseType: 'blob', observe: 'response' });
  }
}
