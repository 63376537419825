import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { StatusEnum } from 'src/app/common/utils/status-enum.models';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { InputTypeEnum } from 'src/app/shared/input/enums/input-type.enum';
import { MaskTypeEnum } from 'src/app/shared/input/enums/mask-type.enum';
import {
  GetClientsFilter,
  GetClientsResponse,
} from '../../client/models/client.model';
import { ClientService } from '../../client/service/client.service';
import {
  CreateIncidentsImportRequest,
  GetAudiosIncidentsImportRequest,
} from '../incidents-import.model';
import { IncidentsImportService } from '../incidents-import.service';

@Component({
  selector: 'app-incidents-import-new',
  templateUrl: './incidents-import-new.component.html',
  styleUrls: ['./incidents-import-new.component.scss'],
})
export class IncidentsImportNewComponent implements OnInit {
  constructor(
    public formBuilder: FormBuilder,
    public service: IncidentsImportService,
    public toastrService: ToastrService,
    public router: Router,
    private clientService: ClientService
  ) {}

  isLoading: boolean = false;

  errorMessageQuantity: string = 'campo obrigatório';
  quantityError: boolean = false;

  clients: string[] = [];
  originalClients: GetClientsResponse[] = [];
  loadingClients: boolean = false;
  clientsError: boolean = false;

  cells: string[] = [];
  cellsError: boolean = false;
  cellsDisabled: boolean = false;
  cellsPlaceHolder: string = 'Selecione uma célula';

  subCells: string[] = [];
  subCellsError: boolean = false;
  subCellsDisabled: boolean = false;
  subCellsPlaceHolder: string = 'Selecione uma sub-célula';

  ButtonColorEnum = ButtonColorEnum;
  MaskTypeEnum = MaskTypeEnum;
  InputTypeEnum = InputTypeEnum;

  step: number = 0;
  availableAudios: number = 0;

  form = this.formBuilder.group({
    serviceDate: ['', Validators.required],
    client: ['', Validators.required],
    cell: ['', Validators.required],
    subCell: ['', Validators.required],
    quantity: ['', Validators.required],
  });

  ngOnInit(): void {
    this.step = 1;
    this.loadingClients = true;
    this.populateClients();

    this.form.controls.client.valueChanges.subscribe((value: string | null) => {
      this.form.controls.cell.reset();
      this.form.controls.subCell.reset();

      if (this.form.controls.client.status == 'INVALID')
        this.clientsError = true;
      else {
        if (value) {
          let clientId = this.getClientIdFromText(value);
          let client = this.originalClients.find((x) => x.id == clientId);
          if (client) {
            this.cells = Array.from(new Set(client.skills.map((i) => i.cell)));
            this.subCells = Array.from(
              new Set(client.skills.map((i) => i.subCell))
            );
            if (this.cells.length == 0) {
              this.cellsDisabled = true;
              this.cellsPlaceHolder =
                'Não há células disponíveis para este cliente';
            } else {
              this.cellsDisabled = false;
              this.cellsPlaceHolder = 'Selecione uma célula';
            }
            if (this.subCells.length == 0) {
              this.subCellsDisabled = true;
              this.subCellsPlaceHolder =
                'Não há sub-células disponíveis para este cliente';
            } else {
              this.subCellsDisabled = false;
              this.subCellsPlaceHolder = 'Selecione uma sub-célula';
            }
          }
        }
        this.clientsError = false;
      }
    });

    this.form.controls.cell.valueChanges.subscribe(() => {
      if (this.form.controls.cell.status == 'INVALID') this.cellsError = true;
      else this.cellsError = false;
    });

    this.form.controls.subCell.valueChanges.subscribe(() => {
      if (this.form.controls.subCell.status == 'INVALID')
        this.subCellsError = true;
      else this.subCellsError = false;
    });

    this.form.controls.quantity.valueChanges.subscribe(() => {
      this.errorMessageQuantity = 'campo obrigatório';
    });
  }

  populateClients() {
    this.clients = [];

    this.clientService
      .get({
        status: [StatusEnum.ATIVO],
      } as GetClientsFilter)
      .subscribe({
        next: (data) => {
          this.originalClients = data.items;
          this.clients = data.items.map((client) => client.name);
          this.loadingClients = false;
        },
      });
  }

  getClientIdFromText(text: string): string | null {
    if (!text || text.trim() === '') return null;

    var client = this.originalClients.find(
      (i) => i.name.trim() === text.trim()
    );

    return client ? client.id : null;
  }

  handleNavigateBack() {
    this.router.navigate(['/incidents-import/']);
  }

  handleNextStep() {
    Object.keys(this.form.controls).forEach((key) => {
      const control =
        this.form.controls[key as keyof typeof this.form.controls];
      if (control.status == 'INVALID') control.markAsDirty();
    });

    if (this.form.controls.client.status == 'INVALID') this.clientsError = true;
    if (this.form.controls.cell.status == 'INVALID') this.cellsError = true;
    if (this.form.controls.subCell.status == 'INVALID')
      this.subCellsError = true;

    if (this.form.controls.quantity.value?.trim() == '0') {
      this.quantityError = true;
      this.errorMessageQuantity = 'quantidade não pode ser 0';
      return;
    }

    if (!this.form.valid || this.isLoading) return;

    this.isLoading = true;

    var request = {
      serviceDate: this.form.controls.serviceDate.value ?? '',
      clientId:
        this.getClientIdFromText(this.form.controls.client.value ?? '') ?? '',
      cell: this.form.controls.cell.value ?? '',
      subCell: this.form.controls.subCell.value ?? '',
    } as GetAudiosIncidentsImportRequest;

    this.service.getAudios(request).subscribe({
      next: (data) => {
        this.isLoading = false;
        this.availableAudios = data;
        this.step = 2;
        this.quantityError = false;
      },
      error: () => {
        this.isLoading = false;
        this.toastrService.error(
          'Ocorreu um erro ao buscar áudios disponíveis para importação de atendimentos'
        );
      },
    });
  }

  validQuantity(): boolean {
    var quantity = this.form.controls.quantity.value ?? 0;
    if (quantity == 0) return false;
    return this.availableAudios >= Number(quantity);
  }

  create() {
    this.isLoading = true;

    var request = {
      serviceDate: this.form.controls.serviceDate.value ?? '',
      clientId:
        this.getClientIdFromText(this.form.controls.client.value ?? '') ?? '',
      cell: this.form.controls.cell.value ?? '',
      subCell: this.form.controls.subCell.value ?? '',
      requestQty: this.form.controls.quantity.value ?? 0,
    } as CreateIncidentsImportRequest;

    this.service.post(request).subscribe({
      next: () => {
        this.isLoading = false;
        this.toastrService.success(
          'Importação de atendimentos registrada com sucesso'
        );
        this.router.navigate(['/incidents-import']);
      },
      error: () => {
        this.isLoading = false;
        this.toastrService.error(
          'Ocorreu um erro ao registrar importação de atendimentos'
        );
      },
    });
  }
}
