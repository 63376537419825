<div class="container" (clickOutside)="blurInput()">
  <div class="input-box" [ngClass]="{ disabled: disabled, error: error }">
    <div *ngIf="fieldVisible" class="label-group" (resized)="onResized($event)">
      <label class="input" [ngClass]="disabled ? 'input disabled' : 'input'">
        {{ label }}
      </label>
      <small class="desc">{{ description }}</small>
    </div>

    <div
      [id]="onFocus ? 'input-icon-focus' : 'input-icon'"
      class="input-icon"
      [ngStyle]="{ height: height + 'rem' }"
      (click)="!isReadOnly ? (onFocus ? blurInput() : focusInput()) : null"
    >
      <span
        *ngIf="
          (selected.length == 0 && !error) ||
          loading ||
          (disabled && selected.length == 0)
        "
        class="placeholder"
      >
        {{ placeholder }}
      </span>
      <span
        *ngIf="selected.length == 0 && error && !loading && !disabled"
        class="placeholder-error"
      >
        {{ placeholder }}
      </span>
      <div class="options-selected">
        <div
          *ngIf="selected.length !== 0"
          class="option-selected"
          [ngStyle]="{ height: heightSelect }"
          (click)="$event.stopPropagation()"
        >
          <label
            [ngClass]="{
              'label-disabled': disabled,
              'ellipsis-multi': selected.length > 1,
              'ellipsis-single': selected.length === 1
            }"
          >
            {{ selected[0] }}
          </label>
          <img
            class="exclude-icon"
            [src]="
              disabled
                ? '../../../assets/icons/exclude-common-400.svg'
                : './../../assets/icons/exclude-primary-600.svg'
            "
            alt="remove"
            (click)="!disabled && removeOption(selected[0], $event)"
          />
        </div>
        <div
          *ngIf="selected.length > 1"
          class="option-selected"
          [ngStyle]="{ height: heightSelect }"
        >
          <label [ngClass]="{ 'label-disabled': disabled }">
            +{{ selected.length - 1 }}
          </label>
          <img
            class="exclude-icon"
            [src]="
              disabled
                ? '../../../assets/icons/exclude-common-400.svg'
                : './../../assets/icons/exclude-primary-600.svg'
            "
            alt="remove"
            (click)="!disabled && removeAll($event)"
          />
        </div>
      </div>
      <img
        *ngIf="!loading && !disabled"
        class="accordion"
        [src]="
          error
            ? '../../../assets/icons/multi-select-arrow-warning-600.svg'
            : '../../../assets/icons/multi-select-arrow.svg'
        "
        alt="open-options"
        [ngClass]="onFocus ? 'on-focus' : 'no-focus'"
      />
      <div *ngIf="loading" class="loading-animation"></div>
    </div>
  </div>

  <div *ngIf="onFocus" class="options-box" [ngClass]="{ single: !multi }">
    <div *ngIf="isSearch && !disabled" class="search">
      <app-input
        *ngIf="isSearch"
        class="search"
        placeholder="O que você está buscando?"
        icon="assets/icons/search.svg"
        (valueChange)="filterOptions(search.value ?? '')"
        [backgroundColorGrey]="true"
        [control]="search"
        [paddingTopSection]="'0.5rem'"
        [paddingLeftSection]="'1rem'"
        [paddingRightSection]="'1rem'"
      ></app-input>
    </div>
    <div *ngIf="filtredOptions.length !== 0; else emptyList">
      <div *ngIf="multi" class="selectedAll">
        <app-check-box
          [label]="'Selecionar todos'"
          [bold]="checkedAll"
          [value]="checkedAll"
          (check)="checkAll()"
          (removeCheck)="removeOptionAll($event)"
          [disabled]="disabled"
        ></app-check-box>
      </div>
      <div class="options" [ngClass]="{ 'options-disabled': disabled }">
        <div class="option" *ngFor="let option of filtredOptions">
          <app-check-box
            [label]="option"
            [bold]="isChecked(option)"
            [value]="isChecked(option)"
            (check)="checkOption(option)"
            (removeCheck)="removeOption(option, $event)"
            [disabled]="disabled"
          ></app-check-box>
        </div>
      </div>
    </div>
    <ng-template #emptyList>
      <label class="emptyList">Nenhum resultado encontrado</label>
    </ng-template>
  </div>

  <div [style.visibility]="(error && !loading && !disabled) ? 'visible' : 'hidden'" class="error-info">
    <img src="../../../assets/icons/error-warning-600.svg" alt="Error icon" />
    <span>{{ errorMessage }}</span>
  </div>
</div>
