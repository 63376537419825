import {
  Component,
  OnInit,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserRoleEnum } from 'src/app/common/services/auth/enums/user-role.enum';
import { StatusEnum } from 'src/app/common/utils/status-enum.models';
import { StorageService } from 'src/app/common/services/storage/storage.service';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { InputTypeEnum } from 'src/app/shared/input/enums/input-type.enum';
import { MaskTypeEnum } from 'src/app/shared/input/enums/mask-type.enum';
import {
  CreateUserRequest,
  GetUserResponse,
  GetUsersFilter,
} from '../../service/models/user.service.model';
import { UserService } from '../../service/user.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-user-new',
  templateUrl: './user-new.component.html',
  styleUrls: ['./user-new.component.scss'],
})
export class UserNewComponent implements OnInit {
  constructor(
    public formBuilder: FormBuilder,
    public userService: UserService,
    public toastrService: ToastrService,
    public router: Router,
    private storageService: StorageService
  ) {}

  isLoading: boolean = false;
  error: boolean = false;
  selectSupervisorEnabled: boolean = true;
  supervisors: string[] = [];
  roleOptions: string[] = [];
  activeOptions: string[] = ['Ativo', 'Inativo'];
  isSuperAdmin: boolean = false;
  originalSupervisors: GetUserResponse[] = [];
  loadingSupervisors: boolean = false;
  selectedSupervisors: string[] = [];

  form = this.formBuilder.group({
    name: ['', Validators.required],
    email: ['', Validators.required],
    functional: ['', Validators.required],
    supervisor: ['', Validators.required],
    active: ['', Validators.required],
    perfil: ['', Validators.required],
    recorderId: [null],
  });

  get ButtonColorEnum() {
    return ButtonColorEnum;
  }

  get MaskTypeEnum() {
    return MaskTypeEnum;
  }

  get InputTypeEnum() {
    return InputTypeEnum;
  }

  ngOnInit(): void {
    this.loadingSupervisors = true;

    Object.keys(this.form.controls).forEach(key => {
      const control = this.form.controls[key as keyof typeof this.form.controls];
      if (control.status == "INVALID")
        control.markAsDirty();
    });

    this.form.controls.perfil.valueChanges.subscribe(
      (selectedValue: string | null) => {
        if (selectedValue === 'SUPERVISOR') {
          this.selectSupervisorEnabled = false;
          this.form.get('supervisor')?.clearValidators();
          this.form.get('supervisor')?.updateValueAndValidity();
          this.form.get('email')?.setValidators([Validators.required]);
          this.form.get('email')?.updateValueAndValidity();
        } else if (selectedValue == 'ATENDENTE') {
          this.selectSupervisorEnabled = true;
          this.form.get('supervisor')?.setValidators([Validators.required]);
          this.form.get('supervisor')?.updateValueAndValidity();
          this.form.get('email')?.clearValidators();
          this.form.get('email')?.updateValueAndValidity();
        } else {
          this.selectSupervisorEnabled = true;
          this.form.get('supervisor')?.setValidators([Validators.required]);
          this.form.get('supervisor')?.updateValueAndValidity();
          this.form.get('email')?.setValidators([Validators.required]);
          this.form.get('email')?.updateValueAndValidity();
        }
      }
    );

    this.populateSupervisors();
    this.populateRoleOptions();
  }

  populateRoleOptions() {
    this.userService.getRoles().subscribe({
      next: (roles) => {
        this.roleOptions = roles;

        if (this.storageService.getRole() !== UserRoleEnum.supervisor)
          this.roleOptions = this.roleOptions.filter(
            (role) => role !== 'ATENDENTE'
          );
      },
    });
  }

  populateSupervisors() {
    this.supervisors = [];

    this.userService
      .get({
        profiles: ['SUPERVISOR'],
        status: [StatusEnum.ATIVO],
      } as GetUsersFilter)
      .subscribe({
        next: (data) => {
          this.originalSupervisors = data.items;
          this.supervisors = data.items.map(
            (supervisor) => supervisor.functional + ' | ' + supervisor.name
          );
          this.loadingSupervisors = false;
        },
      });
  }

  getSupervisorIdFromText(text: string): string | null {
    if (!text || text.trim() === '') return null;

    var [functionalString, name] = text.split('|').map((part) => part.trim());
    var supervisor = this.originalSupervisors.find(
      (attendant) =>
        attendant.functional.toString() === functionalString &&
        attendant.name.trim() === name.trim()
    );

    return supervisor ? supervisor.id : null;
  }

  handleNavigateBack() {
    this.form.reset();
    this.router.navigate(['/user/']);
  }

  handleCreateUser() {
    if (!this.form.valid || this.isLoading) return;

    this.isLoading = true;
    this.error = false;

    var request = {
      name: this.form.controls.name.value ?? '',
      email: this.form.controls.email.value ?? '',
      functional: Number(this.form.controls.functional.value) ?? '',
      supervisorId:
        this.getSupervisorIdFromText(
          this.form.controls.supervisor.value || ''
        ) ?? null,
      roleName: this.form.controls.perfil.value ?? '',
      active: this.form.controls.active.value === 'Ativo' ? true : false,
      recorderId: this.form.controls.recorderId.value ?? null,
    } as CreateUserRequest;

    if (request.roleName == 'ATENDENTE') {
      request.email = null;
    }

    if (request.roleName == 'SUPERVISOR') {
      request.supervisorId = null;
    }

    this.userService.createUser(request).subscribe({
      next: () => {
        this.isLoading = false;
        this.toastrService.success('Usuário cadastrado com sucesso!');
        this.form.reset();
        this.router.navigate(['/user']);
      },
      error: (e) => {
        this.isLoading = false;
        this.error = true;
        this.toastrService.error(`Falha ao cadastrar usuário: ${e.error}`);
      },
    });
  }
}
