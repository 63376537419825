<div class="login-container">
  <div>
    <div class="circle-top"></div>
    <div class="line-1"></div>
    <div class="line-2"></div>
    <div class="line-3"></div>
    <div class="line-4"></div>
    <div class="line-5"></div>
    <div class="circle-bottom"></div>
  </div>

  <div class="overlay">
    <div class="left">
      <img
        src="assets/images/boasvindas.svg"
        alt="Boas Vindas"
        class="boas-vindas"
      />
    </div>
    <div class="right">
      <app-login-button
        label="Entrar"
        [widthFull]="true"
        (clickFunc)="handleLogin()"
        [loading]="isLoading"
        [width]="'27rem'"
        class="login-button"
      ></app-login-button>
    </div>
  </div>
</div>
