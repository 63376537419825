import { Component } from '@angular/core';
import { UserRoleEnum } from 'src/app/common/services/auth/enums/user-role.enum';
import { StorageService } from 'src/app/common/services/storage/storage.service';

@Component({
  selector: 'app-contact-reason-list-header',
  templateUrl: './contact-reason-list-header.component.html',
  styleUrls: ['./contact-reason-list-header.component.scss'],
})
export class ContactReasonListHeaderComponent {
  public hasSupervisorRole: boolean = false;

  constructor(private storageService: StorageService) {}

  ngOnInit(): void {
    const role = this.storageService.getRole();
    this.hasSupervisorRole = role === UserRoleEnum.supervisor;
  }
}
