import { Component } from '@angular/core';
import { GetKeywordsCloudRequest } from './keywords-cloud.model';

@Component({
  selector: 'app-keywords-cloud',
  templateUrl: './keywords-cloud.component.html',
  styleUrls: ['./keywords-cloud.component.scss']
})
export class KeywordsCloudComponent {
  filter!: GetKeywordsCloudRequest;

  setFilter(filter: GetKeywordsCloudRequest) {
    this.filter = filter;
  }
}
