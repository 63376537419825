import { Component, Input } from '@angular/core';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { CategoryAnalysisReportGetResponse } from '../../service/models/category-analysis-report.service.model';

@Component({
  selector: 'app-category-analysis-report-list-item',
  templateUrl: './category-analysis-report-list-item.component.html',
  styleUrls: ['./category-analysis-report-list-item.component.scss'],
})
export class CategoryAnalysisReportListItemComponent {
  @Input() data: CategoryAnalysisReportGetResponse = {
    attendant: '',
    serviceDate: new Date(),
    category: '',
    keyword: '',
    quantity: 0,
  };

  public downloading: boolean = false;
  public downloadIcon: string = 'download';

  public get ButtonColorEnum() {
    return ButtonColorEnum;
  }
}
