import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserRoleEnum } from 'src/app/common/services/auth/enums/user-role.enum';
import { StorageService } from 'src/app/common/services/storage/storage.service';
import { IncidentStatusEnum } from '../../enums/incident-status.enum';
import { GetTranscriptionsFilter } from '../../service/models/transcription.service.model';
import { TranscriptionService } from '../../service/transcription.service';

@Component({
  selector: 'app-transcription-list-header',
  templateUrl: './transcription-list-header.component.html',
  styleUrls: ['./transcription-list-header.component.scss'],
})
export class TranscriptionListHeaderComponent implements OnInit {
  constructor(
    private storageService: StorageService,
    public transcriptionService: TranscriptionService
  ) {}

  @Input() filter!: GetTranscriptionsFilter;
  @Input() totalItems!: number;
  @Input() incidentIdsToReprocessCount: number = 0;
  @Input() incidentIdsLoading: boolean = false;

  @Output() incidentIdsToReprocess = new EventEmitter<string[]>();

  hasSupervisorRole: boolean = false;
  isChecked: boolean = false;

  ngOnInit(): void {
    const role = this.storageService.getRole();
    this.hasSupervisorRole = role === UserRoleEnum.supervisor;
  }

  getGridTemplateColumnsValue() {
    if (this.hasSupervisorRole)
      return '0 repeat(auto-fit, minmax(2.5rem, 1fr))';
    return 'repeat(auto-fit, minmax(2.5rem, 1fr))';
  }

  handleCheck() {
    const filters = JSON.parse(JSON.stringify(this.filter));

    this.treatFilters(filters);

    this.incidentIdsLoading = true;

    this.transcriptionService.getIds(filters).subscribe({
      next: (data) => {
        this.incidentIdsToReprocessCount = data.length;
        this.incidentIdsToReprocess.emit(data);
        this.incidentIdsLoading = false;
        this.isChecked = true;
      },
    });
  }

  treatFilters(filters: any) {
    if (
      !filters.statusName ||
      filters.statusName.length == 0 ||
      (filters.statusName.length == 1 &&
        filters.statusName[0] == IncidentStatusEnum.aguard_analise.toString())
    ) {
      filters.statusName = [];
      Object.values(IncidentStatusEnum).forEach((status) => {
        if (status != IncidentStatusEnum.aguard_analise.toString()) {
          filters.statusName.push(status.toString());
        }
      });
    } else if (
      filters.statusName.length > 1 &&
      filters.statusName.includes(IncidentStatusEnum.aguard_analise.toString())
    )
      filters.statusName = this.filter.statusName.filter(
        (status) => status !== IncidentStatusEnum.aguard_analise.toString()
      );
  }

  handleUncheck() {
    this.incidentIdsToReprocessCount = 0;
    this.isChecked = false;
    this.incidentIdsToReprocess.emit([]);
  }
}
