<section
  [ngClass]="{
    success: type === 'success',
    attention: type === 'attention',
    warning: type === 'warning'
  }"
>
  <img
    [src]="
      type === 'success'
        ? '../../../assets/icons/success.svg'
        : type === 'attention'
        ? '../../../assets/icons/error.svg'
        : '../../../assets/icons/error.svg'
    "
    alt="Info icon"
  />
  <div class="content">
    <ng-content select="[content]"></ng-content>
  </div>
</section>
