import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonColorEnum } from '../buttons/enums/button-color.enum';
import { FilterItem } from './filter-item.model';
import { FilterOrientationEnum } from './filter-orientation-enum';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent {
  @Input() enabled: boolean = true;
  @Input() loading: boolean = false;
  @Input() filterOptions: FilterItem[] = [];
  @Input() orientation: FilterOrientationEnum = FilterOrientationEnum.right;

  @Output() searchEvent: EventEmitter<{ [key: string]: string[] }> =
    new EventEmitter();

  selectedItems: { [key: string]: string[] } = {};
  isOpen: boolean = false;
  open: boolean = false;
  count: number = 0;
  value: string = '';
  hasSelectedItems: boolean = false;
  isSeaching: boolean = false;
  filteredItems: FilterItem[] = [];

  constructor() {}

  public get buttonColorEnum() {
    return ButtonColorEnum;
  }

  ngOnChanges() {
    if (!this.isSeaching) {
      this.filterOptions.forEach((item) => {
        this.selectedItems[item.title] = [];
      });
      this.count = 0;
    }

    this.isOpen = false;

    this.filteredItems = JSON.parse(JSON.stringify(this.filterOptions));
  }

  ngAfterViewChecked() {
    this.check();
  }

  check() {
    Object.entries(this.selectedItems).forEach(([title, items]) => {
      items.forEach((item) => {
        const checkElement = document.getElementById(title + ':' + item);

        if (checkElement) {
          const isChecked = this.selectedItems[title].some(
            (option) => option === item
          );

          if (isChecked) {
            checkElement?.setAttribute('checked', 'true');
          }
        }
      });
    });
  }

  refresh() {
    this.filterOptions.forEach((item) => {
      this.selectedItems[item.title] = [];
    });
    this.count = 0;
    this.filteredItems = JSON.parse(JSON.stringify(this.filterOptions));
  }

  handleButtonClick() {
    this.isOpen = !this.isOpen;
  }

  onChange(key: string, value: string, event: Event) {
    const ischecked = (<HTMLInputElement>event.target).checked;

    if (ischecked) {
      if (!this.selectedItems[key].includes(value))
        this.selectedItems[key].push(value);

      this.count = Object.values(this.selectedItems)
        .map((x) => x.length)
        .reduce((acc, num) => acc + num, 0);
    } else {
      this.selectedItems[key].splice(this.selectedItems[key].indexOf(value), 1);

      this.count = Object.values(this.selectedItems)
        .map((x) => x.length)
        .reduce((acc, num) => acc + num, 0);
    }

    this.hasSelectedItems = this.hasSelectedItemsCheck();
  }

  handleToggleAccordion(item: FilterItem) {
    item.isOpen = !item.isOpen;
  }

  getFilteredOptions(valueChanged: string | null) {
    if (valueChanged == null) {
      return;
    }

    this.value = valueChanged;

    this.filteredItems.forEach((option, index) => {
      option.items = !this.value
        ? this.filterOptions[index].items
        : this.filterOptions[index].items.filter((option) =>
            this.compareIgnoreCase(option, this.value)
          );

      if (option.items.length > 0 && this.value) {
        option.isOpen = true;
      }
    });
  }

  compareIgnoreCase(value: string, valueCompare: string) {
    return value
      .toLocaleLowerCase()
      .replace(/\s\s+/g, ' ')
      .trim()
      .includes(valueCompare.toLocaleLowerCase().replace(/\s\s+/g, ' ').trim());
  }

  onSearch() {
    this.isSeaching = true;
    this.loading = true;
    this.isOpen = false;
    this.searchEvent.emit(this.selectedItems);
  }

  getValues(): [key: string, value: string][] {
    let values: [string, string][] = [];

    for (const key of Object.keys(this.selectedItems)) {
      for (const value of this.selectedItems[key]) {
        values.push([key, value]);
      }
    }

    return values;
  }

  hasSelectedItemsCheck(): boolean {
    return Object.keys(this.selectedItems).length > 0;
  }

  remove(selectedItem: [string, string]) {
    const checkElement = document.getElementById(
      selectedItem[0] + ':' + selectedItem[1]
    );

    checkElement?.click();
  }

  removeMultiple(selectedItems: [string, string][]) {
    selectedItems.slice(2).forEach((item) => {
      const checkElement = document.getElementById(item[0] + ':' + item[1]);
      checkElement?.click();
    });
  }
}
