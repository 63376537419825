<div
  [ngClass]="disabled ? 'container disabled' : 'container'"
  (mouseover)="onHover = true"
  (mouseout)="onHover = false"
  (click)="!disabled && handleClick()"
>
  <div
    [ngClass]="value ? 'box checked' : 'box'"
    [ngClass]="loading ? 'box checked' : 'box'"
  >
    <div class="img">
      <div *ngIf="loading" class="loading-animation"></div>

      <img
        *ngIf="!loading"
        class="imgItem1"
        src="../../../assets/icons/checkbox-checked-primary-600.svg"
        alt="checked"
        [ngStyle]="{ display: !disabled && value ? 'block' : 'none' }"
      />
      <img
        *ngIf="!loading"
        class="imgItem2"
        src="../../../assets/icons/checkbox-hover-primary-600.svg"
        [ngStyle]="{
          display: !disabled && onHover && !value ? 'block' : 'none'
        }"
      />
      <img
        *ngIf="!loading"
        class="imgItem3"
        src="../../../assets/icons/checkbox-disabled.svg"
        [ngStyle]="{ display: disabled && value ? 'block' : 'none' }"
      />
    </div>
  </div>
  <label
    [ngClass]="bold ? 'text bold' : 'text'"
    [ngStyle]="{ color: labelColor }"
  >
    {{ label }}
  </label>
</div>
