import {
  Component,
  ElementRef,
  Input,
  TemplateRef,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'app-result-tab',
  templateUrl: './result-tab.component.html',
  styleUrls: ['./result-tab.component.scss'],
})
export class ResultTabComponent {
  @Input() title: string = '';
  @ViewChild('content') content!: TemplateRef<ElementRef>;
}
