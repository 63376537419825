<section>
  <app-category-analysis-report-filter
    (filterEmmiter)="setFilter($event)"
  ></app-category-analysis-report-filter>

  <app-category-analysis-report-list
    *ngIf="filter"
    [filter]="filter"
  ></app-category-analysis-report-list>
</section>
