import { Injectable } from '@angular/core';
import { GetContactReasonFilter, GetContactReasonResponse, ContactReasons, PagedContactReasonGetAllResponse, UpdateContactReasonRequest, ContactReasonsDictResponse } from './models/contact-reason.service.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContactReasonService {
  private baseURL: string = `${environment.apiUrl}/api/ContactReason`;

  constructor(private http: HttpClient) {}

  private serializeQueryParams(obj: any): any {
    let queryBuilder = new HttpParams();

    Object.keys(obj).forEach((k) => {
      if (Array.isArray(obj[k])) {
        (obj[k] as []).forEach(
          (value) => (queryBuilder = queryBuilder.append(k, value))
        );
      } else if (obj[k]) {
        queryBuilder = queryBuilder.append(k, obj[k]);
      }
    });

    return queryBuilder;
  }

  create(request: FormData) {
    return this.http.post(this.baseURL, request);
  }

  get(filter: GetContactReasonFilter): Observable<PagedContactReasonGetAllResponse> {
    const params = this.serializeQueryParams(filter);
    return this.http.get<PagedContactReasonGetAllResponse>(this.baseURL, { params });
  }

  getAll(): Observable<GetContactReasonResponse[]> {
    return this.http.get<GetContactReasonResponse[]>(this.baseURL + '/all');
  }

  getMacro(): Observable<ContactReasonsDictResponse> {
    return this.http.get<ContactReasonsDictResponse>(this.baseURL + '/macros');
  }
  
  getById(id: string): Observable<GetContactReasonResponse> {
    return this.http.get<GetContactReasonResponse>(this.baseURL + '/' + id);
  }

  update(id: string, request: FormData) {
    return this.http.put(this.baseURL + '/' + id, request);
  }

  delete(id: string) {
    return this.http.delete(this.baseURL + '/' + id);
  }

  downloadFile(id: string) {
    return this.http.get(this.baseURL + '/' + id + '/File', {
      responseType: 'blob',
    });
  }

  downloadTemplate() {
    return this.http.get(this.baseURL + '/template', {
      responseType: 'blob',
    });
  }
}
