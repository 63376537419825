<div class="tabs">
  <div class="main" *ngIf="activeTab">
    <ng-container *ngTemplateOutlet="activeTab.content"></ng-container>
  </div>
  <footer #footer [ngClass]="{ grabbing: grabbing }">
    <ng-container *ngIf="tabs">
      <h2
        *ngFor="let tab of tabs"
        [ngClass]="{ 'active-tab': tab === activeTab }"
        (click)="selectTab(tab)"
      >
        {{ tab.title }}
      </h2>
    </ng-container>
  </footer>
</div>
