<div *ngIf="showModal" class="modal">
  <section [style.width]="width" [style.maxWidth]="maxWidth">
    <header>
      <strong>{{ title }}</strong>
      <div class="actions">
        <ng-content select="[actions]"></ng-content>
        <app-icon-button
          [icon]="closeIcon"
          [color]="ButtonColorEnum.warning"
          (click)="handleCloseModal()"
        ></app-icon-button>
      </div>
    </header>
    <div class="extra">
      <ng-content select="[extra]"></ng-content>
    </div>
    <main *ngIf="includeMain"
    [style.padding]="padding">
      <ng-content select="[main]"></ng-content>
    </main>
    <footer [style.display]="footerDisplay">
      <ng-content select="[footer]"></ng-content>
    </footer>
  </section>
</div>
