<section
  [ngClass]="{
    error: error,
    disabled: disabled
  }"
>
  <label>{{ label }}</label>
  <main>
    <img src="../../../assets/icons/resize.svg" alt="Resize icon" />
    <textarea [formControl]="control" [placeholder]="placeholder"></textarea>
  </main>
  <footer>
    <div *ngIf="error">
      <img src="../../../assets/icons/error.svg" alt="Error Icon" />
      <span>{{ errorMessage }}</span>
    </div>
  </footer>
</section>
