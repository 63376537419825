import { Component } from '@angular/core';
import { GetTranscriptionsFilter } from './service/models/transcription.service.model';

@Component({
  selector: 'app-transcription',
  templateUrl: './transcription.component.html',
  styleUrls: ['./transcription.component.scss']
})
export class TranscriptionComponent {
  filter!: GetTranscriptionsFilter;

  setFilter(filter: GetTranscriptionsFilter) {
    this.filter = filter;
  }
}
