<article>
  <header (click)="handleToggleAccordion()">
    <strong>Filtro</strong>
    <img
      class="accordion-img"
      [ngClass]="{ 'rotate-animation': !isOpen }"
      src="assets/icons/arrow.svg"
    />
  </header>

  <main [ngClass]="{ 'is-collapsed': !isOpen }">
    <div main class="main">
      <div class="grid-content">
        <app-input
          label="Nome"
          placeholder="Digite o nome para pesquisar"
          [control]="form.controls.name"
          [disabled]="false"
        ></app-input>
        <app-input
          label="Email"
          placeholder="Digite o email para pesquisar"
          [control]="form.controls.email"
          [disabled]="false"
        ></app-input>
        <app-select-multi
          label="Perfil"
          placeholder="Selecione múltiplos perfis"
          [multiControl]="form.controls.profiles"
          [options]="profiles"
          [selected]="selectedProfiles"
          property="Type"
          [disabled]="false"
          [multi]="true"
        ></app-select-multi>
      </div>
      <div class="grid-content">
        <app-input
          label="Funcional"
          placeholder="Digite o código do funcional"
          [control]="form.controls.functional"
          [disabled]="false"
          [mask]="MaskTypeEnum.number"
          [maxlength]="11"
        ></app-input>
        <app-input
          label="ID da Gravação"
          placeholder="Digite o código da gravação"
          [control]="form.controls.recorderId"
          [disabled]="false"
        ></app-input>
        <div class="period">
          <app-input
            label="Data de Criação"
            placeholder="De"
            [control]="form.controls.initialDate"
            [disabled]="false"
            [type]="InputTypeEnum.date"
          ></app-input>
          <app-input
            label=""
            placeholder="Até"
            [control]="form.controls.finalDate"
            [disabled]="false"
            [type]="InputTypeEnum.date"
          ></app-input>
        </div>
      </div>
      <div class="grid-content">
        <app-input
          label="Supervisor"
          placeholder="Digite o nome do supervisor"
          [control]="form.controls.supervisor"
          [disabled]="false"
        ></app-input>
        <app-select-multi
          label="Status"
          placeholder="Selecione múltiplos status"
          [multiControl]="form.controls.status"
          [options]="status"
          [selected]="selectedStatus"
          property="Type"
          [disabled]="false"
          [multi]="true"
        ></app-select-multi>
      </div>
    </div>

    <hr />

    <div footer class="footer">
      <app-secondary-button
        (click)="clear()"
        label="Limpar"
        [color]="ButtonColorEnum.warning"
        [icon]="'assets/icons/trash.svg'"
        [enabled]="true"
        [widthFull]="true"
      >
      </app-secondary-button>
      <app-primary-button
        [label]="'Filtrar'"
        [color]="ButtonColorEnum.common"
        [loading]="isLoading"
        (clickFunc)="search()"
      ></app-primary-button>
    </div>
  </main>
</article>
