<div class="validation-container">
  <div class="validator">
    <img
      *ngIf="validator.length"
      src="../../../../assets/icons/success-primary.svg"
      alt=""
    />
    <img
      *ngIf="!validator.length"
      src="../../../../assets/icons/error.svg"
      alt=""
    />
    <span [ngClass]="{ valid: validator.length }">De 8 a 10 caracteres</span>
  </div>
  <div class="validator">
    <img
      *ngIf="validator.uppercase && validator.lowercase"
      src="../../../../assets/icons/success-primary.svg"
      alt=""
    />
    <img
      *ngIf="!validator.uppercase || !validator.lowercase"
      src="../../../../assets/icons/error.svg"
      alt=""
    />
    <span [ngClass]="{ valid: validator.uppercase && validator.lowercase }">
      Maiúsculas e Minúsculas
    </span>
  </div>
  <div class="validator">
    <img
      *ngIf="validator.special"
      src="../../../../assets/icons/success-primary.svg"
      alt=""
    />
    <img
      *ngIf="!validator.special"
      src="../../../../assets/icons/error.svg"
      alt=""
    />
    <span [ngClass]="{ valid: validator.special }">Caracteres especiais</span>
  </div>
  <div class="validator">
    <img
      *ngIf="validator.digit"
      src="../../../../assets/icons/success-primary.svg"
      alt=""
    />
    <img
      *ngIf="!validator.digit"
      src="../../../../assets/icons/error.svg"
      alt=""
    />
    <span [ngClass]="{ valid: validator.digit }">Números</span>
  </div>
</div>
