<div class="client-item">
    <div class="client-item__column bold">
      <strong>{{ data.name || "-" }}</strong>
    </div>
    <div class="client-item__column">
      <strong>{{ data.cnpj ? formatCNPJ(data.cnpj) : "-" }}</strong>
    </div>
    <div class="client-item__column">
      <strong>{{ data.creationDate | date : "dd/MM/yyyy" }}</strong>
    </div>
    <div class="client-item__column status">
      <strong [ngClass]="{ green: data.active, red: !data.active }">{{
      data.active ? "Ativo" : "Inativo" || "-"
    }}</strong>
    </div>
    <div class="incident-item__column actions" *ngIf="hasSupervisorRole">
      <app-icon-button
        icon="../../../../../assets/icons/edit.svg"
        [color]="ButtonColorEnum.primary"
        (clickFunc)="handleDetails(data.id)"
      ></app-icon-button>
    </div>
  </div>
  