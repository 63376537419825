import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { InputTypeEnum } from 'src/app/shared/input/enums/input-type.enum';
import { DashboardService } from '../service/dashboard.service';
import { DashboardFilterRequest } from '../service/models/dashboard.service.model';
import { PieChartComponent } from 'src/app/shared/pie-chart/pie-chart.component';
import { DatasetPieChart, PieChartParams } from 'src/app/shared/pie-chart/models/pie-chart.model';

@Component({
  selector: 'app-dashboard-tma-x-non-talk-by-segment',
  templateUrl: './dashboard-tma-x-non-talk-by-segment.component.html',
  styleUrls: ['./dashboard-tma-x-non-talk-by-segment.component.scss'],
})
export class DashboardTmaXNonTalkBySegmentComponent {
  constructor(
    public formBuilder: FormBuilder,
    public dashboardService: DashboardService
  ) {}

  @Input() filter!: DashboardFilterRequest;
  @Output() isLoadingEmmiter: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @ViewChild(PieChartComponent) pieChartComponent!: PieChartComponent;

  @Output() tmaInSeconds: EventEmitter<number> = new EventEmitter<number>();

  error: boolean = false;
  isLoading: boolean = false;
  params!: PieChartParams;

  form = this.formBuilder.group({
    initialDate: '',
    finalDate: '',
    rankingOption: '',
  });

  ngOnInit() {
    const currentDate = new Date();
    const firstDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );

    this.form.patchValue({
      initialDate: this.formatDate(firstDayOfMonth),
      finalDate: this.formatDate(currentDate),
      rankingOption: 'TOP 10',
    });

    this.generate();
  }

  ngOnChanges() {
    if (this.filter) {
      this.generate();
    }
  }

  formatDate(date: Date): string {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }

  get ButtonColorEnum() {
    return ButtonColorEnum;
  }

  get InputTypeEnum() {
    return InputTypeEnum;
  }

  clear() {
    this.form.reset();
  }

  generate() {
    if (this.isLoading) return;
  
    this.error = false;
    this.isLoading = true;
    this.isLoadingEmmiter.emit(this.isLoading);
  
    this.dashboardService.getTmaXNonTalkBySegment(this.filter).subscribe({
      next: (data) => {
        const tmaTotal = data
          .flatMap((item) =>
            item.tmaXNonTalkResponseQuantity
              .filter((item) => item.legendName === 'Tma')
              .map((item) => parseFloat(item.quantity) * 60)
          )
          .reduce((a, b) => a + b, 0);
  
        this.tmaInSeconds.emit(tmaTotal);
  
        const tmaValue = data
          .flatMap((item) =>
            item.tmaXNonTalkResponseQuantity
              .filter((item) => item.legendName === 'Tma')
              .map((item) => parseFloat(item.quantity))
          )
          .reduce((a, b) => a + b, 0);
  
        const nonTalkValue = data
          .flatMap((item) =>
            item.tmaXNonTalkResponseQuantity
              .filter((item) => item.legendName === 'Non Talk')
              .map((item) => parseFloat(item.quantity))
          )
          .reduce((a, b) => a + b, 0);
  
        const datasetPie: DatasetPieChart = {
          title: 'TMA x Non Talk',
          values: [tmaValue.toString(), nonTalkValue.toString()],
          backgroundColor: ['rgba(145, 202, 197, 1)', 'rgba(14, 165, 233, 1)'],
          borderColor: undefined,
          borderWidth: 1,
        };
  
        this.params = {
          footerOptions: ['TMA', 'Non Talk'],
          datasets: [datasetPie],
        };
  
        this.createChart();
  
        this.isLoading = false;
        this.isLoadingEmmiter.emit(this.isLoading);
      },
      error: () => {
        this.error = true;
        this.isLoading = false;
        this.isLoadingEmmiter.emit(this.isLoading);
      },
    });
  }

  createChart() {
    this.pieChartComponent.createChart(this.params, 'tmaXNonTalkBySegment');
  }
}
