<div class="container">
  <article>
    <header (click)="handleToggleAccordion()">
      <strong>Filtro</strong>
      <img
        class="accordion-img"
        [ngClass]="{ 'rotate-animation': !isOpen }"
        src="assets/icons/arrow.svg"
      />
    </header>

    <main [ngClass]="{ 'is-collapsed': !isOpen }">
      <div main class="main">
        <div class="grid-content">
          <div class="period">
            <app-input
              label="Data de Atendimento"
              placeholder="De"
              [control]="form.controls.startDate"
              [disabled]="false"
              [type]="InputTypeEnum.date"
              [errorMessage]="'campo obrigatório'"
              [error]="
                !form.controls.startDate.valid &&
                !form.controls.startDate.pristine
              "
            ></app-input>
            <app-input
              label=""
              placeholder="Até"
              [control]="form.controls.endDate"
              [disabled]="false"
              [type]="InputTypeEnum.date"
              [errorMessage]="'campo obrigatório'"
              [error]="
                !form.controls.endDate.valid && !form.controls.endDate.pristine
              "
            ></app-input>
          </div>

          <app-select
            label="Tipo da Nuvem"
            placeholder="Selecione um tema para a nuvem"
            errorMessage="campo obrigatório"
            [options]="cloudThemes"
            [control]="form.controls.cloudType"
            [error]="
              !form.controls.cloudType.valid &&
              !form.controls.cloudType.pristine
            "
            [disabled]="loadingCloudThemes"
            [loading]="loadingCloudThemes"
          ></app-select>

          <app-select
            *ngIf="showOffender"
            label="Ofensor por:"
            placeholder="Selecione um item"
            errorMessage="campo obrigatório"
            [options]="offenderOptions"
            [control]="form.controls.offender"
            [error]="
              !form.controls.offender.valid && !form.controls.offender.pristine
            "
          ></app-select>

          <app-select-multi
            label="Clientes"
            placeholder="Selecione múltiplos clientes"
            [multiControl]="form.controls.clients"
            [options]="clients"
            [selected]="selectedClients"
            property="Type"
            [multi]="true"
            [isSearch]="true"
            [disabled]="loadingClients"
            [loading]="loadingClients"
          ></app-select-multi>

          <app-select-multi
            label="Skills"
            [placeholder]="getSkillsPlaceholder()"
            [multiControl]="form.controls.skills"
            [options]="skills"
            [selected]="selectedSkills"
            property="Type"
            [multi]="true"
            [isSearch]="true"
            [disabled]="loadingSkills"
            [loading]="loadingSkills"
          ></app-select-multi>

          <app-select-multi
            label="Células"
            placeholder="Selecione múltiplas células"
            [multiControl]="form.controls.cells"
            [options]="cells"
            property="Type"
            [multi]="true"
            [isSearch]="true"
            [disabled]="loadingCells"
            [loading]="loadingCells"
          ></app-select-multi>

          <app-select-multi
            label="Sub-células"
            placeholder="Selecione múltiplas sub-células"
            [multiControl]="form.controls.subcells"
            [options]="subCells"
            property="Type"
            [multi]="true"
            [isSearch]="true"
            [loading]="loadingSubcells"
            [disabled]="loadingSubcells"
          ></app-select-multi>

          <app-select-multi
            label="Categorias"
            placeholder="Selecione múltiplas categorias"
            [multiControl]="form.controls.categories"
            [options]="categories"
            [selected]="selectedCategories"
            property="Type"
            [multi]="true"
            [isSearch]="true"
            [disabled]="loadingCategories"
            [loading]="loadingCategories"
          ></app-select-multi>

          <app-select-multi
            label="Atendentes"
            placeholder="Selecione múltiplos atendentes"
            [multiControl]="form.controls.attendants"
            [options]="attendants"
            [selected]="selectedAttendants"
            property="Type"
            [multi]="true"
            [isSearch]="true"
            [disabled]="loadingAttendants"
            [loading]="loadingAttendants"
          ></app-select-multi>

          <app-select-multi
            label="Turnos"
            placeholder="Selecione múltiplos turnos"
            [multiControl]="form.controls.shifts"
            [options]="shifts"
            [selected]="selectedShifts"
            property="Type"
            [multi]="true"
            [disabled]="loadingShifts"
            [loading]="loadingShifts"
          ></app-select-multi>

          <app-select-multi
            label="Tipos"
            placeholder="Selecione múltiplos tipos"
            [multiControl]="form.controls.types"
            [options]="types"
            [selected]="selectedTypes"
            property="Type"
            [disabled]="false"
            [multi]="true"
          ></app-select-multi>
        </div>
      </div>

      <hr />

      <div footer class="footer">
        <app-secondary-button
          (click)="clear()"
          label="Limpar"
          [color]="ButtonColorEnum.warning"
          [icon]="'assets/icons/trash.svg'"
          [enabled]="true"
          [widthFull]="true"
        >
        </app-secondary-button>
        <app-primary-button
          [label]="'Filtrar'"
          [color]="ButtonColorEnum.common"
          [loading]="isLoading"
          (clickFunc)="search()"
          [enabled]="form.valid"
        ></app-primary-button>
      </div>
    </main>
  </article>
</div>
