import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appPartialBackgroundColor]',
})
export class PartialBackgroundColorDirective {
  @Input() color: string = '#ff0000'; // Cor para o fundo parcial
  @Input() percentage: number = 50; // Percentual da largura a ser colorida

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {
    const gradient = `linear-gradient(to right, ${this.color} ${this.percentage}%, transparent ${this.percentage}%)`;
    this.renderer.setStyle(this.el.nativeElement, 'background', gradient);
    this.renderer.setStyle(
      this.el.nativeElement,
      'backgroundSize',
      '100% 100%'
    );
    this.renderer.setStyle(
      this.el.nativeElement,
      'backgroundRepeat',
      'no-repeat'
    );
  }
}
