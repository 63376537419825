import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { BarChartComponent } from 'src/app/shared/bar-chart/bar-chart.component';
import {
  BarChartParams,
  DatasetBarChart,
} from 'src/app/shared/bar-chart/models/bar-chart.model';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { InputTypeEnum } from 'src/app/shared/input/enums/input-type.enum';
import { DashboardService } from '../service/dashboard.service';
import { DashboardFilterRequest } from '../service/models/dashboard.service.model';

@Component({
  selector: 'app-dashboard-mentioned-keywords-ranking',
  templateUrl: './dashboard-mentioned-keywords-ranking.component.html',
  styleUrls: ['./dashboard-mentioned-keywords-ranking.component.scss'],
})
export class DashboardMentionedKeywordsRankingComponent {
  constructor(
    public formBuilder: FormBuilder,
    public dashboardService: DashboardService
  ) {}

  @Input() filter!: DashboardFilterRequest;
  @Output() totalMentions: EventEmitter<number> = new EventEmitter<number>();
  @Output() isLoadingEmmiter: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @ViewChild(BarChartComponent) barChartComponent!: BarChartComponent;

  error: boolean = false;
  isLoading: boolean = false;
  rankingOptions: string[] = ['TOP 3', 'TOP 10', 'TOP 20'];
  params!: BarChartParams;

  form = this.formBuilder.group({
    initialDate: '',
    finalDate: '',
    rankingOption: '',
  });

  ngOnInit() {
    const currentDate = new Date();
    const firstDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );

    this.form.patchValue({
      initialDate: this.formatDate(firstDayOfMonth),
      finalDate: this.formatDate(currentDate),
      rankingOption: 'TOP 10',
    });

    this.generate();
  }

  ngOnChanges() {
    if (this.filter) {
      this.generate();
    }
  }

  formatDate(date: Date): string {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }

  get ButtonColorEnum() {
    return ButtonColorEnum;
  }

  get InputTypeEnum() {
    return InputTypeEnum;
  }

  clear() {
    this.form.reset();
  }

  generate() {
    if (this.isLoading) return;

    this.error = false;
    this.isLoading = true;
    this.isLoadingEmmiter.emit(this.isLoading);

    this.dashboardService.getTopMentionedKeywords(this.filter).subscribe({
      next: (data) => {
        var footerOptions = data.map((i) => i.keyword);
        var values = data.map((i) => i.quantity.toString());

        this.totalMentions.emit(data.length);

        var datasets: DatasetBarChart[] = [];

        const dataset: DatasetBarChart = {
          title: '',
          values: values,
          backgroundColor: 'rgba(145, 202, 197, 1)',
          borderColor: 'rgba(15, 114, 105, 1)',
          borderWidth: 3.5,
          borderRadius: 5,
          barPercentage: 0.3,
          type: undefined,
          hasBorderDash: false,
        };

        datasets.push(dataset);

        this.params = {
          type: 'bar',
          footerOptions: footerOptions,
          datasets: datasets,
          displayLegends: false,
          isStacked: false,
          showDatalabel: false,
        };

        this.createChart();

        this.isLoading = false;
        this.isLoadingEmmiter.emit(this.isLoading);
      },
      error: () => {
        this.error = true;
        this.isLoading = false;
        this.isLoadingEmmiter.emit(this.isLoading);
      },
    });
  }

  createChart() {
    this.barChartComponent.createChart(this.params, 'topMentionedKeywords');
  }
}
