import { Component, Input, ViewChild } from '@angular/core';
import {
  DatasetPieChart,
  PieChartParams,
} from 'src/app/shared/pie-chart/models/pie-chart.model';
import { PieChartComponent } from 'src/app/shared/pie-chart/pie-chart.component';
import { ResolutionDashboard } from '../../resoluteness-report/resoluteness-report.model';
import { ResolutenessReportService } from '../../resoluteness-report/resoluteness-report.service';
import { DashboardFilterRequest } from '../service/models/dashboard.service.model';

@Component({
  selector: 'app-dashboard-resolution-chart',
  templateUrl: './dashboard-resolution-chart.component.html',
  styleUrls: ['./dashboard-resolution-chart.component.scss'],
})
export class DashboardResolutionChartComponent {
  constructor(private resolutenessService: ResolutenessReportService) {}

  @Input() filter!: DashboardFilterRequest;

  @ViewChild(PieChartComponent) pieChartComponent!: PieChartComponent;

  data: ResolutionDashboard = {} as ResolutionDashboard;
  loading: boolean = false;
  error: boolean = false;
  params!: PieChartParams;

  ngOnChanges() {
    this.getDashboardData();
  }

  generate() {
    const datasetPie: DatasetPieChart = {
      values: [
        this.data.resolved.toString(),
        this.data.unresolved.toString(),
        this.data.noEvaluation.toString(),
      ],
      backgroundColor: [
        'rgba(22, 133, 123, 1)',
        'rgba(239, 68, 68, 1)',
        'rgba(148, 163, 184, 1)',
      ],
      borderColor: undefined,
      borderWidth: 1,
    };

    this.params = {
      footerOptions: ['Resolvidos', 'Não Resolvidos', 'Sem Avaliação'],
      datasets: [datasetPie],
    };

    this.createChart();
  }

  createChart() {
    this.pieChartComponent.createChart(this.params, 'resolutionChart');
  }

  getDashboardData() {
    this.loading = true;
    this.error = false;

    this.resolutenessService.getDashboardData(this.filter).subscribe({
      next: (data) => {
        this.data = data;
        this.generate();
        this.loading = false;
      },
      error: () => {
        this.loading = false;
        this.error = true;
      },
    });
  }
}
