<div class="container" >
  <article>
    <header>
      <strong>Nuvem de Palavras</strong>
    </header>

    <main>
      <div main class="main">
        <div>
          <div *ngIf="loading" class="loading">
            <div class="loading-animation"></div>
          </div>
          <div *ngIf="noContent" class="no-content">
            {{noContentValue}}
          </div>
          <angular-tag-cloud
            *ngIf="!loading && !noContent"
            [data]="data"
            [width]="options.width"
            [height]="options.height"
            [overflow]="options.overflow"
            [zoomOnHover]="zoomOnHoverOptions"
          >
          </angular-tag-cloud>
        </div>
      </div>
    </main>
  </article>
</div>
