<div class="container">
  <article>
    <header>
      <strong>Visualizar Categoria</strong>
    </header>
    <main>
      <div main class="main">
        <div *ngIf="loadingCategory" class="loading-animation"></div>

        <div
          *ngIf="!loadingCategory && !loadingCategoryError"
          class="grid-content"
        >
          <div class="grid">
            <app-input
              label="Nome da Categoria"
              placeholder="Informe o nome da nova categoria"
              errorMessage="campo obrigatório"
              [control]="form.controls.name"
              [error]="
                !form.controls.name.valid && !form.controls.name.pristine
              "
              [disabled]="true"
            ></app-input>

            <app-select
              label="Status"
              placeholder="Selecione um status"
              errorMessage="campo obrigatório"
              [options]="activeOptions"
              [control]="form.controls.active"
              [disabled]="true"
            ></app-select>

            <app-select
              label="Cliente"
              [disabled]="false"
              [control]="form.controls.client"
              [disabled]="true"
            ></app-select>

            <app-input
              label="Cor"
              [control]="form.controls.color"
              errorMessage="campo obrigatório"
              [error]="
                !form.controls.color.valid && !form.controls.color.pristine
              "
              [type]="InputTypeEnum.color"
              [disabled]="true"
            ></app-input>
          </div>

          <p>Palavras-Chave</p>
          <div class="flex">
            <div class="word-textarea">
              <ng-container
                *ngFor="let keyword of visibleKeywords(); let i = index"
              >
                <div
                  class="keyword tooltip"
                  [ngClass]="{
                    'selected-keyword': keyword == selectedKeyword?.name
                  }"
                >
                  <span
                    class="keyword-text"
                    [ngClass]="{ 'show-all': showAllKeywords }"
                    (click)="setSelectedKeyword(keyword)"
                  >
                    {{ keyword }}
                  </span>

                  <span class="tooltiptext">{{ keyword }}</span>
                </div>
              </ng-container>
              <button
                class="see-all-btn"
                *ngIf="showSeeAllKeywordsButton()"
                (click)="toggleShowAllKeywords()"
              >
                Ver Tudo ({{ keywords.length }})
              </button>
            </div>
          </div>

          <p *ngIf="selectedKeyword">Variações</p>
          <div *ngIf="selectedKeyword" class="flex">
            <div class="word-textarea">
              <ng-container
                *ngFor="let variation of visibleVariations(); let i = index"
              >
                <div class="keyword-variation tooltip">
                  <span
                    class="keyword-variation-text"
                    [ngClass]="{ 'show-all': showAllVariations }"
                  >
                    {{ variation.name }}
                  </span>

                  <span class="tooltiptext">{{ variation.name }}</span>
                </div>
              </ng-container>
              <button
                class="see-all-btn"
                *ngIf="showSeeAllVariationsButton()"
                (click)="toggleShowAllVariations()"
              >
                Ver Tudo ({{ selectedKeyword.keywordVariations.length }})
              </button>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div footer class="footer">
        <app-secondary-button
          label="Voltar"
          [color]="ButtonColorEnum.primary"
          (clickFunc)="closeModal()"
        ></app-secondary-button>
      </div>
    </main>
  </article>
</div>

<app-modal-response
  [showModal]="showModalResponse"
  title="Não foi possível carregar a categoria"
  (closeModalFunc)="closeModal()"
  icon="assets/icons/error.svg"
>
  <app-secondary-button
    footer
    label="Voltar"
    [right]="false"
    [color]="ButtonColorEnum.primary"
    (clickFunc)="closeModal()"
  ></app-secondary-button>

  <app-primary-button
    footer
    *ngIf="!error"
    class="btn-ok"
    label="Ok"
    [right]="false"
    [color]="ButtonColorEnum.common"
    (clickFunc)="closeModal()"
  ></app-primary-button>
</app-modal-response>
