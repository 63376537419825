import { Component } from '@angular/core';

@Component({
  selector: 'app-incidents-import',
  templateUrl: './incidents-import.component.html',
  styleUrls: ['./incidents-import.component.scss']
})
export class IncidentsImportComponent {

}
