import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss']
})
export class TextAreaComponent {
  @Input() control = new FormControl('');
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() errorMessage: string = '';
  @Input() error: boolean = false;
  @Input() disabled: boolean = false;
  @Input() options: any[] | undefined;
  @Output() selectionChange = new EventEmitter<any>();

  onValueChange(event: any) {
    this.selectionChange.emit(event.target.value);
  }
}
