import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { DashboardFilterRequest } from '../service/models/dashboard.service.model';
import { BarChartComponent } from 'src/app/shared/bar-chart/bar-chart.component';
import {
  BarChartParams,
  DatasetBarChart,
} from 'src/app/shared/bar-chart/models/bar-chart.model';
import { FormBuilder } from '@angular/forms';
import { DashboardService } from '../service/dashboard.service';

@Component({
  selector: 'app-dashboard-shifts',
  templateUrl: './dashboard-shifts.component.html',
  styleUrls: ['./dashboard-shifts.component.scss'],
})
export class DashboardShiftsComponent implements OnInit, OnChanges {
  @Input() filter!: DashboardFilterRequest;
  @Output() isLoadingEmmiter: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @ViewChild(BarChartComponent) barChartComponent!: BarChartComponent;

  constructor(
    public formBuilder: FormBuilder,
    public dashboardService: DashboardService
  ) {}

  error: boolean = false;
  isLoading: boolean = false;
  params!: BarChartParams;

  colorPalette = [
    {
      backgroundColor: 'rgba(255, 99, 132, 0.4)',
      borderColor: 'rgb(255, 99, 132)',
    },
    {
      backgroundColor: 'rgba(54, 162, 235, 0.4)',
      borderColor: 'rgb(54, 162, 235)',
    },
    {
      backgroundColor: 'rgba(75, 192, 192, 0.4)',
      borderColor: 'rgb(75, 192, 192)',
    },
    {
      backgroundColor: 'rgba(255, 206, 86, 0.4)',
      borderColor: 'rgb(255, 206, 86)',
    },
    {
      backgroundColor: 'rgba(153, 102, 255, 0.4)',
      borderColor: 'rgb(153, 102, 255)',
    },
    {
      backgroundColor: 'rgba(255, 159, 64, 0.4)',
      borderColor: 'rgb(255, 159, 64)',
    },
  ];

  ngOnInit(): void {
    this.generate();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.filter) {
      this.generate();
    }
  }

  getColorFromPalette(index: number) {
    return this.colorPalette[index % this.colorPalette.length];
  }

  generate() {
    if (this.isLoading) return;

    this.error = false;
    this.isLoading = true;
    this.isLoadingEmmiter.emit(this.isLoading);

    this.dashboardService.getIncidentShifts(this.filter).subscribe({
      next: (data) => {
        var footerOptions = data.map((i) => i.date);
        var datasets: DatasetBarChart[] = [];

        data.forEach((incident, incidentIndex) => {
          incident.shifts.forEach((shift, shiftIndex) => {
            let dataset = datasets.find((d) => d.title === shift.name);

            if (!dataset) {
              const colors = this.getColorFromPalette(shiftIndex);
              dataset = {
                title: shift.name,
                values: [],
                backgroundColor: colors.backgroundColor,
                pointBackgroundColor: colors.borderColor,
                datalabelsBackgroundColor: colors.borderColor,
                datalabelsFontSize: 9,
                borderColor: colors.borderColor,
                borderWidth: 2,
                borderRadius: 1,
                type: 'line',
                datalabelsAlign: 'top',
                fill: true,
              };
              datasets.push(dataset);
            }

            dataset.values.push(shift.quantity.toString());
          });
        });

        this.params = {
          type: 'line',
          footerOptions: footerOptions,
          datasets: datasets,
          displayLegends: true,
          isStacked: false,
          showDatalabel: true,
        };

        this.createChart();

        this.isLoading = false;
        this.isLoadingEmmiter.emit(this.isLoading);
      },
      error: () => {
        this.error = true;
        this.isLoading = false;
        this.isLoadingEmmiter.emit(this.isLoading);
      },
    });
  }

  createChart() {
    this.barChartComponent.createChart(this.params, 'shifts');
  }
}
