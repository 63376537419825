<nav>
  <div class="options">
    <div class="menu-container" (clickOutside)="close()">
      <img
        src="assets/icons/white_menu.svg"
        (click)="toggleSubmenu()"
        width="23px"
        height="23px"
      />
      <div class="submenu" *ngIf="showSubmenu">
        <button
          class="submenu-button"
          (click)="handleNavigateToTranscription()"
        >
          <img src="assets/icons/transcription.svg" class="icon" />
          <span>Atendimentos</span>
        </button>

        <button class="submenu-button" (click)="handleNavigateToDashboard()">
          <img src="assets/icons/dashboard.svg" class="icon" />
          <span>Dashboards</span>
        </button>

        <div class="line">
          <hr />
        </div>

        <div class="submenu-title" (click)="toggleGestao()">
          <span>Gestão</span>
          <img
            src="assets/icons/multi-select-arrow.svg"
            [ngClass]="showGestao ? 'active icon' : 'icon'"
          />
        </div>

        <div class="submenu-dropdown" *ngIf="showGestao">
          <button class="submenu-button" (click)="handleNavigateToCategory()">
            <img src="assets/icons/category.svg" class="icon" />
            <span>Categorias</span>
          </button>
          <button class="submenu-button" (click)="handleNavigateToClient()">
            <img src="assets/icons/client.svg" class="icon" />
            <span>Clientes</span>
          </button>
          <button class="submenu-button" (click)="handleNavigateToUser()">
            <img src="assets/icons/user.svg" class="icon" />
            <span>Usuários</span>
          </button>
        </div>

        <div class="line">
          <hr />
        </div>

        <div class="submenu-title" (click)="toggleRelatorio()">
          <span>Relatórios</span>
          <img
            src="assets/icons/multi-select-arrow.svg"
            [ngClass]="showRelatorio ? 'active icon' : 'icon'"
          />
        </div>

        <div class="submenu-dropdown" *ngIf="showRelatorio">
          <button
            class="submenu-button"
            (click)="handleNavigateToCategoryAnalysisReport()"
          >
            <img src="assets/icons/categories.svg" class="icon" />
            <span>Categorias</span>
          </button>

          <button
            class="submenu-button"
            (click)="handleNavigateToKeywordsCloud()"
          >
            <img src="assets/icons/cloud.svg" class="icon" />
            <span>Nuvem de Palavras</span>
          </button>

          <button class="submenu-button" (click)="handleNavigateToSaReport()">
            <img src="assets/icons/analysis.svg" class="icon" />
            <span>Sentimento</span>
          </button>
          <button
            class="submenu-button"
            (click)="handleNavigateToResolutenessReport()"
          >
            <img src="assets/icons/file-check.svg" class="icon" />
            <span>Resolutividade</span>
          </button>
          <button
            *ngIf="hasSupervisorRole"
            class="submenu-button"
            (click)="handleNavigateToAnalyticalReportReport()"
          >
            <img src="assets/icons/file.svg" class="icon" />
            <span>Analítico</span>
          </button>
        </div>

        <div class="line">
          <hr />
        </div>

        <div class="submenu-title" (click)="toggleImports()">
          <span>Importações</span>
          <img
            src="assets/icons/multi-select-arrow.svg"
            [ngClass]="showImports ? 'active icon' : 'icon'"
          />
        </div>

        <div class="submenu-dropdown" *ngIf="showImports">
          <button
            class="submenu-button"
            (click)="handleNavigateToIncidentsImport()"
          >
            <img src="assets/icons/transcription.svg" class="icon" />
            <span>Atendimentos</span>
          </button>

          <button
            *ngIf="hasSupervisorRole"
            class="submenu-button"
            (click)="handleNavigateToContactReason()"
          >
            <img src="assets/icons/contact-reason.svg" class="icon" />
            <span>Motivo de Contato</span>
          </button>
        </div>
      </div>
    </div>
    <img [src]="'assets/images/necxt.png'" (click)="handleNavigateToHome()" />
    <span class="separator">|</span>
    <p class="analytics">Analytics</p>
  </div>
  <div class="options" *ngIf="isUserLogged">
    <div class="user">
      <div class="avatar">
        <span>{{ initials }}</span>
      </div>
      <div class="user-login">
        <strong>{{ username }}</strong>
        <p>{{ role }}</p>
      </div>
    </div>
    <app-icon-button
      icon="assets/icons/logout.svg"
      [color]="ButtonColorEnum.white"
      (clickFunc)="handleLogout()"
      [height]="'1.3rem'"
      [width]="'1.3rem'"
    ></app-icon-button>
  </div>
</nav>
