import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { InputTypeEnum } from 'src/app/shared/input/enums/input-type.enum';
import { GetCategoriesFilter } from '../service/models/category.service.model';
import { StatusEnum } from 'src/app/common/utils/status-enum.models';
import {
  GetClientsFilter,
  GetClientsResponse,
} from '../../client/models/client.model';
import { ClientService } from '../../client/service/client.service';

@Component({
  selector: 'app-category-filter',
  templateUrl: './category-filter.component.html',
  styleUrls: ['./category-filter.component.scss'],
})
export class CategoryFilterComponent {
  constructor(
    public formBuilder: FormBuilder,
    public clientService: ClientService
  ) {}

  @Output() filterEmmiter: EventEmitter<GetCategoriesFilter> =
    new EventEmitter<GetCategoriesFilter>();

  form = this.formBuilder.group({
    name: '',
    status: [[]],
    initialDate: [''],
    finalDate: [''],
    clients: [[]],
  });

  isOpen: boolean = false;
  status: string[] = [];
  selectedStatus: string[] = [];
  isLoading: boolean = false;

  clientOptions: string[] = [];
  originalClients: GetClientsResponse[] = [];
  selectedClients: string[] = [];
  loadingClients: boolean = false;

  get ButtonColorEnum() {
    return ButtonColorEnum;
  }

  get InputTypeEnum() {
    return InputTypeEnum;
  }

  ngOnInit() {
    this.populateStatus();
    this.populateClients();
  }

  populateStatus() {
    this.status = [StatusEnum.ATIVO, StatusEnum.INATIVO, StatusEnum.EXCLUIDO];
  }

  handleToggleAccordion() {
    this.isOpen = !this.isOpen;
  }

  clear() {
    this.form.reset();
    this.selectedStatus = [];
    this.selectedClients = [];
  }

  populateClients() {
    this.loadingClients = true;
    this.clientOptions = [];

    this.clientService
      .get({ status: [StatusEnum.ATIVO] } as GetClientsFilter)
      .subscribe({
        next: (data) => {
          this.originalClients = data.items;
          this.clientOptions = data.items.map((i) => i.cnpj + ' | ' + i.name);
          this.loadingClients = false;
        },
      });
  }

  getClientIdsFromArray(array: string[]): string[] | null {
    if (!array || array.length === 0) return null;

    var clientIds: string[] = [];
    array.forEach((client) => {
      var [cnpj, name] = client.split('|').map((i) => i.trim());
      var objClient = this.originalClients.find((i) => i.cnpj === cnpj);
      if (objClient && objClient.id) clientIds.push(objClient.id);
    });

    return clientIds;
  }

  search() {

    var clientIds = this.getClientIdsFromArray(
      this.form.controls.clients.value ?? []
    );

    let filter: GetCategoriesFilter = {} as GetCategoriesFilter;

    filter.name = this.form.controls.name.value ?? '';
    filter.status = this.form.controls.status.value ?? null;
    filter.startDate = this.form.controls.initialDate.value ?? '';
    filter.endDate = this.form.controls.finalDate.value ?? '';
    filter.clientIds = clientIds;

    this.filterEmmiter.emit(filter);

    this.isOpen = false;
  }
}
