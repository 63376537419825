import { Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { SkillService } from 'src/app/common/services/skill/skill.service';
import { StatusEnum } from 'src/app/common/utils/status-enum.models';
import { ButtonColorEnum } from 'src/app/shared/buttons/enums/button-color.enum';
import { InputTypeEnum } from 'src/app/shared/input/enums/input-type.enum';
import {
  GetClientsFilter,
  GetClientsResponse,
  GetSkillRequest,
  GetSkillResponse,
} from '../../client/models/client.model';
import { ClientService } from '../../client/service/client.service';
import { TranscriptionService } from '../../transcription/service/transcription.service';
import {
  GetUserResponse,
  GetUsersFilter,
} from '../../user/service/models/user.service.model';
import { UserService } from '../../user/service/user.service';
import { DashboardFilterRequest } from '../service/models/dashboard.service.model';
import { ContactReasonService } from '../../contact-reason/service/contact-reason.service';
import { ContactReasons, ContactReasonsDictResponse } from '../../contact-reason/service/models/contact-reason.service.model';
import { SelectOption } from 'src/app/shared/select-alt/model/select-option.model';

@Component({
  selector: 'app-dashboard-filter',
  templateUrl: './dashboard-filter.component.html',
  styleUrls: ['./dashboard-filter.component.scss'],
})
export class DashboardFilterComponent {
  constructor(
    public formBuilder: FormBuilder,
    public userService: UserService,
    public clientService: ClientService,
    public transcriptionService: TranscriptionService,
    public skillService: SkillService,
    public contactReasonService: ContactReasonService,
    private toastrService: ToastrService
  ) {}

  @Output() filterEmmiter: EventEmitter<DashboardFilterRequest> =
    new EventEmitter<DashboardFilterRequest>();

  isLoading: boolean = false;
  error: boolean = false;

  clientOptions: string[] = [];
  originalClients: GetClientsResponse[] = [];
  selectedClients: string[] = [];

  skills: string[] = [];
  selectedSkills: string[] = [];
  originalSkills: GetSkillResponse[] = [];

  cells: string[] = [];
  selectedCells: string[] = [];

  subCells: string[] = [];
  selectedSubCells: string[] = [];

  supervisors: string[] = [];
  selectedSupervisors: string[] = [];
  originalSupervisors: GetUserResponse[] = [];

  attendants: string[] = [];
  selectedAttendants: string[] = [];
  originalAttendants: GetUserResponse[] = [];

  shifts: string[] = ['Manhã', 'Tarde', 'Noite', 'Madrugada'];
  selectedShifts: string[] = [];
  loadingShifts = false;

  types: string[] = ['Áudio', 'Chat'];
  selectedTypes: string[] = [];

  loadingContactReasons = false;
  macroContactReasonsOptions: string[] = [];
  selectedMacroContactReason: string[] = [];
  originalContactReasons: ContactReasons[] = [];

  microContactReasonsOptions: string[] = [];
  selectedMicroContactReason: string[] = [];

  showModalResponse: boolean = false;
  titleModalResponse: string = '';
  iconButtonModalResponse: string = '';
  message: string = '';

  form = this.formBuilder.group({
    initialDate: '',
    finalDate: '',
    supervisor: '',
    attendants: [[]],
    clients: [[]],
    skills: [[]],
    cells: [[]],
    subCells: [[]],
    shifts: [['']],
    types: [['']],
    macroContactReason: [[] as string[]], 
    microContactReason: [[] as string[]], 
  });

  ButtonColorEnum = ButtonColorEnum;
  InputTypeEnum = InputTypeEnum;

  ngOnInit() {
    const currentDate = new Date();
    const firstDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      1
    );

    this.form.patchValue({
      initialDate: this.formatDate(firstDayOfMonth),
      finalDate: this.formatDate(currentDate),
    });

    this.form.controls.macroContactReason.valueChanges.subscribe(
      (value: string[] | null) => {
        if (value && value.length > 0 && value[0] !== '') {
          var macroNames = value.map((i) => i.trim());

          var macro = this.originalContactReasons.filter((macro) =>
            macroNames.includes(macro.name)
          );

          const filteredMicro = macro
            .map((macro) => macro.micros.map((micro) => micro))
            .flat();

          this.microContactReasonsOptions = filteredMicro;

          this.selectedMicroContactReason = [];
          this.form.controls.microContactReason.reset();
        } else {
          this.microContactReasonsOptions = this.originalContactReasons.flatMap((item) =>
            item.micros.map(
              (subItem) => subItem.trim()
            )
          );
          this.selectedMicroContactReason = [];
          this.form.controls.microContactReason.reset();
        }

        if (
          !this.form.controls.macroContactReason.value ||
          this.form.controls.macroContactReason.value.length === 0 ||
          (this.form.controls.macroContactReason.value.length === 1 &&
            this.form.controls.macroContactReason.value[0] === '')
        ) {
          this.microContactReasonsOptions = this.originalContactReasons.flatMap((item) =>
            item.micros.map(
              (subItem) => subItem.trim()
            )
          );
          this.selectedMicroContactReason = [];
          this.form.controls.microContactReason.reset();
        }
      }
    );

    this.populateSupervisors();
    this.populateAttendants();
    this.populateClients();
    this.populateSkills();
    this.populateCells();
    this.populateSubCells();
    this.getContactReasons();

    this.filter();
  }

  loadingSupervisors: boolean = false;

  populateSupervisors() {
    this.supervisors = [];

    this.loadingSupervisors = true;

    this.loadingAttendants = true;

    this.userService
      .get({
        profiles: ['SUPERVISOR'],
        status: [StatusEnum.ATIVO],
      } as GetUsersFilter)
      .subscribe({
        next: (data) => {
          this.originalSupervisors = data.items;
          this.supervisors = data.items.map(
            (supervisor) => supervisor.name + ' | ' + supervisor.functional
          );
          this.loadingSupervisors = false;
        },
        error: () => {
          this.loadingSupervisors = false;
          this.toastrService.error(
            'Não foi possível carregar os supervisores.'
          );
        },
      });
  }

  loadingAttendants: boolean = false;

  populateAttendants() {
    this.attendants = [];

    this.loadingAttendants = true;

    this.userService
      .get({
        profiles: ['ATENDENTE'],
        status: [StatusEnum.ATIVO],
      } as GetUsersFilter)
      .subscribe({
        next: (data) => {
          this.originalAttendants = data.items;
          this.attendants = data.items.map(
            (attendant) => attendant.name + ' | ' + attendant.functional
          );
          this.loadingAttendants = false;
        },
        error: () => {
          this.loadingAttendants = false;
          this.toastrService.error('Não foi possível carregar os atendentes.');
        },
      });
  }

  loadingClients: boolean = false;

  populateClients() {
    this.clientOptions = [];
    this.loadingClients = true;

    this.clientService
      .get({ status: [StatusEnum.ATIVO] } as GetClientsFilter)
      .subscribe({
        next: (data) => {
          this.originalClients = data.items;
          this.clientOptions = data.items.map((i) => i.cnpj + ' | ' + i.name);
          this.loadingClients = false;
        },
        error: () => {
          this.loadingClients = false;
          this.toastrService.error('Não foi possível carregar os clientes.');
        },
      });
  }

  getFilteredShiftsValue(text: string[] | null): string[] | null {
    if (!text || text.length == 0 || (text.length == 1 && text[0] == ''))
      return null;
    return this.form.controls.shifts.value;
  }

  loadingSkills: boolean = false;

  populateSkills() {
    this.loadingSkills = true;

    this.skillService.get({ active: true } as GetSkillRequest).subscribe({
      next: (data) => {
        this.originalSkills = data;

        this.skills = data.map(
          (i) => i.code + ': ' + i.cell + ' — ' + i.subCell
        );

        this.loadingSkills = false;
      },
      error: () => {
        this.toastrService.error('Não foi possível carregar as skills.');
        this.loadingSkills = false;
      },
    });
  }

  loadingCells: boolean = false;

  populateCells() {
    this.loadingCells = true;

    this.skillService.getCells().subscribe({
      next: (data) => {
        this.cells = data;
        this.loadingCells = false;
      },
      error: () => {
        this.toastrService.error('Não foi possível carregar as células.');
        this.loadingCells = false;
      },
    });
  }

  loadingSubcells: boolean = false;

  populateSubCells() {
    this.loadingSubcells = true;

    this.skillService.getSubCells().subscribe({
      next: (data) => {
        this.subCells = data;
        this.loadingSubcells = false;
      },
      error: () => {
        this.toastrService.error('Não foi possível carregar as sub-células.');
        this.loadingSubcells = false;
      },
    });
  }

  getContactReasons() {
    this.loadingContactReasons = true;

    this.contactReasonService.getMacro().subscribe({
      next: (contactReasons) => {
        if (contactReasons) {
          this.originalContactReasons =
            this.transformToMacroContactReasons(contactReasons);

          this.macroContactReasonsOptions = this.originalContactReasons.map(
            (item) => item.name.trim()
          );

          this.microContactReasonsOptions = this.originalContactReasons.flatMap((item) =>
            item.micros.map(
              (subItem) => subItem.trim()
            )
          );
        }

        this.loadingContactReasons = false;
      },
      error: (e) => {
        console.error(e);
        this.loadingContactReasons = false;
      },
    });
  }

  transformToMacroContactReasons(
    contactReasons: ContactReasonsDictResponse
  ): ContactReasons[] {
    return Object.keys(contactReasons).map((key) => {
      return {
        name: key,
        micros: contactReasons[key],
      };
    });
  }

  formatDate(date: Date): string {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  }

  clear() {
    this.form.reset();
    this.selectedClients = [];
    this.selectedAttendants = [];
    this.selectedSkills = [];
    this.selectedCells = [];
    this.selectedSubCells = [];
    this.selectedSupervisors = [];
    this.selectedShifts = [];
    this.selectedTypes = [];
    this.selectedMacroContactReason = [];
    this.selectedMicroContactReason = [];
  }

  getClientIdsFromArray(array: string[]): string[] | null {
    if (!array || array.length === 0) return null;

    var clientIds: string[] = [];
    array.forEach((client) => {
      var [cnpj, name] = client.split('|').map((i) => i.trim());
      var objClient = this.originalClients.find((i) => i.cnpj === cnpj);
      if (objClient && objClient.id) clientIds.push(objClient.id);
    });

    return clientIds;
  }

  handleCloseModalResponse() {
    this.showModalResponse = false;
  }

  filter() {
    var clientIds = this.getClientIdsFromArray(
      this.form.controls.clients.value ?? []
    );
    var selectedClients: string[] = this.form.controls.clients.value ?? [];

    if (selectedClients.length > 0) {
      if (
        clientIds === null ||
        clientIds.length === 0 ||
        clientIds.length !== selectedClients.length
      ) {
        this.isLoading = false;
        this.error = true;
        this.titleModalResponse = 'Ocorreu um erro ao filtrar dashboards';
        this.iconButtonModalResponse = 'assets/icons/error.svg';
        this.message = 'não foi possível recuperar os clientes selecionados.';
        this.showModalResponse = true;
        return;
      }
    }

    var shifts = this.getFilteredShiftsValue(this.form.controls.shifts.value);

    var types: number[] | null;

    if (
      !this.form.controls.types ||
      !this.form.controls.types.value ||
      (this.form.controls.types.value.length == 1 &&
        this.form.controls.types.value[0].trim() == '')
    )
      types = null;
    else {
      types =
        this.form.controls.types.value?.map((i) => {
          return this.types.indexOf(i);
        }) ?? null;
    }
    var filters = {
      startDate: this.form.controls.initialDate.value ?? null,
      endDate: this.form.controls.finalDate.value ?? null,
      clients: clientIds,
      cells: this.form.controls.cells.value ?? null,
      supervisor:
        this.getSupervisorIdFromText(
          this.form.controls.supervisor.value || ''
        ) ?? null,
      attendants:
        this.getAttendantsIdFromText(
          this.form.controls.attendants.value || []
        ) ?? null,
      subCells: this.form.controls.subCells.value ?? null,
      shifts: shifts,
      types: types,
      skills: this.getSkillIdFromText(this.form.controls.skills.value || []),
      macroContactReasons: this.form.controls.macroContactReason.value ?? null,
      microContactReasons: this.form.controls.microContactReason.value ?? null,
      topQuantity: null,
    } as DashboardFilterRequest;
    
    this.filterEmmiter.emit(filters);
  }

  getAttendantsIdFromText(text: string[]): string[] {
    let attendantIds: string[] = [];

    text.forEach((attendantText) => {
      let attendantId = this.getAttendantIdFromText(attendantText);
      if (attendantId) attendantIds.push(attendantId);
    });

    return attendantIds;
  }

  getAttendantIdFromText(text: string): string | null {
    if (!text || text.trim() === '') return null;

    var [name, functionalString] = text.split('|').map((part) => part.trim());
    var attendant = this.originalAttendants.find(
      (attendant) =>
        attendant.functional.toString() === functionalString &&
        attendant.name.trim() === name.trim()
    );

    return attendant ? attendant.id : null;
  }

  getSupervisorIdFromText(text: string): string | null {
    if (!text || text.trim() === '') return null;

    var [name, functionalString] = text.split('|').map((part) => part.trim());
    var supervisor = this.originalSupervisors.find(
      (attendant) =>
        attendant.functional.toString() === functionalString &&
        attendant.name.trim() === name.trim()
    );

    return supervisor ? supervisor.id : null;
  }

  getSkillIdFromText(text: string[]): string[] | null {
    if (!text || text.length === 0) return null;

    var skills: string[] = [];

    for (let i = 0; i < text.length; i++) {
      if (!text[i] || text[i].trim() === '') return null;

      var [code, cellAndSubCell] = text[i].split(':').map((i) => i.trim());
      var [cell, subCell] = cellAndSubCell.split('—').map((i) => i.trim());

      var skillFiltered = this.originalSkills.find(
        (i) =>
          i.code.toString() === code &&
          i.cell.trim() === cell &&
          i.subCell.trim() === subCell
      );

      if (skillFiltered) skills.push(skillFiltered.id);
    }

    return skills;
  }

  isOpen: boolean = false;

  handleToggleAccordion() {
    this.isOpen = !this.isOpen;
  }
}
