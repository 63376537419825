import { AttendantOrClient } from "../../transcription-list/transcription-list-item/models/transcription-list-item.model";

export interface ITranscriptionGetAllResponse {
  id: string;
  createdOn: string;
  createdBy: string;
  protocolNumber: string;
  attendant: AttendantOrClient;
  client: AttendantOrClient;
  mentionedCategories: string[];
  serviceDate: string;
  audioStatus: TranscriptionStatusEnum;
  transcribeStatus: TranscriptionStatusEnum;
  resultStatus: TranscriptionStatusEnum;
  reprocessStatus: TranscriptionStatusEnum;
  deleteStatus: TranscriptionStatusEnum;
  statusName: string;
  resolutenessPercentage: number | null;
  resolutenessInsight: string | null;
  shortCall: boolean;
  type: number | null;
}

export interface GetTranscriptionsFilter {
  protocolNumber: string;
  attendant: string;
  categories: string[];
  statusName: string[];
  startDate: string;
  endDate: string;
  pageNumber: number;
  pageSize: number;
  types: number[];
}

export interface PagedTranscriptionGetAllResponse {
  pageNumber: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
  items: ITranscriptionGetAllResponse[];
}

export interface ITranscriptionGetTranscriptionByIDResponse extends ITranscriptionGetAllResponse {}

export interface ITranscriptionSubmitTranscriptionRequest {
  audio: string;
  operatorId: string;
  customer: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
  };
}

export interface ITranscriptionTranscriptionResponse {
  conversation: ITranscriptionTranscriptionConversation[];
}

export interface ITranscriptionTranscriptionConversation {
  author: string;
  period: string;
  content: string;
}

export enum TranscriptionStatusEnum {
  PROCESSING,
  FINALIZED,
  ERROR
}

export interface CreateIncidentRequest {
  monitor: string;
  skill: string;
  protocolNumber: string;
  serviceDate: string;
  audio: File | {};
  attendant: string;
}

export enum TranscriptionTypeEnum{
  AUDIO,
  CHAT
}