import { Component } from '@angular/core';
import { GetContactReasonFilter } from './service/models/contact-reason.service.model';

@Component({
  selector: 'app-contact-reason',
  templateUrl: './contact-reason.component.html',
  styleUrls: ['./contact-reason.component.scss']
})
export class ContactReasonComponent {
  filter!: GetContactReasonFilter;

  setFilter(filter: GetContactReasonFilter) {
    this.filter = filter;
  }
}
