import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-rate',
  templateUrl: './rate.component.html',
  styleUrls: ['./rate.component.scss']
})
export class RateComponent {
  @Input() rating: number = 0;
  @Output() ratingFunc: EventEmitter<number> = new EventEmitter<number>();

  public hover: number = 0;

  handleRating(rate: number) {
    this.rating = this.rating === rate ? 0 : rate;
    this.ratingFunc.emit(this.rating);
  }

  handleHoverRating(rate: number) {
    this.hover = rate;
  }
}
