import {
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostListener,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import { DashboardTabItemComponent } from './dashboard-tab-item/dashboard-tab-item.component';

@Component({
  selector: 'app-dashboard-tabs',
  templateUrl: './dashboard-tabs.component.html',
  styleUrls: ['./dashboard-tabs.component.scss'],
})
export class DashboardTabsComponent {
  @ContentChildren(DashboardTabItemComponent)
  tabs!: QueryList<DashboardTabItemComponent>;
  @ViewChild('footer') footer!: ElementRef<HTMLElement>;
  @Output() emmiter: EventEmitter<DashboardTabItemComponent> =
    new EventEmitter<DashboardTabItemComponent>();

  public activeTab!: DashboardTabItemComponent;
  public pressed: boolean = false;
  public grabbing: boolean = false;
  public position = {
    left: 0,
    x: 0,
  };

  @HostListener('document:mousedown', ['$event'])
  mouseDownHandler(event: MouseEvent) {
    const children = Array.from(this.footer.nativeElement.children);
    const element = event.target as Element;

    if (
      event.target !== this.footer.nativeElement &&
      !children.includes(element)
    )
      return;

    this.pressed = true;
    this.position = {
      left: this.footer.nativeElement.scrollLeft,
      x: event.clientX,
    };
  }

  @HostListener('document:mousemove', ['$event'])
  mouseMoveHandler(event: MouseEvent) {
    if (!this.pressed) return;

    this.grabbing = true;
    const dx = event.clientX - this.position.x;
    this.footer.nativeElement.scrollLeft = this.position.left - dx;
  }

  @HostListener('document:mouseup', ['$event'])
  mouseUpHandler() {
    this.pressed = false;

    setTimeout(() => {
      this.grabbing = false;
    }, 0);
  }

  ngAfterViewInit() {
    if (!this.activeTab) {
      setTimeout(() => {
        this.activeTab = this.tabs.first;
      }, 0);
    }
  }

  selectTab(tab: DashboardTabItemComponent) {
    if (this.activeTab === tab || this.grabbing) return;

    this.activeTab = tab;

    this.emmiter.emit(tab);
  }
}
