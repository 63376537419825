import { Component } from '@angular/core';
import { UserRoleEnum } from 'src/app/common/services/auth/enums/user-role.enum';
import { StorageService } from 'src/app/common/services/storage/storage.service';

@Component({
  selector: 'app-user-list-header',
  templateUrl: './user-list-header.component.html',
  styleUrls: ['./user-list-header.component.scss'],
})
export class UserListHeaderComponent {
  public hasSupervisorRole: boolean = false;

  constructor(private storageService: StorageService) {}

  ngOnInit(): void {
    const role = this.storageService.getRole();
    this.hasSupervisorRole = role === UserRoleEnum.supervisor;

    // const header = document.querySelector('.header') as HTMLElement;

    // if (header)
    // {
    //   if (!this.hasSupervisorRole)
    //     header.style.gridTemplateColumns = "minmax(0rem, 28rem) minmax(0rem, 17rem) minmax(0rem, 8rem) minmax(0rem, 8rem) minmax(0rem, 12rem) minmax(0rem, 12rem)";
    //   else
    //     header.style.gridTemplateColumns = "minmax(0rem, 28rem) minmax(0rem, 17rem) minmax(0rem, 8rem) minmax(0rem, 8rem) minmax(0rem, 12rem) minmax(0rem, 12rem) minmax(0rem, 12rem)";
    // }
  }
}
