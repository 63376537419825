import { Component, HostListener, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { SelectOption } from './model/select-option.model';

@Component({
  selector: 'app-select-alt',
  templateUrl: './select-alt.component.html',
  styleUrls: ['./select-alt.component.scss']
})
export class SelectAltComponent {
  @Input() control = new FormControl('');
  @Input() options: SelectOption[] = [];
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() errorMessage: string = '';
  @Input() error: boolean = false;
  @Input() disabled: boolean = false;

  public focused: boolean = false;
  public selectedOption: SelectOption | null = null;

  @HostListener('document:keydown', ['$event'])
  documentKeydownEvent(event: KeyboardEvent) {
    if (event.key === 'Escape' || event.key === 'Tab') {
      this.handleBlurSelect();
    }
  }

  handleBlurSelect() {
    this.focused = false;
  }

  handleToggleFocus() {
    this.focused = !this.focused;
  }

  handleSelectOption(option: SelectOption) {
    if (this.disabled) return;

    this.selectedOption = option;
    this.control.patchValue(option.value);
    this.control.markAsDirty();
    this.handleBlurSelect();
  }
}